import { NETWORKS } from '../../../constants/types'
import { getDefaultBridgeRoute } from '../helpers'

const defaultRoute = getDefaultBridgeRoute()

export const DEFAULT_CHAIN_IN = defaultRoute?.sourceChain || 'SCROLL'
export const DEFAULT_CHAIN_OUT = defaultRoute?.destinationChain || 'ZIRCUIT'
export const DEFAULT_TOKEN = defaultRoute?.token || 'USDT'

export const DEPOSITS_POLLING_INTERVAL = 10000

export const BRIDGE_RECEIVE_TOKEN: Record<string, Record<string, string>> = {
  [NETWORKS.CRONOS_ZKEVM]: {
    ETH: 'vETH',
  },
  [NETWORKS.APECHAIN]: {
    ETH: 'ApeETH',
  },
}

export const TOKEN_INPUT_CHAIN_CUSTOM_SIZE = { size: '16px', bottom: '-3px', left: '18px' }
