import styled from 'styled-components'
import Spinner from '../../../assets/icons/spinner.svg?react'
import { rotate } from '../animations/rotate'
import { useAppSelector } from '../../../hooks'
import { selectIsWidget } from '../../../store/selectors/portal.selectors'

export const SmallSpinner = ({ size = 16 }: { size?: number }) => {
  const isWidget = useAppSelector(selectIsWidget)
  return (
    <Animate $size={size} $isWidget={isWidget}>
      <Spinner viewBox="0 0 48 48" />
    </Animate>
  )
}

const Animate = styled.div<{ $size?: number | undefined; $isWidget?: boolean }>`
  animation: ${rotate} 1s infinite linear;
  font-size: 0;
  overflow: visible;

  &,
  svg {
    height: ${({ $size }) => ($size ? `${$size}px` : '16px')};
    width: ${({ $size }) => ($size ? `${$size}px` : '16px')};

    path {
      stroke: ${({ theme, $isWidget }) => ($isWidget ? theme.textPrimary : theme.primaryMain)};
    }
  }
`
