import { useCallback, useEffect, useMemo, useState } from 'react'
import { NETWORKS, NON_EVM_CHAINS } from '../../constants/types'
import { useAppSelector } from '../../hooks'
import { useWallet } from '../../hooks/useWallet'
import { findBridgeKeyForNetworkId } from '../../services/helperService/findBridgeKeyForNetworkId'
import { switchStarknetWalletToNetworkId } from '../../services/secondaryWallet/services/starknetService/switchStarknetWalletToNetworkId'
import { selectBridgeConfig } from '../../store/apis/config.api'
import { selectIsWidget, selectRequiredChain } from '../../store/selectors/portal.selectors'
import { selectSecondaryWalletNetworkId } from '../../store/selectors/secondaryWallet.selectors'
import { selectConnectedNetwork } from '../../store/selectors/user.selectors'
import type { RequireChainContentProps } from './types/RequireChainTypes'
import { RequireChainModal } from './components/RequireChainModal'
import { RequireChainPopup } from './components/RequireChainPopup'
import { useIsMobile } from '../../hooks/useIsMobile'

export const RequireChain = () => {
  const requiredChain = useAppSelector(selectRequiredChain)
  const connectedNetwork = useAppSelector(selectConnectedNetwork)
  const secondaryConnectedNetwork = useAppSelector(selectSecondaryWalletNetworkId)
  const bridgeConfig = useAppSelector(selectBridgeConfig)
  const isWidget = useAppSelector(selectIsWidget)

  const [mustSwitchNetwork, setMustSwitchNetwork] = useState(false)

  const wallet = useWallet()
  const { isMobile } = useIsMobile()

  const { chainName = '', resolve } = requiredChain || {}
  const requiredNetworkId = useMemo(
    () => (chainName && bridgeConfig ? bridgeConfig?.[chainName]?.networkId || '' : ''),
    [chainName, bridgeConfig],
  )
  const isSecondaryWalletChain = useMemo(() => !!NON_EVM_CHAINS[chainName || ''], [chainName])
  useEffect(() => {
    if (!isSecondaryWalletChain) {
      if (!requiredChain || !chainName || +requiredNetworkId === connectedNetwork) {
        setMustSwitchNetwork(false)
        return resolve?.({ done: true })
      }
    } else {
      if (requiredChain?.chainName === NETWORKS.PARADEX) {
        return resolve?.({ done: true })
      }
      if (requiredNetworkId.toString() === secondaryConnectedNetwork) {
        setMustSwitchNetwork(false)
        return resolve?.({ done: true })
      }
    }
    setMustSwitchNetwork(true)
  }, [
    requiredChain,
    resolve,
    connectedNetwork,
    requiredNetworkId,
    chainName,
    isSecondaryWalletChain,
    secondaryConnectedNetwork,
  ])

  const onClickClose = useCallback(() => {
    if (requiredChain) {
      requiredChain.resolve({ done: false, reason: 'Closed network change modal' })
    }
    setMustSwitchNetwork(false)
  }, [requiredChain])

  const networkName = useMemo(() => {
    if (!requiredChain) {
      return null
    }
    return bridgeConfig?.[requiredChain.chainName]?.name || ''
  }, [requiredChain, bridgeConfig])

  const switchToNetwork = useCallback(() => {
    if (!isSecondaryWalletChain) {
      const chain = requiredChain?.chainName
      if (chain && bridgeConfig?.[chain]) {
        void wallet?.connectionKit?.switchNetwork(requiredChain?.chainName, bridgeConfig[chain])
      }
    } else {
      if (requiredChain?.chainName === NETWORKS.STARKNET) {
        void switchStarknetWalletToNetworkId(requiredNetworkId.toString())
      }
    }
  }, [isSecondaryWalletChain, requiredNetworkId, requiredChain?.chainName, wallet, bridgeConfig])

  const connectedNetworkId = isSecondaryWalletChain ? secondaryConnectedNetwork : connectedNetwork
  const connectedNetworkName = connectedNetworkId
    ? findBridgeKeyForNetworkId(connectedNetworkId.toString(), bridgeConfig) || ''
    : ''

  const props: RequireChainContentProps = {
    connectedNetworkName,
    chainName,
    networkName: networkName ?? '',
    switchToNetwork,
    onClickClose,
    mustSwitchNetwork,
  }

  return isMobile && !isWidget ? <RequireChainPopup {...props} /> : <RequireChainModal {...props} />
}
