import type { BridgeConfigsResponseSchema, ConnectedQuotePayloadSchema } from '@rhinofi/bridge-api-spec'
import {
  initialState,
  resetBridge,
  setBridgeState,
  resetInvalidInitialState,
  type BridgeState,
} from '../../pages/Bridge/slices/bridge.slice'
import type { AppDispatch } from '../../store/configureStore'
import { LogFeature, makeLog } from '../makeLog'
import { NETWORKS } from '../../constants/types'
import { isParadexAllowedChain } from '../../services/secondaryWallet/services/paradexService/isParadexAllowedChain'

const log = makeLog(LogFeature.BRIDGE_STATE)

const DEFAULT_KEYS_TO_SKIP: (keyof BridgeState)[] = ['isOtherAddress', 'gasBoostEnabled']

/**
 * Checks which keys should be skipped based on the mode
 *
 * @returns bridge state keys that should be skipped from bridge state
 */
const getKeysToSkip = (bridgeState: BridgeState): (keyof BridgeState)[] => {
  const keysToSkip = [...DEFAULT_KEYS_TO_SKIP]

  if (bridgeState.mode === 'pay' && bridgeState.amount) {
    keysToSkip.push('amountOut')
  }

  if (bridgeState.mode === 'receive' && bridgeState.amountOut) {
    keysToSkip.push('amount')
  }

  return keysToSkip
}

/**
 * Gets the mode bridge state property from the url and converts it to
 * the correct type
 *
 * @returns A `BridgeState` object derived from the URL.
 */
const parseUrlMode = (mode: string | null): ConnectedQuotePayloadSchema['mode'] => {
  if (mode === 'receive' || mode === 'pay') {
    return mode
  }

  return 'receive'
}

/**
 * Retrieves the bridge state from the current URL query parameters.
 * If a parameter is missing, default values are returned.
 *
 * @returns A `BridgeState` object derived from the URL.
 */
export const getStateFromUrl = (): BridgeState => {
  const urlParams = new URLSearchParams(window.location.search)
  const modeParam = urlParams.get('mode')

  // Replace `chain` with `chainIn` in the URL for backward compatibility
  if (urlParams.has('chain') && !urlParams.has('chainIn')) {
    const chain = urlParams.get('chain')
    urlParams.set('chainIn', chain || '')
    urlParams.delete('chain')

    // Update the URL without triggering a page reload
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`
    window.history.replaceState({}, '', newUrl)
  }

  return {
    mode: parseUrlMode(modeParam),
    chainIn: urlParams.get('chainIn') || '',
    chainOut: urlParams.get('chainOut') || '',
    token: urlParams.get('token') || '',
    amount: urlParams.get('amount') || '',
    amountOut: urlParams.get('amountOut') || '',
    recipient: urlParams.get('recipient') || '',
    isOtherAddress: urlParams.get('recipient') !== '' && urlParams.get('recipient') !== null,
    gasBoostEnabled: false,
  }
}
/**
 * Updates the URL to reflect the current bridge state.
 * The state is serialized into query parameters.
 */
export const updateUrlFromState = (state: BridgeState): void => {
  const urlParams = new URLSearchParams(window.location.search)
  const keysToSkip = getKeysToSkip(state)

  Object.entries(state).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== '' && !keysToSkip.includes(key)) {
      urlParams.set(key, String(value))
    }
    if (value == '') {
      urlParams.delete(key)
    }
  })

  const newUrl = `${window.location.pathname}?${urlParams.toString()}`
  log('updateUrlFromState', { state, urlParams: urlParams.toString(), newUrl })

  window.history.pushState({}, '', newUrl)
  window.dispatchEvent(new Event('urlUpdated'))
}
/**
 * Validates if the given bridge configuration is valid based on the chain and token information.
 */
export const isValidBridgeCombination = ({
  bridgeState: { chainIn, chainOut, token, amount, amountOut, mode },
  chainConfig,
  bridgeStateSource = 'slice',
}: {
  bridgeState: BridgeState
  chainConfig: BridgeConfigsResponseSchema | undefined
  bridgeStateSource?: 'slice' | 'url'
}): boolean => {
  const urlBridgeStateSource = bridgeStateSource === 'url'

  if (!chainConfig) {
    log('[isValidBridgeCombination]: No chain config')
    return false
  }

  const sourceChainConfig = chainConfig[chainIn]
  const targetChainConfig = chainConfig[chainOut]

  if (!sourceChainConfig || !targetChainConfig) {
    return false
  }

  if (sourceChainConfig.status !== 'enabled' || targetChainConfig.status !== 'enabled') {
    return false
  }

  if (sourceChainConfig.networkId === targetChainConfig.networkId) {
    return false
  }

  if (!sourceChainConfig.tokens?.[token] || !targetChainConfig.tokens?.[token]) {
    return false
  }

  if (urlBridgeStateSource) {
    if (!mode) {
      return false
    }

    if (amount && amountOut) {
      return false
    }

    if (amount && mode === 'receive') {
      return false
    }

    if (amountOut && mode === 'pay') {
      return false
    }
  }

  if (chainOut === NETWORKS.PARADEX && !isParadexAllowedChain({ chain: chainIn })) {
    return false
  }

  return true
}
/**
 * Resets the bridge state to its initial values if the provided combination is invalid.
 * Updates the URL to reflect the reset state.
 */
export const resetInvalidBridgeCombination = (
  dispatch: AppDispatch,
  scope: string,
  { chainIn, chainOut, token }: Omit<BridgeState, 'amount'>,
  chainConfig: BridgeConfigsResponseSchema | undefined,
) => {
  log(
    `[${scope}] Invalid bridge combination. Bridge from ${chainIn} to ${chainOut} with token ${token} is not valid. Resetting to default values`,
  )

  const isInitialStateValid = isValidBridgeCombination({ bridgeState: initialState, chainConfig })

  if (!isInitialStateValid && chainConfig) {
    log(`[${scope}] Initial state for the bridge set in the bridge slice is not valid. Picking new combination`)
    dispatch(resetInvalidInitialState())
  } else {
    dispatch(resetBridge())
    updateUrlFromState(initialState)
  }
}
/**
 * Synchronizes the state from the URL and validates the retrieved combination.
 * If invalid, resets to default values.
 */
export const syncStateFromUrl = (dispatch: AppDispatch, chainConfig: BridgeConfigsResponseSchema) => {
  const urlState = getStateFromUrl()

  log('[syncStateFromUrl]:', { chainConfig, urlState })

  if (!isValidBridgeCombination({ bridgeStateSource: 'url', bridgeState: urlState, chainConfig })) {
    return resetInvalidBridgeCombination(dispatch, 'syncStateFromUrl', urlState, chainConfig)
  }

  dispatch(setBridgeState(urlState))
}

/**
 * Returns the new chainIn and chainOut values based on the available chains and the current bridge state, trying to keep original values if possible.
 */
export const getValidChains = (
  availableChains: { chain: string }[],
  bridgeState: BridgeState,
): { newChainIn: string | undefined; newChainOut: string | undefined } => {
  const availableChainsSet = new Set(availableChains.map(({ chain }) => chain))

  // Determine new chainIn
  const newChainIn = availableChainsSet.has(bridgeState.chainIn)
    ? bridgeState.chainIn
    : (availableChains.find(({ chain }) => chain !== bridgeState.chainOut)?.chain ?? availableChains[0]?.chain)

  // Determine new chainOut
  const newChainOut =
    availableChainsSet.has(bridgeState.chainOut) && bridgeState.chainOut !== newChainIn
      ? bridgeState.chainOut
      : (availableChains.find(({ chain }) => chain !== newChainIn)?.chain ?? availableChains[0]?.chain)

  return { newChainIn, newChainOut }
}
