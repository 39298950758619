import { translate } from '../../../intl/i18n'
import type { WithdrawLimitReached } from '@rhinofi/bridge-api-spec'
import { BigDecimal } from 'effect'
import { formatFloat } from '../../../services/helperService/formatFloat'

export const formatWithdrawLimitReachedError = (error: WithdrawLimitReached) => {
  const limit = error.maxWithdrawAmount ? formatFloat(BigDecimal.format(error.maxWithdrawAmount)) : ''
  const limitWithToken = `${limit} ${error.token}`
  return translate('errors.bridge_withdraw_limit_reached', {
    '%chain': error.chain,
    '%withdrawLimit': limitWithToken,
  })
}
