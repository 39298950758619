import { defaultTheme, dune, sonic, tether, dark, equalizer } from '@rhinofi/dvf-shared-ui/lib/theme/colors'
import type { DefaultTheme } from 'styled-components'
import { themeAssets } from './assets/assets'
import type { ThemeName } from './store/slices/types/PortalReducerState'

export const themes: Record<ThemeName, DefaultTheme> = {
  dune: {
    theme: 'dune',
    assets: themeAssets.dune,
    ...defaultTheme,
    ...dune,
  },
  dark: {
    theme: 'dark',
    assets: themeAssets.dark,
    ...defaultTheme,
    ...dark,
  },
  tether: {
    theme: 'tether',
    assets: themeAssets.tether,
    ...defaultTheme,
    ...tether,
  },
  sonic: {
    theme: 'sonic',
    assets: themeAssets.sonic,
    ...defaultTheme,
    ...sonic,
  },
  equalizer: {
    theme: 'equalizer',
    assets: themeAssets.equalizer,
    ...defaultTheme,
    ...equalizer,
  },
}

export const commonColors = {}
