import { ButtonSizes, ButtonVariant, TextSize } from '@rhinofi/dvf-shared-ui'
import { useEffect, useMemo, useState } from 'react'
import InfoIcon from '../../assets/icons/info.svg?react'
import XIcon from '../../assets/icons/x.svg?react'
import { useIsMobile } from '../../hooks/useIsMobile'
import {
  CloseButton,
  TopAlertsPosition,
  InfoIconWrapper,
  TopAlertContainer,
  ActionButton,
  TopAlertText,
  TopAlertContent,
} from './TopAlerts.styled'
import { shouldShowTopAlert } from './shouldShowTopAlert'
import { useTopAlertsConfig } from './topAlerts.config'
import { FlexContainer } from '../common/Wrappers/Containers'
import { useAppSelector } from '../../hooks'
import { selectAddress } from '../../store/selectors/user.selectors'

export const TopAlerts = () => {
  const address = useAppSelector(selectAddress)
  const topAlertsConfig = useTopAlertsConfig()
  const { isMobile } = useIsMobile({})
  const [isVisible, setIsVisible] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setIsVisible(
      topAlertsConfig.reduce((accumulator: Record<string, boolean>, config) => {
        accumulator[config.id] = shouldShowTopAlert({ config, address })
        return accumulator
      }, {}),
    )
  }, [topAlertsConfig, address])

  const visibleTopAlerts = useMemo(
    () => topAlertsConfig.filter(({ id }) => isVisible[id]),
    [topAlertsConfig, isVisible],
  )

  if (visibleTopAlerts.length === 0) {
    return null
  }

  return (
    <TopAlertsPosition $isMobile={isMobile} id="top-alerts">
      {visibleTopAlerts.map(({ id, type, onClick, hasCloseButton, text, saveInLsWithAddress, buttonText }) => (
        <TopAlertContainer
          $type={type}
          key={id}
          onClick={() => {
            // Enable click on whole element if on mobile because the action button gets hidden
            if (isMobile && onClick) {
              onClick()
            }
          }}
        >
          <FlexContainer $justifyContent="center" $flexGrow="1">
            <FlexContainer $justifyContent="center" $alignItems="center" $gap="8px">
              <InfoIconWrapper $type={type}>
                <InfoIcon />
              </InfoIconWrapper>

              <TopAlertContent>
                <TopAlertText $type={type} size={TextSize.S}>
                  {text}
                </TopAlertText>

                {!isMobile && onClick && buttonText && (
                  <ActionButton id={`${id}-button`} onClick={onClick}>
                    {buttonText}
                  </ActionButton>
                )}
              </TopAlertContent>
            </FlexContainer>
          </FlexContainer>

          {hasCloseButton ? (
            <CloseButton
              $type={type}
              id="close-top-alert"
              size={ButtonSizes.Small}
              variant={ButtonVariant.ghost}
              onClick={(event) => {
                // On mobile, clicking close, should not trigger action click
                event.stopPropagation()

                if (saveInLsWithAddress && address) {
                  window.localStorage.setItem(`${id}-${address}`, new Date().toISOString())
                } else if (!saveInLsWithAddress) {
                  window.localStorage.setItem(id, new Date().toISOString())
                }

                setIsVisible({ ...isVisible, [id]: false })
              }}
            >
              <XIcon />
            </CloseButton>
          ) : null}
        </TopAlertContainer>
      ))}
    </TopAlertsPosition>
  )
}
