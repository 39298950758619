import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { WalletType } from '../../services/wallet/wallet.types'
import type { AuthDataType } from '../../services/auth/auth.types'

type PossibleWallets =
  | {
      address: string
      walletType: typeof WalletType.walletConnect
    }
  | {
      address: string
      walletType: typeof WalletType.eip6963
      name: string
    }
  | {
      address: string
      walletType: typeof WalletType.injected
    }

export type UserState = {
  hasLegacyClaims: boolean
  contractWallet:
    | {
        isContractWallet: true
        signingAddress: string
      }
    | {
        isContractWallet: false
        signingAddress?: null | undefined
      }
  auth: {
    requested: boolean
    rejected: boolean
    data: AuthDataType | null
  }
  wallet: PossibleWallets | null
  network: number | null
  loading: {
    lastLoginRead: boolean
  }
}

const initialState: UserState = {
  hasLegacyClaims: false,
  contractWallet: {
    isContractWallet: false,
  },
  auth: {
    requested: false,
    rejected: false,
    data: null,
  },
  wallet: null,
  network: null,
  loading: {
    lastLoginRead: false,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWallet(
      state,
      action: PayloadAction<{
        wallet: PossibleWallets | null
        preventAuthClear?: boolean
      }>,
    ) {
      if (action.payload.wallet) {
        // Connecting a mixed case address causes issues with all endpoints which use the EthAddressSchema schema
        // This might be a problem if we allow the "user" to be some other type of address
        state.wallet = {
          ...action.payload.wallet,
          address: action.payload.wallet.address.toLowerCase(),
        }
      } else {
        state.wallet = action.payload.wallet
      }

      if (
        (!state.wallet?.address || state.wallet.address !== state.auth.data?.ethAddress) &&
        !action.payload.preventAuthClear
      ) {
        state.auth.data = null
        state.auth.requested = false
        state.auth.rejected = false
      }
    },
    setLastLoginRead(state, action: PayloadAction<boolean>) {
      state.loading.lastLoginRead = action.payload
    },
    setAuthData(state, action: PayloadAction<UserState['auth']['data']>) {
      state.auth = {
        requested: false,
        rejected: false,
        data: action.payload,
      }
    },
    setAuthRequested(state, action: PayloadAction<boolean>) {
      state.auth.requested = action.payload
    },
    setAuthRejected(state, action: PayloadAction<boolean>) {
      state.auth.rejected = action.payload
    },
    setContractWallet(state, action: PayloadAction<UserState['contractWallet']>) {
      state.contractWallet = action.payload
    },
    setNetwork: (state, action: PayloadAction<{ network: number }>) => {
      state.network = action.payload.network
    },
    setHasLegacyClaims: (state, action: PayloadAction<boolean>) => {
      state.hasLegacyClaims = action.payload
    },
  },
})

export const {
  setWallet,
  setLastLoginRead,
  setAuthData,
  setAuthRequested,
  setAuthRejected,
  setContractWallet,
  setNetwork,
  setHasLegacyClaims,
} = userSlice.actions
