import type { PayloadAction, SliceSelectors } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserHistoryResponseSchema } from '@rhinofi/bridge-api-spec'
import type { RootState } from '../../../store/configureStore'

export type BridgeHistoryState = Pick<UserHistoryResponseSchema, 'totalItems' | 'items'>

export const initialState: BridgeHistoryState = {
  totalItems: 0,
  items: [],
}

// need to manually set the generics as it fails to infer it properly
export const bridgeHistorySlice = createSlice<
  UserHistoryResponseSchema,
  {
    resetHistory(state: UserHistoryResponseSchema): void
    setHistory(state: UserHistoryResponseSchema, action: { type: string; payload: UserHistoryResponseSchema }): void
  },
  'bridgeHistory',
  SliceSelectors<UserHistoryResponseSchema>,
  'bridgeHistory'
>({
  name: 'bridgeHistory',
  initialState,
  reducers: {
    setHistory(state, action: PayloadAction<BridgeHistoryState>) {
      Object.assign(state, action.payload)
    },
    resetHistory(state) {
      Object.assign(state, initialState)
    },
  },
})

export const { setHistory, resetHistory } = bridgeHistorySlice.actions

// Selectors
export const selectBridgeHistory = (state: RootState) => state.bridgeHistory
export const selectSingleBridgeFromHistory = (bridgeId?: string | null) => (state: RootState) =>
  bridgeId ? state.bridgeHistory.items.find((item) => item.bridge._id === bridgeId)?.bridge : null
export const selectPendingBridges = (state: RootState) =>
  state.bridgeHistory.items.filter((item) => ['PENDING', 'ACCEPTED'].includes(item.bridge.state))
export const selectPendingBridgesCount = (state: RootState) => selectPendingBridges(state).length
