export const heapEvents = {
  bridge: 'Bridge',
  connectWallet: 'Connect Wallet',
  authenticate: 'Authenticate',
  unlockWallet: 'Unlock wallet',
  switchLanguage: 'Switch Language',
  switchTheme: 'Swith Theme',
  pageLeave: 'page-leave',
  pageEnter: 'page-enter',
  navigatonBridgeClicked: 'Navigation Bridge Clicked',
  rpcReconnection: 'RPC provider reconnection',
  walletSearch: 'Wallet Search',
  notification: 'Notification',
  walletError: 'Wallet Error',
  activityTracker: 'Activity Tracker',
  profileAllChains: 'Profile All Chains Data Loaded',
  toggleBridgeAnyAddress: 'Toggle bridge any address',
  recommendations: 'Clicked on Recommendations',
  nftMinted: 'NFT Minted',
  referralLinkUnlocked: 'Referral Link Unlocked',
  referralCreated: 'Referral Created',
  activityTrackerRecommendedProjectClicked: 'Activity Tracker Recommended Project Clicked',
  myAchievementsDataLoadedInModal: 'My Achievements Data Loaded In Modal',
  myAchievementDataLoaded: 'Achievement data loaded',
  myAchievementActionClick: 'My Achievement Action Clicked',
  profileTabClick: 'Profile tab click',
  contractsInteractionsTabClick: 'Contracts Interactions Interactions Tab Clicked',
  gmContractDeployed: 'GM Contract Deployed',
  gm: 'Contract GM interaction',
  eip6963Connect: 'EIP6963 Connect',
  nationWidgetIconClick: 'Nation Widget icon click',
  nationWidgetChainFilter: 'Nation Widget chain filter',
  nationWidgetMissionTypeFilter: 'Nation Widget mission type filter',
  withdrawlLimitReached: 'Withdraw Limit Reached',
  depositLimitReached: 'Deposit Limit Reached',
  tokenResetAllowance: 'Token reset allowance',
} as const

export type HeapEvent = (typeof heapEvents)[keyof typeof heapEvents]

export const heapStatuses = {
  success: 'Successful',
  fail: 'Failed',
  partialSuccess: 'Partial Success',
}
