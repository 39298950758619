export const POLLING_INTERVALS = {
  CONFIG: 90_000, // 1min30
  BLOCKCHAIN_BALANCE: 30_000, // 30sec
  TOKEN_PRICES: 60_000, // 1min
  USER_BRIDGE_HISTORY: 60_000, // 1min
  PENDING_BRIDGE: 2_000, // 2sec
}

export const WEI_MAX_LIMIT = 500000000000

export const WALLET_CONNECT_PROJECT_ID = '982077ac6b911c7270812e5545e16b60'
