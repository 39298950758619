import styled from 'styled-components'
import { FlexContainer } from '../common/Wrappers/Containers'

export const PopupWrapper = styled.div`
  background: ${({ theme }) => theme.cardBackground};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

export const PopupHeader = styled(FlexContainer)`
  padding: 14px 24px 12px 24px;
  height: 38px;
`

export const PopupContent = styled.div`
  padding: 0 24px 24px;
`
