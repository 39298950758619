import { useTokenDistributionUserEligibilityManyQueryWrapper } from './useTokenDistributionUserEligibilityManyQueryWrapper'
import { useMultipleDistributions } from '../Claimables/useMultipleDistributions'
import { useTokenBalances } from '../XChainClaims/useTokenBalances'
import { useAppSelector } from '../../../hooks'
import { selectIsAuthenticated } from '../../../store/selectors/user.selectors'

type Props =
  | {
      skip: boolean
    }
  | undefined

export const useLegacyRhinoClaimsData = ({ skip }: Props = { skip: false }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const { currentData: eligibilityList, isFetching: isFetchingEligibility } =
    useTokenDistributionUserEligibilityManyQueryWrapper({ skip })
  const {
    distributionsFetchDone,
    claimsFetchDone,
    distributions,
    updateDistribution,
    lazyContractCheck,
    fetchedForAddress,
  } = useMultipleDistributions(eligibilityList)

  const xChainTokenBalances = useTokenBalances()
  const isLoadingClaims = isAuthenticated && (xChainTokenBalances === undefined || isFetchingEligibility)

  const isLoadingAll = !claimsFetchDone || !distributionsFetchDone || xChainTokenBalances === undefined
  const hasNoClaims = !isLoadingAll && distributions.length === 0 && xChainTokenBalances?.length === 0

  return {
    distributions,
    xChainTokenBalances,
    isLoadingAll,
    isLoadingClaims,
    hasNoClaims,
    updateDistribution,
    lazyContractCheck,
    fetchedForAddress,
  }
}
