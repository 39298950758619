import jsonwebtoken from 'jsonwebtoken'

export const isJwtExpired = (token?: string): boolean => {
  if (!token) {
    return true
  }

  const decodedToken = jsonwebtoken.decode(token, { json: true })
  if (!decodedToken?.exp) {
    return true
  }

  return decodedToken.exp <= Date.now() / 1000
}
