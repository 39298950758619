import { WalletButton } from './WalletButton'
import { WalletType } from '../../services/wallet/wallet.types'
import walletConnectIcon from './assets/walletConnect.svg'
import { useOtherWallet } from './hooks/useOtherWallets'
import { useWallet } from '../../hooks/useWallet'
import { useConnectInjected } from './hooks/useConnectInjected'

type Props = {
  onErrorCallback?: (error: string) => void
}

export const ConnectOthersWalletOptions = ({ onErrorCallback = () => {} }: Props) => {
  const wallet = useWallet()
  const otherWallets = useOtherWallet()

  const connectInjected = useConnectInjected({ onErrorCallback })

  return (
    <>
      <WalletButton
        isConnecting={wallet.connectingWallet.isConnecting && wallet.connectingWallet.name === WalletType.walletConnect}
        onClick={async () => {
          await wallet.connect({
            walletType: WalletType.walletConnect,
          })
        }}
        id="wallet"
        label="WalletConnect"
        icon={walletConnectIcon}
      />
      {otherWallets.map(({ icon, label }) => (
        <WalletButton
          key={label}
          isConnecting={
            wallet.connectingWallet.isConnecting &&
            wallet.connectingWallet.name === WalletType.injected &&
            label === 'Browser wallet'
          }
          onClick={() => connectInjected(label)}
          id={`wallet-${label}`}
          label={label}
          icon={icon}
        />
      ))}
    </>
  )
}
