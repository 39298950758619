import styled from 'styled-components'
import { ConnectedWallets } from '../ConnectWallet/ConnectedWallets/ConnectedWallets'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'

export const MenuConnectedWalletsButtonContent = () => (
  <Wrapper>
    <ConnectedWallets />
    <CustomIcon className="dropdown-caret" id="caret-down-fill" size={IconSizes.XSmall} />
  </Wrapper>
)

const CustomIcon = styled(Icon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.strokeDivider};
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
  height: 44px;
  box-sizing: border-box;

  &:hover {
    background: ${({ theme }) => theme.actionHover};
  }

  .dropdown-caret {
    margin-left: 8px;
  }
`
