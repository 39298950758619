import { type FC, type PropsWithChildren, type ReactNode } from 'react'
import { MenuHeader } from './MobileMenu.styled'
import { IconSizes, Text, Icon } from '@rhinofi/dvf-shared-ui'
import {
  MOBILE_MENU_FADE_TRANSITION_LENGTH,
  MOBILE_MENU_SLIDE_IN_TRANSITION_LENGTH,
  MOBILE_MENU_SLIDE_OUT_TRANSITION_LENGTH,
} from './constants/MobileMenu.constants'
import { OpacitySlideTransition } from '../OpacitySlideTransition/OpacitySlideTransition'
import { zIndices } from '../../constants/zIndex'

type Props = {
  show: boolean
  handleClose: () => void
  headerText?: string
  headerCustomComponent?: ReactNode
}

export const MobileMenu: FC<PropsWithChildren<Props>> = ({
  show,
  children,
  headerText,
  headerCustomComponent,
  handleClose,
}) => (
  <OpacitySlideTransition
    show={show}
    timeOutOpacity={MOBILE_MENU_FADE_TRANSITION_LENGTH}
    timeOutSlideEnter={MOBILE_MENU_SLIDE_IN_TRANSITION_LENGTH}
    timeOutSlideExit={MOBILE_MENU_SLIDE_OUT_TRANSITION_LENGTH}
    zIndex={zIndices.mobileMenuOpen}
  >
    <MenuHeader $justifyContent="space-between">
      {headerText && <Text bold>{headerText}</Text>}
      {headerCustomComponent ? headerCustomComponent : null}

      <Icon id="x" active size={IconSizes.Big} handleClick={handleClose} />
    </MenuHeader>

    {children}
  </OpacitySlideTransition>
)
