import { type CSSProperties } from 'react'

export const disableTransformInStyleOnCondition = ({
  style,
  hideTransform,
}: {
  style: CSSProperties
  hideTransform: boolean
}) => {
  if (hideTransform && 'transform' in style) {
    return {
      ...style,
      transform: 'initial',
    }
  }

  return style
}
