import {
  Button,
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  Spacing,
  Text,
  Title,
  TypographyAlign,
} from '@rhinofi/dvf-shared-ui'
import { TextSize, TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { ButtonLink } from '../../../../../components/common/Helpers/LinkHelpers'
import { translate } from '../../../../../intl/i18n'
import { makeRouteTo } from '../../../../../router'
import { getBridgeDuration } from '../../../../../services/helperService/getBridgeDuration'
import { shortenAddress } from '../../../../../services/helperService/shortenAddress'
import { PaleText, StateHeadingWrapper, SuccessSection, Summary, SummaryItem } from '../../../Bridge.styled'
// import { DeployContractBanner } from './DeployContractBanner'
import type { PublicQuoteResponseSchema } from '@rhinofi/bridge-api-spec'
import { BigDecimal } from 'effect'
import { useCallback, useMemo } from 'react'
import { TextWithLink } from '../../../../../components/TextWithLink/TextWithLink'
import { modalKeys } from '../../../../../constants/modalKeys'
import { NETWORKS } from '../../../../../constants/types'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { formatFloat } from '../../../../../services/helperService/formatFloat'
import { showModal } from '../../../../../store/actions/modalActions/showModal'
import { selectBridgeChainsConfig } from '../../../../../store/selectors/bridgeConfig.selectors'
import { useBridgeProgress } from '../../../hooks'
import { useGetHighlightsByIdQuery } from '../../../services/highlights.api'
import { selectBridge } from '../../../slices/bridge.slice'
import { BridgeSuccessType } from '../../../slices/bridgeProgress.slice'
import { Highlights } from '../../../types/bridge-widget.types'
import { selectIsWidget } from '../../../../../store/selectors/portal.selectors'

const getSuggestedDexLink = (chain: string) => {
  switch (chain) {
    case NETWORKS.BASE:
      return 'https://app.uniswap.org/explore/base'
    case NETWORKS.SOLANA:
      return 'https://jup.ag/'
  }
  return undefined
}

const TokenBuySuggestion = ({ chain, token }: { chain: string; token: string }) => {
  const href = getSuggestedDexLink(chain)
  return href ? (
    <TextWithLink
      linkId={`buy-on-dex-${token}`}
      text={() => translate('bridge.buy_on_dex', { '%token': token.toUpperCase() })}
      href={href}
      openNewTab
    />
  ) : null
}

type SuccessBridgeStateProps = {
  prettyChainInName: string
  prettyChainOutName: string
  destinationAddress?: string
  isSend?: boolean
  customDescription?: string
  customButtonLabel?: string
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
}

export const SuccessBridgeState = ({
  isSend,
  prettyChainOutName,
  customButtonLabel,
  destinationAddress,
  payAmount,
}: SuccessBridgeStateProps) => {
  const dispatch = useAppDispatch()
  const { progress, resetProgressState } = useBridgeProgress()
  const isWidget = useAppSelector(selectIsWidget)
  const { token, amount, chainOut } = useAppSelector(selectBridge)
  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)
  const bridgeToBuyToken = useSearchParams()[0].get('bridgeToBuyToken')

  const { data } = useGetHighlightsByIdQuery(Highlights.PostBridge)

  const chainOutHighlights = useMemo(
    () => data?.items.filter((item) => item.chain === chainOut) ?? [],
    [data, chainOut],
  )

  const hasChainOutHighlights = chainOutHighlights.length > 0

  const handleShowChainProjectsModal = useCallback(() => {
    if (!hasChainOutHighlights) {
      return
    }
    showModal(dispatch)(modalKeys.exploreChainProjects, {
      chain: chainOut,
      chainProjects: chainOutHighlights,
    })
  }, [chainOut, chainOutHighlights, dispatch, hasChainOutHighlights])

  return (
    <>
      <StateHeadingWrapper>
        <Title size={TitleSize.XXS} align={TypographyAlign.Center} fullWidth>
          {translate('helpers.success')}
        </Title>
      </StateHeadingWrapper>
      <SuccessSection>
        <Icon id="check-circle-fill" color={isWidget ? 'textPrimary' : 'primaryMain'} size={IconSizes.ExtraLarge} />
        <Text size={TextSize.XS} align={TypographyAlign.Center} bold>
          Great job! The bridge has been initiated. <br />
          You can relax and check your balance later.
        </Text>
      </SuccessSection>
      <Summary $withBorder>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('bridge.you_bridged')}</PaleText>
          <PaleText id="summary-item-value" size={TextSize.XS}>
            {formatFloat(amount)} {token}
          </PaleText>
        </SummaryItem>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('bridge.you_paid')}</PaleText>
          <PaleText id="summary-item-value" size={TextSize.XS}>
            {formatFloat(payAmount ? BigDecimal.format(payAmount) : '0')} {token}
          </PaleText>
        </SummaryItem>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('helpers.timestamp')}</PaleText>
          <PaleText id="summary-item-timestamp" size={TextSize.XS}>
            {progress.timestamp ? moment(progress.timestamp).format('DD-MM HH:mm:ss') : '...'}
          </PaleText>
        </SummaryItem>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('helpers.duration')}</PaleText>
          <PaleText id="summary-item-timestamp" size={TextSize.XS}>
            {getBridgeDuration(chainInConfig, chainOutConfig)}
          </PaleText>
        </SummaryItem>
        {isSend && destinationAddress ? (
          <SummaryItem>
            <PaleText size={TextSize.XS}>{translate('helpers.to')}</PaleText>
            <PaleText id="summary-item-destination" size={TextSize.XS}>
              {shortenAddress(destinationAddress)}
            </PaleText>
          </SummaryItem>
        ) : (
          <SummaryItem>
            <PaleText size={TextSize.XS}>{translate('helpers.destination')}</PaleText>
            <PaleText id="summary-item-destination" size={TextSize.XS}>
              {prettyChainOutName}
            </PaleText>
          </SummaryItem>
        )}
      </Summary>
      {/* {getEnabledCIChains().includes(chainOut) && <DeployContractBanner chain={chainOut} />} */}
      {progress.successType === BridgeSuccessType.BlocksTimeout && (
        <>
          <Spacing size="16" />
          <ButtonLink
            id="bridge-deposit-pending-history"
            variant={ButtonVariant.secondary}
            fullWidth
            to={makeRouteTo.history()}
          >
            {translate('portfolio.account_history')}
          </ButtonLink>
        </>
      )}
      {bridgeToBuyToken && (
        <>
          <Spacing size="24" />
          <TokenBuySuggestion chain={chainOut} token={bridgeToBuyToken} />
        </>
      )}
      <Spacing size="24" />
      <Button id="bridge-success-make-another" fullWidth onClick={resetProgressState} size={ButtonSizes.Large}>
        {customButtonLabel || translate('bridge.bridge_again')}
      </Button>

      {hasChainOutHighlights && (
        <>
          <Spacing size="24" />
          <Button
            id="explore-chain-projects"
            variant={ButtonVariant.secondary}
            fullWidth
            onClick={handleShowChainProjectsModal}
            size={ButtonSizes.Large}
            icon={<Icon id="arrow-up-right" size={IconSizes.Small} isOnButton />}
          >
            {translate('bridge.explore_chain', { '%chain': prettyChainOutName })}
          </Button>
        </>
      )}
    </>
  )
}
