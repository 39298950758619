import { Loader, Spacing, Text } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import type { UserHistoryItemSchema } from '@rhinofi/bridge-api-spec'
import { Modal } from '../../../../components/common/Modal/Modal'
import { useAppDispatch } from '../../../../hooks'
import { translate } from '../../../../intl/i18n'
import { capitalize } from '../../../../services/helperService'
import { clearModal } from '../../../../store/actions/modalActions/clearModal'
import { LoaderWrapper } from './RecentBridgeTx.styled'
import { RecentBridgeTx } from './RecentBridgeTx'

type Props = {
  bridgeData: UserHistoryItemSchema | undefined
}

export const RecentBridgeTxModal = ({ bridgeData }: Props) => {
  const dispatch = useAppDispatch()

  if (!bridgeData) {
    return (
      <Modal isVisible width="400px" onClose={() => clearModal(dispatch)()} increasedPadding>
        <Text size={TextSize.M} bold>
          {capitalize(translate('global.details'))}
        </Text>
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </Modal>
    )
  }

  return (
    <Modal isVisible width="400px" onClose={() => clearModal(dispatch)()} increasedPadding>
      <Text size={TextSize.M} bold>
        {capitalize(translate('global.details'))}
      </Text>
      <Spacing size="12" />
      <RecentBridgeTx bridgeData={bridgeData} />
    </Modal>
  )
}
