import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../../store/configureStore'
import { BridgeLayoutMobileView, BridgeLayoutVisibleTab, BridgeTabsStep } from '../types/bridge-widget.types'

export type BridgeLayoutState = {
  activeMobileView: BridgeLayoutMobileView
  visibleTab: BridgeLayoutVisibleTab
  activeBridgeTab: BridgeTabsStep
}

export const initialState: BridgeLayoutState = {
  activeMobileView: BridgeLayoutMobileView.bridge,
  visibleTab: BridgeLayoutVisibleTab.none,
  activeBridgeTab: BridgeTabsStep.highlights,
}

export const bridgeLayout = createSlice({
  name: 'bridgeLayout',
  initialState,
  reducers: {
    setActiveMobileView(state, action: PayloadAction<BridgeLayoutMobileView>) {
      state.activeMobileView = action.payload
    },
    setActiveBridgeTab(state, action: PayloadAction<BridgeTabsStep>) {
      state.activeBridgeTab = action.payload
    },
    setVisibleTab(state, action: PayloadAction<BridgeLayoutVisibleTab>) {
      state.visibleTab = action.payload
    },
  },
})

export const { setActiveMobileView, setActiveBridgeTab, setVisibleTab } = bridgeLayout.actions

// Selectors
export const selectBridgeLayout = (state: RootState) => state.bridgeLayout
export const selectBridgeLayoutActiveMobileView = (state: RootState) => state.bridgeLayout.activeMobileView
export const selectBridgeLayoutActiveBridgeTab = (state: RootState) => state.bridgeLayout.activeBridgeTab
export const selectBridgeLayoutVisibleTab = (state: RootState) => state.bridgeLayout.visibleTab
