import type { Kits, WalletTypeProps } from '../wallet.types'
import { WalletType } from '../wallet.types'
import { getEIP1193ConnectionKit } from '../kits/eip1193/getEip1193Kit'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import { useCallback, useState } from 'react'
import type { EIP6963ProviderDetail } from '../eip6963/EthereumProvider.types'
import { getWalletConnectProvider } from '../walletConnect/getWalletConnectProvider'

const log = makeLog(LogFeature.WALLET_CONTEXT)

export const useConnectionKits = ({ eip6963Providers }: { eip6963Providers: EIP6963ProviderDetail[] }) => {
  const [connectionKit, setConnectionKit] = useState<Kits | null>(null)

  const getConnectionKit = useCallback(
    async (props: WalletTypeProps) => {
      setConnectionKit(null)
      switch (props.walletType) {
        case WalletType.injected: {
          if (!window.ethereum) {
            log('No injected provider')
            throw new Error('No injected provider')
          }
          const provider = window.ethereum
          const kit = await getEIP1193ConnectionKit({
            name: 'injected',
            provider,
          })
          setConnectionKit(kit)
          log('Picked injected wallet option')
          return kit
        }

        case WalletType.walletConnect: {
          const provider = await getWalletConnectProvider()
          await provider.enable()
          const kit = await getEIP1193ConnectionKit({
            name: 'walletConnect',
            provider,
          })
          setConnectionKit(kit)
          log('Picked walletconnect wallet option')
          return kit
        }

        case WalletType.eip6963: {
          const provider = eip6963Providers.find((candidate) => candidate.info.name === props.name)
          if (!provider) {
            log('No provider found')
            throw new Error('Provider not found')
          }
          log('Picked eip6963 wallet option', props.name)
          const kit = await getEIP1193ConnectionKit({
            name: props.name,
            provider: provider.provider,
          })
          setConnectionKit(kit)
          return kit
        }
      }
    },
    [eip6963Providers],
  )

  return {
    connectionKit,
    getConnectionKit,
  }
}
