import { WalletType } from '../../../services/wallet/wallet.types'
import { translate } from '../../../intl/i18n'
import { useCallback } from 'react'
import { useWallet } from '../../../hooks/useWallet'
import { useIsMobile } from '../../../hooks/useIsMobile'

type Props = {
  onErrorCallback?: (error: string) => void
}

export const useConnectInjected = ({ onErrorCallback = () => {} }: Props) => {
  const wallet = useWallet()
  const { isMobile } = useIsMobile()

  return useCallback(
    (label: string) => {
      const { ethereum } = window
      onErrorCallback('')
      if (isMobile && ethereum) {
        void wallet.connect({
          walletType: WalletType.injected,
        })
      } else if (isMobile && !ethereum) {
        void wallet.connect({
          walletType: WalletType.walletConnect,
        })
      } else {
        if (label === 'Browser wallet') {
          void wallet.connect({
            walletType: WalletType.injected,
          })
        } else {
          onErrorCallback(translate('errors.no_wallet_browser'))
        }
      }
    },
    [wallet, isMobile, onErrorCallback],
  )
}
