import styled from 'styled-components'
import { zIndices } from '../../constants/zIndex'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: ${zIndices.mobileHeader};
  transition: all 0.5s ease-in-out;
`

export const TabBar = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.cardBackground};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: stretch;
  transition: height 0.3s ease-in-out;
  height: 80px;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid ${({ theme }) => theme.divider};
  cursor: pointer;
`

export const MobileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 0;
  margin-bottom: 18px;
`
