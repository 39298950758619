import type { ConnectedQuotePayloadSchema } from '@rhinofi/bridge-api-spec'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { BigDecimal } from 'effect'
import { heapEvents, heapStatuses } from '../../../constants/heapEvents'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useWallet } from '../../../hooks/useWallet'
import { trackHeapEvent } from '../../../services/apiService'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { isValidWalletError } from '../../../services/helperService/isValidWalletError'
import { useCreateAffiliateIdMutation } from '../../../services/referralApi'
import { requireChain } from '../../../store/actions/user.actions'
import {
  useCommitUserQuoteMutation,
  useGenUserQuoteMutation,
  usePatchBridgeHistoryMutation,
} from '../../../store/apis/bridge.api'
import {
  selectBridgeChainsConfig,
  selectBridgeChainsTokenConfig,
} from '../../../store/selectors/bridgeConfig.selectors'
import { selectSecondaryWalletAddress } from '../../../store/selectors/secondaryWallet.selectors'
import { selectWallet } from '../../../store/selectors/user.selectors'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { callBridgeContract } from '../services/bridge.contract'
import { trackBridgeEvent } from '../services/trackBridgeEvent'
import { selectBridge } from '../slices/bridge.slice'
import { BridgeProgressStep } from '../slices/bridgeProgress.slice'
import { BridgeConfirmationScreen, BridgeLayoutVisibleTab, BridgeTabsStep } from '../types/bridge-widget.types'
import { useBridgeProgress } from './useBridgeProgress'
import { useBridgeAmount } from './useBridgeAmount'
import { selectIsWidget } from '../../../store/selectors/portal.selectors'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { setActiveBridgeTab, setVisibleTab } from '../slices/bridgeLayout.slice'

const log = makeLog(LogFeature.BRIDGE_WIDGET)

type UseBridgeInput = {
  mode?: ConnectedQuotePayloadSchema['mode']
  gasBoostAmountNative?: string
  triggerConfirmationScreen: (screen: BridgeConfirmationScreen) => Promise<boolean>
}

type UseBridgeOutput = {
  handleBridge: () => Promise<void>
}

export const useBridge = ({
  mode = 'receive',
  gasBoostAmountNative,
  triggerConfirmationScreen,
}: UseBridgeInput): UseBridgeOutput => {
  const dispatch = useAppDispatch()
  const isWidget = useAppSelector(selectIsWidget)
  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const { chainInTokenConfig } = useAppSelector(selectBridgeChainsTokenConfig)
  const walletData = useAppSelector(selectWallet)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const bridgeAmount = useBridgeAmount()
  const { isMobile } = useIsMobile()

  const { setProgress, resetProgressState } = useBridgeProgress()
  const { ethersSigner } = useWallet()

  const [genUserQuoteMutation] = useGenUserQuoteMutation()
  const [commitUserQuoteMutation] = useCommitUserQuoteMutation()
  const [patchBridgeHistoryMutation] = usePatchBridgeHistoryMutation()
  const [createAffiliateId] = useCreateAffiliateIdMutation()

  const handleBridge = async () => {
    // eslint-disable-next-line functional/no-let -- needed for catch
    let bridgeId: string | undefined
    try {
      if (!bridgeAmount || !bridgeState.token || !bridgeState.chainIn || !bridgeState.chainOut) {
        throw new Error('All fields are required to proceed with the bridge.')
      }

      if (!walletData && !isWidget) {
        throw new Error('Wallet data not found.')
      }

      if (bridgeState.recipient) {
        const didConfirm = await triggerConfirmationScreen(BridgeConfirmationScreen.bridgeToAny)
        if (!didConfirm) {
          return
        }
      }

      setProgress((state) => ({
        ...state,
        progressStep: BridgeProgressStep.Pending,
      }))
      if (!isNonEVMChain(bridgeState.chainIn)) {
        const switchChainResult = await requireChain(dispatch)(bridgeState.chainIn)
        if (switchChainResult.done === false) {
          resetProgressState()
          return
        }
        log({ switchChainResult })
      }
      const depositor = isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress : walletData?.address
      const recipient =
        bridgeState.recipient || (isNonEVMChain(bridgeState.chainOut) ? secondaryWalletAddress : walletData?.address)

      if (!depositor || !recipient) {
        throw new Error('Depositor or recipient is missing.')
      }
      const userQuote = await genUserQuoteMutation({
        payload: {
          amount: BigDecimal.unsafeFromString(bridgeAmount),
          chainIn: bridgeState.chainIn,
          chainOut: bridgeState.chainOut,
          token: bridgeState.token,
          mode,
          depositor,
          recipient,
          amountNative: gasBoostAmountNative
            ? BigDecimal.unsafeFromString(gasBoostAmountNative)
            : BigDecimal.unsafeFromNumber(0),
        },
      }).unwrap()

      log({ userQuote })

      if (!userQuote?.quoteId) {
        throw new Error('Failed to generate user quote.')
      }

      const commitmentResult = await commitUserQuoteMutation({
        path: { quoteId: userQuote.quoteId },
      }).unwrap()

      if (!commitmentResult?.quoteId) {
        throw new Error('Failed to commit user quote.')
      }

      bridgeId = commitmentResult.quoteId

      if (!chainInConfig) {
        throw new Error('ChainIn config not found.')
      }

      if (!chainInTokenConfig) {
        throw new Error('ChainIn token config not found.')
      }

      const isNativeToken = bridgeState.token === chainInConfig.nativeTokenName

      const receipt = await callBridgeContract({
        ethersSigner: ethersSigner,
        secondaryWalletAddress: isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress : undefined,
        amount: userQuote.payAmount,
        amountWithDecimals: SafeDecimal(BigDecimal.format(userQuote.payAmount))
          .mul(10 ** chainInTokenConfig.decimals)
          .toFixed(),
        chain: bridgeState.chainIn,
        commitmentId: commitmentResult.quoteId,
        bridgeContractAddress: chainInConfig.contractAddress,
        tokenAddress: chainInTokenConfig.address,
        isNativeToken,
        callback: (txHash, commitmentId) => {
          setProgress((previous) => ({
            ...previous,
            bridgeId: commitmentId,
            transactionHash: txHash,
            isTxSubmitted: true,
          }))

          // Open bridge history tab
          if (!isMobile && !isWidget) {
            dispatch(setVisibleTab(BridgeLayoutVisibleTab.history))
            dispatch(setActiveBridgeTab(BridgeTabsStep.history))
          }
        },
      })
      setProgress((previous) => ({
        ...previous,
        isTxConfirmed: true,
        timestamp: Date.now(),
      }))

      // Don't wait for the transaction to be confirmed and show the success screen immediately
      setTimeout(() => {
        setProgress((previous) => ({
          ...previous,
          progressStep: BridgeProgressStep.Done,
        }))
      }, 3000)

      const affiliateRefId = window.localStorage.getItem('refId')
      if (affiliateRefId && walletData?.address) {
        await createAffiliateId({
          user: walletData.address,
          affiliateId: affiliateRefId,
        })
      }

      const isReceiveMode = bridgeState.mode === 'receive'

      trackBridgeEvent({
        status: heapStatuses.success,
        mode: bridgeState.mode,
        token: bridgeState.token,
        chainIn: bridgeState.chainIn,
        chainOut: bridgeState.chainOut,
        amount: bigDecimalToNumericString(isReceiveMode ? userQuote.receiveAmount : userQuote.payAmount),
        usdAmount: bigDecimalToNumericString(isReceiveMode ? userQuote.receiveAmountUsd : userQuote.payAmountUsd),
        fee: bigDecimalToNumericString(userQuote.fees.fee),
        feeUsd: bigDecimalToNumericString(userQuote.fees.feeUsd),
        gasBoostUsed: gasBoostAmountNative ? 'true' : 'false',
      })

      log('Native Transaction confirmed:', receipt)
    } catch (error) {
      console.error('Bridge failed:', error)
      if (isValidWalletError(error)) {
        trackHeapEvent(heapEvents.bridge, {
          status: heapStatuses.fail,
          mode: bridgeState.mode,
          token: bridgeState.token,
          chainIn: bridgeState.chainIn,
          chainOut: bridgeState.chainOut,
          amount: bridgeState.amount,
        })
      }
      // Clear bridge from history on failure
      if (bridgeId) {
        void patchBridgeHistoryMutation({
          path: { bridgeId },
          payload: { state: 'CANCELLED' },
        }).unwrap()
      }

      setProgress((previous) => ({
        ...previous,
        progressStep: BridgeProgressStep.Error,
      }))
    }
  }

  return { handleBridge }
}
