import { createSelector } from 'reselect'
import { selectAvailableChains } from '../apis/config.api'
import type { RootState } from '../configureStore'

export const selectAvailableChainsByToken = createSelector(
  [selectAvailableChains, (state: RootState) => state.bridge.token],
  (availableChains, token) => {
    if (!token) {
      return []
    }
    return availableChains.filter(({ config }) => Object.keys(config.tokens).includes(token))
  },
)

export const selectAvailableChainByPassedToken = createSelector(
  [selectAvailableChains, (_, data: { token: string; chain: string }) => data],
  (availableChains, { token, chain }) => {
    const filteredChains = availableChains.filter(
      ({ config, chain: availableChain }) => availableChain !== chain && Object.keys(config.tokens).includes(token),
    )
    const firstChain = filteredChains[0]

    return firstChain?.chain
  },
)

// Selector for the current `chainIn` and `chainOut` configurations
export const selectBridgeChainsConfig = createSelector(
  [selectAvailableChains, (state: RootState) => state.bridge.chainIn, (state: RootState) => state.bridge.chainOut],
  (availableChains, chainIn, chainOut) => {
    const chainInConfig = availableChains.find(({ chain }) => chain === chainIn)?.config
    const chainOutConfig = availableChains.find(({ chain }) => chain === chainOut)?.config
    return { chainInConfig, chainOutConfig }
  },
)

// Selector for the configuration of the currently selected token
export const selectBridgeChainsTokenConfig = createSelector(
  [selectBridgeChainsConfig, (state: RootState) => state.bridge.token],
  ({ chainInConfig, chainOutConfig }, token) => {
    const chainInTokenConfig = chainInConfig?.tokens[token]
    const chainOutTokenConfig = chainOutConfig?.tokens[token]
    return { chainInTokenConfig, chainOutTokenConfig }
  },
)
export const selectBridgeConfigForChain = createSelector(
  [selectAvailableChains, (_, chain: string) => chain],
  (availableChains, chain) => {
    return availableChains.find(({ chain: availableChain }) => availableChain === chain)?.config
  },
)
