import type { TokenInputProps } from '@rhinofi/dvf-shared-ui/lib/components/token-input/TokenInput'
import { translate } from '../../intl/i18n'

export const createTokenInputDictionary = (chainName: string): TokenInputProps['tokenSelectProps']['dictionary'] => ({
  all: translate('helpers.all'),
  chainFilterLabel: translate('bridge.filter_by_chain'),
  clearChainFilter: translate('helpers.clear'),
  cancel: translate('helpers.cancel'),
  select: translate('helpers.select'),
  emptyList: translate('helpers.no_search_results'),
  yours: translate('helpers.your_tokens'),
  others: translate('helpers.other_tokens'),
  placeholder: translate('helpers.token_search_placeholder'),
  listLabel: translate('global.select_token'),
  // Is added in the component
  unsupported: translate('helpers.unsupported_on_chain', {
    '%chain': chainName ?? '',
  }),
  innerListLabel: (chainFilter: string) => {
    if (chainFilter) {
      return translate('helpers.tokens_on', { '%chain': chainFilter })
    }

    return translate('helpers.available_tokens')
  },
})
