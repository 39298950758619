import { FieldToggle, Input, Spacing, ToggleLabel } from '@rhinofi/dvf-shared-ui'
import { LabelSize, TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { heapEvents } from '../../../../constants/heapEvents'
import { NON_EVM_CHAINS } from '../../../../constants/types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { translate } from '../../../../intl/i18n'
import { trackHeapEvent } from '../../../../services/apiService'
import { isNonEVMChain } from '../../../../services/helperService/isNonEVMChain'
import { shortenAddressLongForm } from '../../../../services/helperService/shortenAddressLongForm'
import { selectSecondaryWalletAddressAndChain } from '../../../../store/selectors/secondaryWallet.selectors'
import { selectAddress } from '../../../../store/selectors/user.selectors'
import { isBridgeToAnyRouteDisabled } from '../../helpers/isBridgeToAnyRouteDisabled'
import { InputActionKeys, ToggleableActionKeys, useBridgeState } from '../../hooks'
import type { BridgeFormValues } from '../../types/bridge-widget.types'
import { Destination } from '../BridgeWidget/BridgeWidget.styled'
import { OwnershipAndCompliance } from './OwnershipAndCompliance'

export const BridgeToAny = ({
  values,
  setFieldValue,
  valueError,
}: {
  values: BridgeFormValues
  setFieldValue: (field: string, value: string | boolean) => void
  valueError: boolean
}) => {
  const address = useAppSelector(selectAddress)
  const { secondaryWalletAddress, connectedChain } = useAppSelector(selectSecondaryWalletAddressAndChain)

  const { handleInputChange, handleToggleChange, bridgeState } = useBridgeState({ setFieldValue })

  const { isOtherAddress } = values
  const { chainIn, chainOut } = bridgeState

  const isNonEVMBridge = NON_EVM_CHAINS[chainOut]
  const userDestinationAddress = (isNonEVMBridge ? secondaryWalletAddress : address) || ''
  const isNonEVMToNonEVM = isNonEVMChain(chainIn) && isNonEVMChain(chainOut)
  const isEVMToNonEVM = !isNonEVMChain(chainIn) && isNonEVMChain(chainOut)
  const isNonEVMToEVM = isNonEVMChain(chainIn) && !isNonEVMChain(chainOut)
  const isEVMtoEVM = !isNonEVMChain(chainIn) && !isNonEVMChain(chainOut)
  const isNonEVMChainOutConnected = chainOut === connectedChain
  const isRouteDisabled = isBridgeToAnyRouteDisabled(chainIn, chainOut)
  const isFeatureDisabled = isNonEVMToNonEVM || isRouteDisabled

  useEffect(() => {
    if (isNonEVMToNonEVM || (isEVMToNonEVM && !isNonEVMChainOutConnected)) {
      handleToggleChange(ToggleableActionKeys.IsOtherAddress, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on flag change
  }, [isNonEVMToNonEVM, isEVMToNonEVM, isNonEVMChainOutConnected])

  useEffect(() => {
    if ((isRouteDisabled && isOtherAddress) || isNonEVMChainOutConnected || isNonEVMToEVM || isEVMtoEVM) {
      handleInputChange(InputActionKeys.Recipient, '')
      handleToggleChange(ToggleableActionKeys.IsOtherAddress, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on flag change
  }, [isRouteDisabled, isNonEVMChainOutConnected, isNonEVMToEVM, isEVMtoEVM])

  const handleToggle = useCallback(
    (isToggled: boolean) => {
      trackHeapEvent(heapEvents.toggleBridgeAnyAddress, { toggle: isToggled })
      if (!isToggled) {
        handleInputChange(InputActionKeys.Recipient, '')
      }
      handleToggleChange(ToggleableActionKeys.IsOtherAddress, isToggled)
    },
    [handleInputChange, handleToggleChange],
  )

  return (
    <Destination>
      <FlexContainer $gap="6px" $padding="0 12px">
        <FieldToggle
          name="isOtherAddress"
          color="primary"
          size={TextSize.L}
          reverse
          handleChange={handleToggle}
          disabled={isFeatureDisabled}
          isChecked={isOtherAddress}
        />
        <ToggleLabel size={LabelSize.Label} $selected={isOtherAddress} title={translate('global.other_address')}>
          {translate('bridge.send_to_address')}
        </ToggleLabel>
      </FlexContainer>
      {isOtherAddress && (
        <>
          <Spacing size="4" />
          <FlexContainer $fullWidth $gap="8px">
            <DestinationInputWrapper>
              {!values.isOtherAddress && userDestinationAddress && (
                <AddressOverlay>{shortenAddressLongForm(userDestinationAddress, 36)}</AddressOverlay>
              )}
              <Input
                showError={false}
                type="text"
                name="recipient"
                id="bridge-destination-address"
                disabled={!isOtherAddress}
                disableSetValueOnChange
                onInputChange={(recipientAddress) => {
                  handleInputChange(InputActionKeys.Recipient, recipientAddress)
                }}
                placeholder={
                  userDestinationAddress || isOtherAddress
                    ? translate('global.enter_address_here')
                    : translate('onboarding.connect_a_wallet')
                }
                isStandaloneInput
                valueError={valueError}
              />
              <OwnershipAndCompliance />
            </DestinationInputWrapper>
          </FlexContainer>
        </>
      )}
    </Destination>
  )
}

const DestinationInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;

  .input-outer-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  .input-wrapper {
    padding: 8px 12px;
  }
`

const AddressOverlay = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.elevationL2};
  color: ${({ theme }) => theme.inactiveText};

  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 0 12px;
  font-size: 13px;
  border: 1px solid transparent;
`
