/* eslint-disable @typescript-eslint/ban-tslint-comment -- Autogenerated file */
/* eslint-disable @eslint-community/eslint-comments/require-description -- Autogenerated file */
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from 'ethers'
import type { CrossChainSwapAbi, CrossChainSwapAbiInterface } from '../CrossChainSwapAbi'

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_token',
        type: 'address',
      },
    ],
    name: 'requestEmergencyWithdrawal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_token',
        type: 'address',
      },
    ],
    name: 'settleEmergencyWithdrawal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'userBalances',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export class CrossChainSwapAbi__factory {
  static readonly abi = _abi
  static createInterface(): CrossChainSwapAbiInterface {
    return new Interface(_abi) as CrossChainSwapAbiInterface
  }
  static connect(address: string, runner?: ContractRunner | null): CrossChainSwapAbi {
    return new Contract(address, _abi, runner) as unknown as CrossChainSwapAbi
  }
}
