import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShouldShowLegacyRhinoClaimsNotification } from '../../pages/LegacyRhinoClaims/hooks/useShouldShowLegacyRhinoClaimsNotification'
import type { TopAlertConfig } from './topAlerts.types'
import { publicRoutes } from '../../router'
import { translate } from '../../intl/i18n'

export const useTopAlertsConfig = (): TopAlertConfig[] => {
  const navigate = useNavigate()
  const shouldShowLegacyRhinoClaimsNotification = useShouldShowLegacyRhinoClaimsNotification()

  return useMemo(() => {
    const legacyRhinoClaimConfig: TopAlertConfig | null = shouldShowLegacyRhinoClaimsNotification
      ? {
          type: 'info',
          id: 'legacy-rhino-claim-notification',
          text: translate('claim.you_have_unclaimed_balances'),
          onClick: () => {
            navigate(publicRoutes.legacyRhinoClaim)
          },
          buttonText: translate('claim.claim_now'),
          hasCloseButton: true,
          saveInLsWithAddress: true,
        }
      : null

    return [...(legacyRhinoClaimConfig ? [legacyRhinoClaimConfig] : [])]
  }, [shouldShowLegacyRhinoClaimsNotification, navigate])
}
