import type { TopAlertConfig } from './topAlerts.types'

const getLastShowValue = ({
  address,
  saveInLsWithAddress,
  id,
}: {
  address: string | undefined
  saveInLsWithAddress: TopAlertConfig['saveInLsWithAddress']
  id: TopAlertConfig['id']
}) => {
  if (saveInLsWithAddress) {
    return address ? localStorage.getItem(`${id}-${address}`) : undefined
  }

  return localStorage.getItem(id)
}

export const shouldShowTopAlert = ({
  config: { endDate, startDate, intervalMs, id, saveInLsWithAddress },
  address,
}: {
  config: TopAlertConfig
  address: string | undefined
}) => {
  const lastShownValue = getLastShowValue({ address, saveInLsWithAddress, id })

  const now = new Date().getTime()
  const end = endDate ? new Date(endDate).getTime() : null
  const start = startDate ? new Date(startDate).getTime() : null
  const lastShown = lastShownValue ? new Date(lastShownValue).getTime() : null

  if (end) {
    const diff = end - now
    if (diff < 0) {
      return false
    }
  }

  if (start) {
    const diff = start - now
    if (diff > 0) {
      return false
    }
  }

  if (lastShown && intervalMs) {
    const diff = now - lastShown
    if (diff < intervalMs) {
      return false
    }
  }

  if (lastShown && !intervalMs) {
    return false
  }

  return true
}
