import Decimal from 'decimal.js'
import { translate } from '../../../intl/i18n'
import { makeBaseAmountValidator } from './helpers/makeBaseAmountValidator'

export const bridgeAmountValidator = makeBaseAmountValidator<{
  // General
  token?: string
  nativeToken?: string
  payAmount: string
  // Safeguard
  safeguardAmount?: number
}>({})
  .test('safeguard-warning', '', (_, { options, createError }) => {
    const { payAmount, balance } = options.context || {}
    if (!payAmount || !balance) {
      return true
    }

    const { token, nativeToken, safeguardAmount } = options.context || {}

    if (token !== nativeToken) {
      return true
    }
    if (new Decimal(balance).minus(payAmount.toString()).lt(safeguardAmount || 0)) {
      return createError({
        message: translate('global.native_token_gas_warning', {
          '%token': nativeToken || '',
          '%amount': safeguardAmount || '',
        }),
      })
    }

    return true
  })
  // Final amount with gas boost and fee should not be more than the balance
  .test('min-fee', translate('errors.insufficient_balance'), (_, { options }) => {
    const { payAmount, balance } = options.context || {}
    if (!balance || !payAmount) {
      return true
    }

    if (new Decimal(balance.toString()).lt(payAmount)) {
      return false
    }

    return true
  })
