import {
  BridgeConnectedDropdown,
  BridgeConnectedWalletStyled,
  ConnectedWalletDot,
} from './BridgeConnectedWallet.styled'
import { useAppSelector } from '../../hooks'
import { selectAddress } from '../../store/selectors/user.selectors'
import { selectSecondaryWalletAddress } from '../../store/selectors/secondaryWallet.selectors'
import { selectBridge } from '../../pages/Bridge/slices/bridge.slice'
import { isNonEVMChain } from '../../services/helperService/isNonEVMChain'
import { shortenAddress } from '../../services/helperService/shortenAddress'
import { Dropdown, Icon, IconSizes, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { BridgeConnectedWalletContent } from './BridgeConnectedWalletContent'

export const BridgeConnectedWallet = () => {
  const bridgeState = useAppSelector(selectBridge)
  const address = useAppSelector(selectAddress)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)

  const connectedAddress = isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress || address : address

  return (
    <BridgeConnectedWalletStyled>
      <Dropdown
        element={
          <BridgeConnectedDropdown>
            <ConnectedWalletDot />{' '}
            <Text size={TextSize.S} color="textSecondary" bold>
              {shortenAddress(connectedAddress, false, 4)}
            </Text>
            <Icon id="caret-down-fill" color="textSecondary" size={IconSizes.XSmall} />
          </BridgeConnectedDropdown>
        }
        triggerType="click"
        strongBoxShadow
        propsToChildren
      >
        <BridgeConnectedWalletContent />
      </Dropdown>
    </BridgeConnectedWalletStyled>
  )
}
