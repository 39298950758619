/* eslint-disable @typescript-eslint/naming-convention -- token names*/
export const tokenPricesSnapshot: Record<string, number> = {
  ELON: 1.34936e-7,
  CORX: 0.00005359,
  LUS: 0.00074301,
  MONI: 0.00299652,
  ADX: 0.106044,
  GAFI: 1.25,
  MGP: 0.03795939,
  STARS: 0.00002389,
  PCNT: 0.00320961,
  ALPHA: 0.0367423,
  DHV: 0.01513692,
  DMLG: 0.00005528,
  BEL: 0.590811,
  '8PAY': 0.00142251,
  LIQ: 0.00530369,
  MNY: 5.45474e-7,
  HOP: 0.00517568,
  CIV: 0.00129216,
  GENE: 0.07019,
  GUM: 0.00731079,
  BCL: 0.0006027,
  DEFY: 0.00002626,
  NLC: 0.0146028,
  APRIL: 0.00029716,
  HOOK: 0.135174,
  GMR: 0.0014096,
  ANKR: 0.01707917,
  ACT: 0.195098,
  PENDLE: 2.1,
  GHST: 0.394795,
  DUEL: 0.01695525,
  RBC: 0.01433286,
  AXS: 3.13,
  EGG: 0.00850086,
  MDX: 0.00203171,
  LFW: 0.00086599,
  ROOBEE: 0.00031787,
  MAHA: 0.49552,
  DPET: 0.00486441,
  ROY: 0.00079374,
  EBOX: 0.00050361,
  BTCMT: 0.609576,
  FET: 0.481229,
  NORD: 0.01007004,
  FCTR: 0.059101,
  BLZ: 0.04310507,
  RNDR: 2.97,
  ELVN: 0.00065722,
  ADOGE: 4.1086e-8,
  FIGHT: 0.0001441,
  WETH: 1911.12,
  ASIA: 0.095766,
  MTRG: 0.081986,
  HAY: 0.998387,
  DG: 0.00330434,
  KNC: 0.325601,
  HEGIC: 0.02314098,
  BTL: 0.00029482,
  PVU: 0.00100264,
  BABI: 0.00020535,
  BUX: 0.00295138,
  DSLA: 0.00012162,
  RAM: 0.00770395,
  GAIA: 0.00191586,
  ROUTE: 0.47451,
  BIFI: 178.73,
  ORBS: 0.01934831,
  ROCK: 0.00085154,
  JMPT: 0.889449,
  GRAIL: 461.03,
  CLS: 0.02996902,
  BICO: 0.113946,
  ZOON: 0.00076645,
  PRQ: 0.116567,
  XCAL: 0.00121836,
  DEPAY: 0.299864,
  MV: 0.00752829,
  PAR: 0.00008725,
  MCRN: 0.01305692,
  KALM: 0.01123477,
  ORO: 0.00042208,
  CHAI: 0.00329567,
  AMA: 0.00064979,
  MASK: 2.18,
  MGN: 165.08,
  MST: 0.00013004,
  NAKA: 0.347532,
  DEXE: 17.37,
  SHAK: 0.00001213,
  MCT: 0.121254,
  DERI: 0.00539891,
  MTVT: 0.00115413,
  FARA: 0.00278473,
  MATH: 0.13919,
  COR: 0.00019128,
  WELT: 0.00102928,
  GMD: 2.65,
  KALI: 0.00239809,
  CPS: 0.00000154,
  RPG: 0.085497,
  ASCEND: 0.01032454,
  GMM: 0.00005987,
  AFIT: 0.00279906,
  BHC: 0.655108,
  HNTR: 0.00204256,
  NWC: 0.02327981,
  RVF: 0.04909688,
  GTON: 0.03902438,
  LEV: 0.00194981,
  BANK: 0.00028588,
  INST: 5,
  MNST: 0.00057072,
  LVL: 0.062342,
  JONES: 0.080564,
  KROM: 0.00773762,
  PALM: 0.00323973,
  POLS: 0.228714,
  HWT: 0.00085669,
  COOK: 0.00007198,
  NGL: 0.02195532,
  GNY: 0.00384484,
  MCB: 2.01,
  DERC: 0.067247,
  L2DAO: 0.00014182,
  CTSI: 0.070255,
  LUSD: 1.002,
  NBT: 0.00124761,
  AGEUR: 1.092,
  DNXC: 0.00017995,
  LPOOL: 0.027135,
  ONI: 0.0425139,
  MAT: 0.00067332,
  CAKE: 1.52,
  BCT: 0.162131,
  HOTCROSS: 0.01111051,
  LFI: 0.00000723,
  HFT: 0.065152,
  NAFT: 0.00029392,
  BONDLY: 0.00112287,
  $GENE: 0.00016951,
  MASQ: 0.083703,
  NMX: 0.00328675,
  POOL: 0.390614,
  INJ: 9.27,
  ALPACA: 0.081204,
  $INR: 0.00706683,
  GOB: 0.00022039,
  IUX: 0.00012122,
  BMON: 0.00256296,
  RDPX: 1.5,
  RAGE: 0.00015484,
  CWT: 0.00112703,
  CCV2: 0.814635,
  KATA: 0.00015574,
  BBC: 0.00002739,
  KAI: 0.00130543,
  BULL: 0.00113114,
  BURGER: 0.191844,
  AIMX: 0.01230592,
  GSPI: 0.878896,
  BAPE: 0.00079082,
  GAL: 0.930086,
  NFTB: 0.0010107,
  KTN: 0.01849458,
  AUTO: 7.7,
  BRKL: 0.00346185,
  UFI: 0.01802296,
  BABY: 0.0015462,
  METO: 0.00010931,
  FRONT: 0.229408,
  LBL: 0.00128557,
  PERI: 0.01289337,
  QUAI: 0.00284531,
  EMON: 0.00051395,
  ITSB: 0.00001,
  OOE: 0.00447329,
  DOME: 0.00014194,
  LAND: 0.596224,
  FRAX: 0.998056,
  MFI: 4693.64,
  NRV: 0.00160437,
  QMALL: 0.00842222,
  COPI: 0.01627339,
  LEPA: 0.00093842,
  HAPPY: 6.5997e-8,
  RAINI: 0.00040976,
  DEP: 0.00118072,
  FXS: 1.47,
  POLAR: 0.00080623,
  RVC: 0.00082045,
  WARS: 0.0001193,
  CELR: 0.0094894,
  SFP: 0.53462,
  QANX: 0.03092282,
  AIOZ: 0.260561,
  OVR: 0.118424,
  MOD: 0.00095755,
  SFUND: 0.635944,
  ARGON: 0.0003674,
  ROSN: 0.00221726,
  CUMMIES: 0.00277842,
  FROYO: 0.00005696,
  DFYN: 0.00347353,
  MBOX: 0.082813,
  MAGIC: 0.136043,
  PAINT: 0.00001425,
  C98: 0.067103,
  CAN: 0.00027272,
  AIR: 6.3853e-10,
  MYC: 0.00006676,
  PLS: 0.01419574,
  POG: 0.00646777,
  RFOX: 0.00031694,
  ROOM: 0.00013167,
  RADIO: 0.00004367,
  FOR: 0.00130181,
  QATAR: 0.00002975,
  DEFIT: 0.04799107,
  FSN: 0.01476023,
  GOHM: 5924.08,
  DSQ: 1.11,
  OKG: 0.00007905,
  LPT: 5.21,
  PAPER: 0.00047651,
  LEVI: 0.123691,
  COMBO: 0.156853,
  LODE: 0.00449655,
  RGP: 0.00643076,
  PICKLE: 0.184483,
  TRVL: 0.00919282,
  MIX: 0.00109068,
  TRADE: 0.02301649,
  CAP: 0.124097,
  GMX: 13.82,
  BCOIN: 0.0055224,
  NITRO: 0.00030209,
  DPX: 8.93,
  FABRIC: 0.00011488,
  DAFI: 0.00027357,
  PMON: 0.056716,
  FODL: 0.0004071,
  KPAD: 0.00072518,
  HAMI: 5.17464e-7,
  PACOCA: 0.0001288,
  CNT: 0.00036484,
  FIWA: 0.00002809,
  FUSE: 0.01866407,
  URUS: 1.26,
  GCOIN: 0.00134294,
  DBL: 0.00008875,
  MILK: 0.00027022,
  PBR: 0.00189235,
  BNSD: 0.00010226,
  JULD: 0.00065315,
  MLT: 0.00896491,
  BSW: 0.03362487,
  CPD: 0.00683889,
  O3: 0.00512056,
  DFX: 0.01188775,
  NUM: 0.02388874,
  ARB: 0.338929,
  TXL: 0.00088011,
  GOAL: 0.0049875,
  REV3L: 0.00115115,
  CEEK: 0.01667115,
  MIMATIC: 0.99849,
  MINT: 0.00000266,
  CAT: 3.86259e-10,
  BLID: 0.00094546,
  DFY: 0.00015866,
  KART: 0.00459315,
  CHRP: 0.00196637,
  DELOT: 0.00008164,
  $ANRX: 0.00007819,
  ALU: 0.04160446,
  FORM: 0.00046309,
  JGN: 0.00773782,
  ATA: 0.050172,
  PYM: 0.00057167,
  DFTL: 0.01993777,
  ANN: 0.00350338,
  FLAME: 0.00409028,
  FCD: 0.00393739,
  ITAMCUBE: 0.0032932,
  LYRA: 0.0005409,
  FAST: 0.00044593,
  BFR: 0.00176678,
  CAPS: 0.0020527,
  BOB: 0.998614,
  DEXT: 0.278149,
  PET: 0.01019713,
  ZKP: 0.01054943,
  MLS: 0.00002958,
  BUSD: 1.001,
  FRAG: 0.00240294,
  SCLP: 0.03692016,
  NYAN: 0.0008692,
  DESU: 0.00556256,
  FOTA: 0.00002512,
  PEEL: 0.00128704,
  EKTA: 0.00045053,
  ARI10: 0.00052557,
  IDIA: 0.02449016,
  MINTME: 0.00399606,
  RAZOR: 0.00131364,
  CTI: 0.00883722,
  GRB: 0.12026,
  AVAX: 17.55,
  EDE: 0.00010955,
  BLES: 0.00124375,
  KT: 0.00069586,
  BANANA: 3.3293e-8,
  BSCS: 0.00198145,
  ORION: 0.00084741,
  TUSD: 0.996671,
  SAKE: 0.00071923,
  CRTS: 0.00016955,
  HE: 0.00011132,
  IDNA: 0.00369454,
  XIV: 0.0026096,
  EJS: 0.00030495,
  FOX: 0.02684061,
  FNC: 0.00036571,
  BETU: 0.00008438,
  LIQD: 2686.79,
  KAKA: 0.00056632,
  GOVI: 0.0262474,
  RDT: 0.00604953,
  LACE: 0.00007486,
  IXT: 0.133833,
  GMEE: 0.00261731,
  HOOP: 0.00005018,
  PHX: 0.00112984,
  BAKE: 0.127503,
  FYN: 0.00220215,
  RDNT: 0.02354989,
  FRM: 0.0010881,
  NABOX: 0.00000247,
  OATH: 0.00091475,
  RAIDER: 0.00340358,
  NEST: 0.00041547,
  METIS: 16.41,
  LTO: 0.060373,
  BUY: 0.00524587,
  BETA: 0.01989158,
  LOA: 0.00125572,
  MIM: 1,
  '2GCC': 0.00019507,
  BUNNY: 0.0499828,
  MVL: 0.0030174,
  LCT: 0.00031425,
  SOFI: 0.0074018,
  ICHI: 0.445722,
  MRUN: 0.00013351,
  ETHO: 0.00726164,
  CREO: 0.01106802,
  DODO: 0.066963,
  MMPRO: 0.00619744,
  EPIK: 0.00231719,
  KRS: 0.00001073,
  GSTS: 0.00212275,
  LINA: 0.00174379,
  FEAR: 0.02990155,
  FANZ: 0.060099,
  REEF: 0.00046964,
  DON: 0.00257309,
  ANI: 0.00126339,
  REVV: 0.00131827,
  FND: 0.00010084,
  ALIEN: 0.00489937,
  PLA: 0.01728082,
  TAU: 0.0013698,
  JRT: 0.00202649,
  RAMP: 0.03797426,
  ALGOBLK: 0.00057135,
  FWT: 0.00004943,
  PKR: 0.00111677,
  NNI: 0.00229299,
  CXO: 0.194233,
  LAVAX: 0.00273668,
  ROYA: 0.00096102,
  PALLA: 0.01048344,
  ALPA: 0.00266525,
  GLCH: 0.00337731,
  POI$ON: 0.00425671,
  PWAR: 0.00049892,
  FINE: 0.00125161,
  ICE: 0.200348,
  PLOT: 0.00347832,
  XED: 0.00109381,
  ETX: 0.00001346,
  DINO: 0.00046389,
  FNDZ: 0.00022774,
  $FUR: 0.00147527,
  IF: 0.00637069,
  FUEL: 33.24,
  IQ: 0.00433283,
  CGG: 0.00305285,
  IBS: 0.00000121,
  INSUR: 0.00494583,
  BBS: 0.00218946,
  KUOR: 0.00015258,
  GQ: 0.00019289,
  SEBA: 0.00087968,
  POLYDOGE: 4.603e-9,
  ORAI: 2.77,
  MULTI: 0.722991,
  BRY: 0.01415814,
  QUICK: 30.48,
  QI: 0.01238375,
  PREMIA: 0.079027,
  F2C: 0.00023632,
  RELAY: 0.099974,
  IPAD: 0.00001556,
  ONSTON: 0.00041715,
  GET: 0.449425,
  PORTX: 0.00082415,
  FREE: 8.3257e-8,
  BSCPAD: 0.01986629,
  PLT: 2.19,
  LABS: 0.00003839,
  OPUL: 0.03537887,
  GNS: 1.4,
  KAMPAY: 0.00021556,
  ARC: 0.01510563,
  SNS: 0.00365239,
  MS: 0.00016164,
  CFI: 0.099915,
  MTS: 0.00097461,
  NEU: 0.060414,
  GUILD: 0.00102327,
  BOBC: 0.100005,
  MONSTA: 0.0000019,
  OM: 6.28,
  PROS: 0.469877,
  HERO: 0.0010379,
  XDG: 0.0265048,
  MTV: 0.00040142,
  GDE: 0.00008011,
  ARPA: 0.02658694,
  MMF: 0.00259378,
  NFTY: 0.00016542,
  GTE: 0.00021285,
  GNT: 5.63633e-10,
  SAITO: 0.0018417,
  GUD: 0.013153,
  ZEE: 0.00507844,
  YFI: 5020.37,
  NU: 0.052782,
  SWPR: 0.00688937,
  TWIN: 0.076888,
  'XTT-B20': 0.00020309,
  DRB: 0.00028313,
  SNFTS: 0.00074248,
  XTM: 0.00708284,
  XETA: 0.00132857,
  Y2K: 0.052057,
  DSETH: 2379.341588793651,
  WBNB: 555.25,
  VELA: 0.00793175,
  VOXEL: 0.073133,
  HI: 0.0001318,
  YIN: 0.00031524,
  '10SET': 0.072936,
  WMX: 0.00024996,
  VSTA: 0.02477492,
  DUSK: 0.094779,
  SDAO: 0.064012,
  MTA: 0.02990662,
  U: 7.93905e-7,
  AUST: 0.02880072,
  OKB: 40.88,
  ZRX: 0.239941,
  TAIKO: 0.691009,
  AI16Z: 0.167417,
  LIME: 0.01396106,
  BOND: 0.261356,
  USDC: 0.99988,
  HEZ: 3.35,
  BERA: 5.85,
  THALES: 0.102321,
  VLX: 0.00457998,
  MARU: 0.00590687,
  VHC: 0.00031863,
  SPA: 0.0151775,
  LINK: 13.09,
  WOMBAT: 0.00015496,
  UNFI: 0.247336,
  MKR: 1103.35,
  SPOOL: 0.239252,
  SHI: 0.00054222,
  SUPER: 0.408449,
  SX: 0.04671478,
  CEL: 0.096596,
  VSP: 0.204221,
  WAM: 0.00099322,
  POPCAT: 0.153228,
  HEI: 0.00019793,
  VAB: 0.00001661,
  YOU: 0.00078041,
  SHILL: 0.00045641,
  APE: 0.500188,
  BONK: 0.00001048,
  BAT: 0.14509,
  SAND: 0.277034,
  SHIB: 0.00001213,
  USX: 0.994957,
  PSTAKE: 0.02179385,
  WQT: 0.00220865,
  COMP: 40.36,
  BENJI: 0.01363692,
  MARSO: 0.00290063,
  mooStargateUSDT: 1148913735059.737,
  ZYB: 0.00223629,
  CRV: 0.41271,
  MATIC: 0.215438,
  AAVE: 175.12,
  FARTCOIN: 0.244726,
  MNT: 0.718223,
  TOSHI: 0.00035248,
  SXP: 0.186028,
  XDAO: 0.02270426,
  EXRD: 0.00698358,
  WSG: 1.28511e-10,
  WSB: 0.00017553,
  WNK: 0.00015408,
  SWAPZ: 0.00059518,
  YGG: 0.202968,
  STR: 0.00706507,
  UNB: 0.00010307,
  DOGINME: 0.00020732,
  YIELDUSD: 1.133144267373,
  UEDC: 0.00060134,
  TRIAS: 1.53,
  XVS: 5.31,
  TIP: 0.00033986,
  DOG: 0.00181642,
  TRAVA: 0.00016142,
  SWAP: 0.093353,
  DEGEN: 0.00307757,
  RAI: 3.07,
  IP: 5.54,
  ERP: 0.00034646,
  ALN: 0.00100099,
  UNI: 6,
  PENGU: 0.00592425,
  Z7: 0.00048974,
  TRONPAD: 0.00133204,
  'YF-DAI': 13.59,
  MLN: 8.95,
  MANA: 0.262702,
  CHAMP: 0.01548974,
  mooStargateUSDC: 1107679092548.1123,
  TAG: 0.00096412,
  'mooRamsesMAI-USDC': 1882122.192637696,
  STFX: 0.00489109,
  ULTI: 1.58003e-7,
  YLD: 0.00086413,
  BTC: 82425,
  TWT: 0.87044,
  SWINGBY: 0.00026181,
  TRUMP: 10.3,
  SUSHI: 0.606628,
  TBC: 0.00267794,
  TTK: 0.00020459,
  TCH: 0.00390937,
  GGT: 0.0000855,
  SPELL: 0.00057841,
  TKO: 0.190265,
  TON: 2.67,
  HUSL: 0.00187638,
  BRETT: 0.02959912,
  SFUSD: 0.00098042,
  TKP: 0.417969,
  SNX: 0.802526,
  USDD: 0.999368,
  SWASH: 0.00468443,
  ILV: 13.85,
  ROOK: 0.51478,
  SFUEL: 0.00023848,
  xDVF: 0.544063,
  VINU: 1.5929e-8,
  SDT: 0.273617,
  YIELDETH: 2647.2818999921174,
  OMG: 0.199734,
  CHESS: 0.087522,
  TND: 3.53,
  TRL: 0.00278269,
  VST: 0.99937,
  RENA: 0.00065939,
  CUSDT: 0.024927251554334798,
  DVF: 0.544063,
  HAKA: 0.00004609,
  WAMPL: 7.25,
  LRC: 0.104404,
  DAI: 0.99937,
  ZENITH: 0.00055485,
  wXNM: 40.2,
  BBANK: 0.00003706,
  FIU: 0.00018038,
  STMATIC: 0.29384082375768794,
  WOO: 0.082027,
  BABYDOGE: 1.157e-9,
  BAL: 1.42,
  INV: 33.39,
  TOKO: 0.00030201,
  ZKCRO: 0.079895,
  PNUT: 0.162714,
  BLUR: 0.102439,
  TSX: 0.0009003,
  WINR: 0.01228867,
  PSI: 0.00199944,
  LTC: 90.12,
  ZEFU: 0.00047213,
  XEND: 0.01423531,
  TRX: 0.222622,
  XCAD: 0.08164,
  GRT: 0.090172,
  MIGGLES: 0.02464547,
  SD: 0.641217,
  ETHBTCMOMENTUM: 1.234780638657,
  XPNET: 0.00046804,
  ANT: 0.143497,
  WIRTUAL: 0.00037526,
  FOREX: 0.00054301,
  ZODI: 0.00017315,
  BNB: 556.26,
  TOKE: 0.205977,
  DIS: 0.702702,
  XAUT: 2911.31,
  PSP: 0.01826375,
  XY: 0.01946035,
  NEC: 0.00711058,
  UFT: 0.097272,
  STG: 0.198416,
  XWIN: 0.01460749,
  FUN: 0.0019462,
  DPI: 70.82,
  PEPE: 3.3502e-8,
  PNL: 0.00306463,
  MOOV: 0.00104837,
  SGLY: 0.00012528,
  ICETH: 2269.4537165776997,
  TRY: 0.00000622,
  USDT: 0.999576,
  YAE: 0.00066952,
  MPL: 13,
  REN: 0.01069163,
  BADGER: 1.86,
  WOOP: 0.00048346,
  VOLTA: 0.0025721,
  TIDAL: 0.00002212,
  POL: 0.215317,
  DOGE: 0.165622,
  LON: 0.731642,
  ETHBTCTREND: 2.034818412422,
  YIELDBTC: 93225.88000515,
  SOMM: 0.00469058,
  DHT: 0.088162,
  TT: 0.00272154,
  TORN: 7.38,
  WSTETH: 2686.5688814499886,
  YVUSDT: 1.097403485098,
  MILK2: 0.00159503,
  MEW: 0.00177878,
  WIDI: 0.00006583,
  WIF: 0.444958,
  DDOS: 0.01599044,
  SLERF: 0.057182,
  WTF: 0.00065632,
  '1INCH': 0.19667,
  LDO: 0.926041,
  SYN: 0.181941,
  SKI: 0.03823589,
  TOWER: 0.0005106,
  VEED: 0.00142167,
  KEYCAT: 0.00149089,
  PRL: 0.00056378,
  TOR: 0.00272574,
  RBN: 0.161899,
  ZFM: 0.00008991,
  $SNOW: 0.00023762,
  WOD: 0.000008,
  SPS: 0.00566516,
  SOL: 123.42,
  RUNE: 1.12,
  PNK: 0.01353433,
  S: 0.430675,
  SURE: 0.00130709,
  ETH: 1914.68,
}
