import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { approveEVMTokenContract } from './approveEVMTokenContract'
import type { Signer } from 'ethers'
import { NETWORKS } from '../../../constants/types'
import { approveTronDeposit } from '../../../services/secondaryWallet/services/tronService/approveTronDeposit'

export type ApprovalContractCallProps = {
  bridgeContractAddress: string
  tokenAddress: string
  token: string
  amountWithDecimals: string
  amount: string
  requireAllowanceReset: boolean
  chain: string
  ethersSigner?: Signer | null | undefined
}

export const callApprovalContract = async (props: ApprovalContractCallProps) => {
  const { chain } = props
  if (isNonEVMChain(chain)) {
    if (chain === NETWORKS.TRON) {
      return approveTronDeposit(props)
    } else {
      throw new Error('Approvals not available for this chain.')
    }
  } else {
    return approveEVMTokenContract(props)
  }
}
