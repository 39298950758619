import { useLocation } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { publicRoutes } from '../../../router'
import { useLegacyRhinoClaimsData } from './useLegacyRhinoClaimsData'
import { isFeatureEnabled } from '../../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../../constants/types'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectAddress } from '../../../store/selectors/user.selectors'
import { setHasLegacyClaims } from '../../../store/slices/user.slice'

const isStarkexClaimEnabled = isFeatureEnabled(F_FLAGS_TYPES.STARKEX_CLAIM)
const LS_KEY_START = 'HIDE_LEGACY_CLAIMS_NOTIFICATION'

export const useShouldShowLegacyRhinoClaimsNotification = () => {
  const address = useAppSelector(selectAddress) || ''
  const dispatch = useAppDispatch()
  const location = useLocation()

  const lsKey = useMemo(() => `${LS_KEY_START}-${address}`, [address])
  const disabledInLs = useMemo(() => !!localStorage.getItem(lsKey), [lsKey])

  const skipForRoute = useMemo(() => location.pathname.includes(publicRoutes.legacyRhinoClaim), [location.pathname])
  const skip = skipForRoute || disabledInLs || !isStarkexClaimEnabled

  const { distributions, xChainTokenBalances, isLoadingAll, fetchedForAddress } = useLegacyRhinoClaimsData({ skip })

  const hasUnclaimedDistributions = useMemo(() => {
    const claimedDistributions = distributions.filter(({ claimStatus }) => claimStatus === 'claimed')
    // Show if there are any unclaimed or error claim status distributions
    return claimedDistributions.length !== distributions.length
  }, [distributions])

  const hasUnclaimed = useMemo(
    () => !isLoadingAll && ((xChainTokenBalances?.length ?? 0) > 0 || hasUnclaimedDistributions),
    [hasUnclaimedDistributions, isLoadingAll, xChainTokenBalances],
  )

  useEffect(() => {
    // Disable fetching and showing the notification again on next page load
    const addressHit = fetchedForAddress === address
    if (addressHit && !isLoadingAll) {
      if (xChainTokenBalances?.length === 0 && !hasUnclaimedDistributions) {
        localStorage.setItem(lsKey, 'true')
        dispatch(setHasLegacyClaims(false))
      } else if (hasUnclaimed) {
        dispatch(setHasLegacyClaims(true))
      }
    }
  }, [
    isLoadingAll,
    xChainTokenBalances,
    hasUnclaimedDistributions,
    address,
    fetchedForAddress,
    lsKey,
    hasUnclaimed,
    dispatch,
  ])

  return useMemo(() => !skip && hasUnclaimed, [skip, hasUnclaimed])
}
