import type { NavigationLinks, NavigationLinkSubLink } from './NavigationDesktop.types'
import { translate } from '../../intl/i18n'
import { makeRouteTo, publicRoutes } from '../../router'
import TrophyIcon from '../../assets/icons/trophy.svg?react'
import StatisticsIcon from '../../assets/icons/statistics.svg?react'
import { DEFAULT_FEATURE_CHAIN } from '../../pages/Chain/constants/activityTrackers.constants'
import { isFeatureEnabled } from '../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../constants/types'
import { getDefaultTokenDistribution } from '../../pages/TokenDistribution/utils/getDefaultTokenDistribution'

export const topNavigationHeight = '72px'

const isMemecoinTrackerEnabled = isFeatureEnabled(F_FLAGS_TYPES.MEMECOIN_TRACKER)
const isTokenDistributionEnabled = isFeatureEnabled(F_FLAGS_TYPES.TOKEN_DISTRIBUTION)

const MEMECOIN_LINK: NavigationLinkSubLink = {
  id: 'nav-mint-interact-memecoin-tracker',
  type: 'to',
  title: translate('memecoin_tracker.title'),
  to: publicRoutes.memecoinTracker,
}

const TOKEN_DISTRIBUTION_LINK: NavigationLinkSubLink = {
  id: 'nav-mint-interact-token-distribution',
  type: 'to',
  title: translate('token_distribution.menu_title'),
  to: makeRouteTo.tokenDistribution(getDefaultTokenDistribution()),
}

export const NAVIGATION_LINKS: NavigationLinks = [
  {
    id: 'nav-bridge',
    type: 'to',
    title: translate('bridge.bridge'),
    to: publicRoutes.bridge,
    showOnMobile: false,
  },
  {
    id: 'nav-nation',
    type: 'to',
    showOnMobile: true,
    mobileIcon: <TrophyIcon />,
    title: translate('helpers.rhino_nation'),
    to: publicRoutes.nation,
  },
  {
    id: 'nav-mint-interact',
    type: 'sub-links',
    mobileIcon: <StatisticsIcon />,
    title: translate('helpers.mint_interact'),
    showOnMobile: true,
    subLinks: [
      {
        id: 'nav-mint-interact-analytics',
        type: 'to',
        title: translate('realm.analytics'),
        to: makeRouteTo.analytics(DEFAULT_FEATURE_CHAIN),
      },
      {
        id: 'nav-mint-interact-mint-nfts',
        type: 'to',
        title: translate('helpers.mint_nfts'),
        to: makeRouteTo.nftMinter({ chain: DEFAULT_FEATURE_CHAIN }),
      },
      {
        id: 'nav-mint-interact-deploy-and-interact',
        type: 'to',
        title: translate('helpers.deploy_and_interact'),
        to: makeRouteTo.chainDeployContracts({ chain: DEFAULT_FEATURE_CHAIN }),
      },
      ...(isMemecoinTrackerEnabled ? [MEMECOIN_LINK] : []),
      ...(isTokenDistributionEnabled ? [TOKEN_DISTRIBUTION_LINK] : []),
    ],
  },
]
