import { translate } from '../../intl/i18n'
import { MobileMenu } from '../MobileMenu/MobileMenu'
import { MobileMenuContent } from '../MobileMenu/components/MobileMenuContent.styled'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useMobileMenuCloseOnExternalAction } from '../MobileMenu/hooks/useMobileMenuCloseOnExternalAction'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { selectAddress, selectHasLegacyClaims } from '../../store/selectors/user.selectors'
import { useEffect, useState } from 'react'
import { MobileMenuSectionConnectedEvmWallet } from '../MobileMenu/components/MobileMenuSectionConnectedEvmWallet'
import { MobileMenuSection } from '../MobileMenu/components/MobileMenuSection'
import { FlexContainer } from '../common/Wrappers/Containers'
import { ButtonVariant, Text, TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import WalletIcon from '../../assets/icons/wallet.svg?react'
import styled from 'styled-components'
import { publicRoutes } from '../../router'
import { ButtonLink } from '../common/Helpers/LinkHelpers'

export const MobileMenuConnectedWallets = () => {
  const userAddress = useAppSelector(selectAddress)
  const dispatch = useAppDispatch()
  const hasLegacyClaims = useAppSelector(selectHasLegacyClaims)

  const [menuOpen, setMenuOpen] = useState(true)

  const closeMenu = useMobileMenuCloseOnExternalAction({
    close: () => {
      setMenuOpen(false)
    },
    externalAction: () => {
      clearModal(dispatch)()
    },
  })

  useEffect(() => {
    if (!userAddress) {
      closeMenu()
    }
  }, [userAddress, dispatch, closeMenu])

  const controls = {
    closeMenu,
  }

  return (
    <MobileMenu show={menuOpen} headerText={translate('onboarding.connect_wallet')} handleClose={controls.closeMenu}>
      <MobileMenuContent>
        {hasLegacyClaims && (
          <MobileMenuSection title={translate('claim.you_have_unclaimed_balances')}>
            <ButtonLink
              id="dropdown-claim-link"
              to={publicRoutes.legacyRhinoClaim}
              variant={ButtonVariant.text}
              onClick={closeMenu}
            >
              {translate('claim.claim_now')}
            </ButtonLink>
          </MobileMenuSection>
        )}
        <MobileMenuSectionConnectedEvmWallet showBorder={false} />

        <MobileMenuSection title={translate('onboarding.connect_non_evm_wallet')}>
          <FlexContainer $justifyContent="center" $padding="24px 0 0" $gap="8px" $direction="column">
            <NotSupportedIconWrapper $justifyContent="center">
              <WalletIcon />
            </NotSupportedIconWrapper>

            <NotSupportedText size={TextSize.S} color="disabled" align={TypographyAlign.Center}>
              {translate('onboarding.non_evm_not_supported')}
            </NotSupportedText>
          </FlexContainer>
        </MobileMenuSection>
      </MobileMenuContent>
    </MobileMenu>
  )
}

const NotSupportedIconWrapper = styled(FlexContainer)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.actionHover};
`

const NotSupportedText = styled(Text)`
  max-width: 221px;
`
