import { type ConnectedQuotePayloadSchema } from '@rhinofi/bridge-api-spec'
import { unsafeFromString } from 'effect/BigDecimal'
import { useAppSelector } from '../../../hooks'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { useGetUserQuoteQuery } from '../../../store/apis/bridge.api'
import { selectSecondaryWalletAddress } from '../../../store/selectors/secondaryWallet.selectors'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { selectBridge } from '../slices/bridge.slice'
import { useDebouncedValues } from './useDebouncedValues'
import { useTrackBridgeLimitReached } from './useTrackBridgeLimitReached'
import { useBridgeAmount } from './useBridgeAmount'
import { isQuoteAmountValid } from '../helpers/isQuoteAmountValid'

type UseUserQuoteInput = {
  mode?: ConnectedQuotePayloadSchema['mode']
  amount?: string
  gasBoostAmountNative?: string | undefined
  skip?: boolean
}

export const useUserQuote = ({ mode = 'receive', gasBoostAmountNative, skip = false }: UseUserQuoteInput = {}) => {
  const bridgeState = useAppSelector(selectBridge)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const address = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const quoteAmount = useBridgeAmount()

  const depositor = (isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress : address) ?? ''
  const recipient =
    (bridgeState.recipient || (isNonEVMChain(bridgeState.chainOut) ? secondaryWalletAddress : address)) ?? ''

  const debouncedValues = useDebouncedValues({
    recipient,
    amount: quoteAmount,
  })

  const {
    currentData: userQuote,
    isError,
    error,
    isFetching,
  } = useGetUserQuoteQuery(
    {
      payload: {
        depositor,
        recipient: debouncedValues.recipient,
        amount: unsafeFromString(debouncedValues.amount),
        chainIn: bridgeState.chainIn,
        chainOut: bridgeState.chainOut,
        token: bridgeState.token,
        mode,
        ...(gasBoostAmountNative && { amountNative: unsafeFromString(gasBoostAmountNative) }),
      },
    },
    {
      skip:
        skip ||
        !isAuthenticated ||
        !depositor ||
        !isQuoteAmountValid(quoteAmount) ||
        // Fixes bug where the quote was being called with the 0 value
        // This happened because the amount was not 0 but the debounced value was still 0
        !isQuoteAmountValid(debouncedValues.amount) ||
        !bridgeState.chainIn ||
        !bridgeState.chainOut ||
        !bridgeState.token,
    },
  )

  useTrackBridgeLimitReached({
    quoteError: error,
    user: depositor,
    chainIn: bridgeState.chainIn,
    chainOut: bridgeState.chainOut,
    token: bridgeState.token,
  })

  return { quote: !isError ? userQuote : undefined, isError, error, isFetching }
}
