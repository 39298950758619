import { getTronProvider } from '../../providers/tronProvider'
import { nonEVMBridgeAbi } from '../../../abis/nonEVMBridgeAbi'
import type { BridgeContractCallProps } from '../../../../pages/Bridge/services/bridge.contract'

const pollTxUntilSuccess = async ({
  txHash,
  maxRetryCount,
  timeout,
  retryCount,
}: {
  txHash: string
  maxRetryCount: number
  timeout: number
  retryCount: number
}): Promise<true | undefined> => {
  const { tronWeb } = getTronProvider()

  if (!tronWeb) {
    return
  }

  const retryPoll = async (error: unknown) => {
    if (retryCount < maxRetryCount) {
      await new Promise((resolve) => setTimeout(resolve, timeout))

      return pollTxUntilSuccess({
        txHash,
        maxRetryCount,
        timeout,
        retryCount: retryCount + 1,
      })
    } else {
      console.error(error)
      console.error(`Tron tx poll timeout: ${txHash}`)
      return undefined
    }
  }

  try {
    const { receipt } = await tronWeb.trx.getUnconfirmedTransactionInfo(txHash)

    if (!receipt) {
      return retryPoll('')
    }

    if (receipt.result !== 'SUCCESS') {
      throw new Error(`NOT_SUCCESS`)
    }

    return true
  } catch (error) {
    if (error instanceof Error && error.message === 'NOT_SUCCESS') {
      throw new Error(`TX ${txHash} receipt result is not SUCCESS`)
    }

    return retryPoll(error)
  }
}

export const handleTronDeposit = async ({
  bridgeContractAddress,
  tokenAddress,
  amountWithDecimals,
  commitmentId,
  callback,
}: BridgeContractCallProps) => {
  const { tronWeb } = getTronProvider()
  if (!tronWeb) {
    throw new Error('Please connect your Tron wallet first.')
  }
  const contract = tronWeb.contract(nonEVMBridgeAbi, bridgeContractAddress)
  if (!('depositWithId' in contract)) {
    return false
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- type is unknown
  const deposit = contract.depositWithId as (
    token: string,
    amount: string,
    commitmentId: string,
  ) => { send: (options?: { keepTxID: boolean }) => Promise<string> }

  const txHash = await deposit(tokenAddress, amountWithDecimals, `0x${commitmentId}`).send({
    keepTxID: true,
  })

  callback?.(txHash, commitmentId)

  await pollTxUntilSuccess({ txHash, maxRetryCount: 10, retryCount: 0, timeout: 5000 })

  return { transactionHash: txHash }
}
