import { Button, ButtonSizes } from '@rhinofi/dvf-shared-ui'
import { modalKeys } from '../../constants/modalKeys'
import { useAppDispatch } from '../../hooks'
import { useWallet } from '../../hooks/useWallet'
import { translate } from '../../intl/i18n'
import { showModal } from '../../store/actions/modalActions/showModal'

export const ConnectWalletButton = () => {
  const dispatch = useAppDispatch()
  const wallet = useWallet()
  const isConnectingWallet = wallet.connectingWallet.isConnecting

  const connectWallet = () => {
    showModal(dispatch)(modalKeys.connectWallet)
  }

  return (
    <Button size={ButtonSizes.Large} onClick={connectWallet} id="heap-connect-wallet">
      {isConnectingWallet ? translate('onboarding.connecting_wallet') : translate('onboarding.connect_wallet')}
    </Button>
  )
}
