import { useCallback } from 'react'
import type { MobileNavigationMenuControls, MobileNavigationMenuSection } from '../types/MobileNavigation.types'
import { isSafariIOS } from '../../../services/helperService'
import { MOBILE_MENU_SECTION_DEFAULT } from '../constants/MobileNavigation.constants'
import { translate } from '../../../intl/i18n'
import ChatIcon from '../../../assets/icons/chat.svg?react'
import { useCrispSupportUrl } from '../../../hooks/useCrispSupportUrl'

type Props = {
  controls: MobileNavigationMenuControls
}

export const useMobileNavigationMenuSections = ({ controls: { showChat } }: Props) => {
  const crispUrl = useCrispSupportUrl()

  const showCrispChat = useCallback(() => {
    // Open iframe in a new window as iOS safari doesn't support iframes with cross-origin cookies
    if (isSafariIOS()) {
      window.open(crispUrl)
      return
    }
    showChat()
  }, [showChat, crispUrl])

  const otherSection: MobileNavigationMenuSection = {
    id: 'mobile-nav-section-support',
    title: translate('helpers.get_help'),
    links: [
      {
        showOnMobile: true,
        mobileIcon: <ChatIcon />,
        type: 'action',
        id: 'mobile-nav-section-support-open',
        title: translate('helpers.customer_support'),
        handleAction: showCrispChat,
      },
    ],
  }

  return [MOBILE_MENU_SECTION_DEFAULT, otherSection]
}
