import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../../store/configureStore'

export enum BridgeProgressStep {
  Form = 'form',
  Pending = 'pending',
  Done = 'done',
  Error = 'error',
}

export enum BridgeErrorType {
  Timeout = 'timeout',
  WrongNetwork = 'wrongNetwork',
}

export enum BridgeSuccessType {
  BlocksTimeout = 'blocksTimeout',
}

export type BridgeProgressState = {
  type: 'approval' | 'deposit'
  isTxSubmitted: boolean
  isTxConfirmed: boolean
  timestamp: number | null
  isError: boolean
  token: string
  amount: string
  progressStep: BridgeProgressStep
  error: string
  errorType: BridgeErrorType | undefined
  successType: BridgeSuccessType | undefined
  bridgeId: string | undefined
  transactionHash: string | undefined
  withdrawTransactionHash: string | undefined
}

export const initialState: BridgeProgressState = {
  error: '',
  type: 'deposit',
  isTxSubmitted: false,
  isTxConfirmed: false,
  timestamp: null,
  isError: false,
  token: '',
  amount: '',
  progressStep: BridgeProgressStep.Form,
  errorType: undefined,
  successType: undefined,
  bridgeId: undefined,
  transactionHash: undefined,
  withdrawTransactionHash: undefined,
}

export const bridgeProgressSlice = createSlice({
  name: 'bridgeProgress',
  initialState,
  reducers: {
    setProgress(state, action: PayloadAction<BridgeProgressState>) {
      Object.assign(state, action.payload)
    },
    updateProgress(state, action: PayloadAction<(current: BridgeProgressState) => Partial<BridgeProgressState>>) {
      const update = action.payload(state)
      Object.assign(state, update)
      if (update.isError) {
        state.progressStep = BridgeProgressStep.Error
      }
    },
    resetProgress(state) {
      Object.assign(state, initialState)
    },
  },
})

export const { setProgress, updateProgress, resetProgress } = bridgeProgressSlice.actions

// Selectors
export const selectBridgeProgress = (state: RootState) => state.bridgeProgress
