import type { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'

type Props = {
  title: string
  showBorder?: boolean
}

export const MobileMenuSection: FC<PropsWithChildren<Props>> = ({ title, children, showBorder = true }) => (
  <Wrapper $padding="26px 0 16px" $direction="column" $alignItems="stretch" $gap="4px" $showBorder={showBorder}>
    <FlexContainer $padding="0 8px">
      <Text color="textSecondary" size={TextSize.XS}>
        {title}
      </Text>
    </FlexContainer>

    {children}
  </Wrapper>
)

const Wrapper = styled(FlexContainer)<{ $showBorder: boolean }>`
  border-top: ${({ theme, $showBorder }) => ($showBorder ? `1px solid ${theme.divider}` : 'none')};
`
