import { BridgeTab, BridgeTabs, HistoryCount } from './BridgeTabs.styled'
import { Text } from '@rhinofi/dvf-shared-ui'
import type { ReactNode } from 'react'

type TabProps<T extends string | number> = {
  label: string | ReactNode
  id: T
  notificationCount?: number | undefined
}

type TabsProps<T extends string | number> = {
  tabs: TabProps<T>[]
  onClick: (id: T) => void
  activeTab: T
  fullWidth?: boolean
  clickable?: boolean
}

export const TabSelect = <T extends string | number>({
  tabs,
  onClick,
  activeTab,
  fullWidth,
  clickable = true,
}: TabsProps<T>) => (
  <BridgeTabs $gap="24px" $flexWrap="wrap" $fullWidth={fullWidth}>
    {tabs.map(({ label, id, notificationCount = 0 }) => (
      <BridgeTab
        id={`bridge-tab-${id}`}
        key={id}
        onClick={() => onClick(id)}
        $active={activeTab === id}
        $clickable={clickable}
      >
        <Text color={activeTab === id ? undefined : 'textDisabled'} weight={600}>
          {label}
        </Text>

        {notificationCount > 0 && <HistoryCount>{notificationCount}</HistoryCount>}
      </BridgeTab>
    ))}
  </BridgeTabs>
)
