import type { FormikProps } from 'formik'
import { Form, Formik } from 'formik'
import { useMemo, useRef } from 'react'
import { useAppSelector } from '../../../../hooks'
import { selectBridgeChainsConfig } from '../../../../store/selectors/bridgeConfig.selectors'
import { normalizeErrors } from '../../helpers'
import { useBridge, useBridgeValidation, useMaxBalance, useQuote } from '../../hooks'
import { useConfirmationScreen } from '../../hooks'
import { useGasBoost } from '../../hooks'
import { selectBridge } from '../../slices/bridge.slice'
import { BridgeProgressStep, selectBridgeProgress } from '../../slices/bridgeProgress.slice'
import type { BridgeFormValues } from '../../types/bridge-widget.types'
import { BridgeFormState } from '../bridge-states/BridgeFormState'
import { ErrorBridgeState } from '../bridge-states/ErrorBridgeState'
import { PendingBridgeState } from '../bridge-states/PendingBridgeState/PendingBridgeState'
import { SuccessBridgeState } from '../bridge-states/SuccessBridgeState/SuccessBridgeState'
import { BridgeTransitionStates } from '../BridgeTransitionStates'
import { ConfirmationModals } from '../ConfirmationModals/ConfirmationModals'
import { FormWrapper, OverflowCoreCard } from './BridgeWidget.styled'
import { selectIsWidget } from '../../../../store/selectors/portal.selectors'

export const BridgeWidget = () => {
  const bridgeFormRef = useRef<FormikProps<BridgeFormValues> | null>(null)

  const isWidget = useAppSelector(selectIsWidget)
  const bridgeState = useAppSelector(selectBridge)
  const { progressStep, transactionHash } = useAppSelector(selectBridgeProgress)
  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)

  const { confirmationPromise, setConfirmationPromise, triggerConfirmationScreen } = useConfirmationScreen()
  const gasBoostOptions = useGasBoost()
  const { handleBridge } = useBridge({
    mode: bridgeState.mode,
    gasBoostAmountNative: gasBoostOptions.amountNative,
    triggerConfirmationScreen,
  })
  const { quote, error, isFetching } = useQuote({
    mode: bridgeState.mode,
    gasBoostAmountNative: gasBoostOptions.amountNative,
    errors: bridgeFormRef.current?.errors,
  })

  const { tokenBalanceChainIn, tokenBalanceChainOut, maxBridgeableAmount } = useMaxBalance()
  const { bridgeValidation } = useBridgeValidation({
    gasSafeGuard: chainInConfig?.nativeTokenSafeguard,
    payAmount: quote?.payAmount,
    bridgeFormRef,
  })

  const chainInName = chainInConfig?.name ?? ''
  const chainOutName = chainOutConfig?.name ?? ''

  const hasAmountOrRecipient = useMemo(
    () => Boolean(+bridgeState.amount > 0 || (bridgeState.isOtherAddress && bridgeState.recipient.length > 0)),
    [bridgeState.amount, bridgeState.isOtherAddress, bridgeState.recipient],
  )

  return (
    <OverflowCoreCard widget $isWidget={isWidget}>
      <FormWrapper>
        <Formik<BridgeFormValues>
          innerRef={bridgeFormRef}
          initialValues={{
            amount: bridgeState.amount,
            amountOut: bridgeState.amountOut,
            token: bridgeState.token,
            chainIn: bridgeState.chainIn,
            chainOut: bridgeState.chainOut,
            recipient: bridgeState.recipient,
            isOtherAddress: bridgeState.isOtherAddress,
            gasBoostEnabled: bridgeState.gasBoostEnabled,
          }}
          enableReinitialize
          onSubmit={handleBridge}
          validateOnMount={hasAmountOrRecipient}
          validate={bridgeValidation}
        >
          {({ setFieldValue, values, errors }) => {
            const normalizedError = normalizeErrors({
              quoteError: error,
              formErrors: errors,
              hasDepositAmount: !!values.amount,
              hasRecipient: !!values.recipient,
            })
            return (
              <Form>
                <BridgeTransitionStates currentState={progressStep}>
                  {({ step }) => {
                    switch (step) {
                      case BridgeProgressStep.Form:
                        return (
                          <BridgeFormState
                            values={values}
                            normalizedError={normalizedError}
                            setFieldValue={setFieldValue}
                            withdrawalFeeConfig={quote?.fees}
                            withdrawalPromoConfig={quote?.promotion}
                            payAmount={quote?.payAmount}
                            receiveAmount={quote?.receiveAmount}
                            tokenBalanceChainIn={tokenBalanceChainIn}
                            tokenBalanceChainOut={tokenBalanceChainOut}
                            maxBridgeableAmount={maxBridgeableAmount}
                            gasBoostOptions={gasBoostOptions}
                            isFetchingQuote={isFetching}
                          />
                        )
                      case BridgeProgressStep.Pending:
                        return (
                          <PendingBridgeState
                            transactionHash={transactionHash}
                            prettyChainInName={chainInName}
                            prettyChainOutName={chainOutName}
                          />
                        )
                      case BridgeProgressStep.Done:
                        return (
                          <SuccessBridgeState
                            prettyChainInName={chainInName}
                            prettyChainOutName={chainOutName}
                            payAmount={quote?.payAmount}
                          />
                        )
                      case BridgeProgressStep.Error:
                        return <ErrorBridgeState prettyChainInName={chainInName} />
                    }
                  }}
                </BridgeTransitionStates>
              </Form>
            )
          }}
        </Formik>
      </FormWrapper>
      {confirmationPromise && (
        <ConfirmationModals confirmationPromise={confirmationPromise} setConfirmationPromise={setConfirmationPromise} />
      )}
      {isWidget && <div id="widget" />}
    </OverflowCoreCard>
  )
}
