// src/services/AppLayer.ts
import { createApiFromEffectTagFactory } from '@rhinofi/effect-rtk'
import type { Context } from 'effect'
import { Layer, ManagedRuntime } from 'effect'
import { TokenDistributionClient } from '../pages/TokenDistribution/services/TokenDistributionClient'
import { BridgeClient } from '../services/bridge/BridgeClient'
import { SwapsClient } from '../pages/BridgeSwap/services/swapsApi/SwapsClient'

export const AppServiceTags = [
  // Add Service Tags here:
  BridgeClient,
  TokenDistributionClient,
  SwapsClient,
] as const

export type AppServiceTagsTypes = (typeof AppServiceTags)[number]
export type RuntimeServices = Context.Tag.Identifier<AppServiceTagsTypes>
export type AppContext = Context.Context<RuntimeServices>

export const AppLayerLive: Layer.Layer<RuntimeServices> = Layer.mergeAll(
  // Add Layers providing implementation for AppServiceTags here:
  BridgeClient.Default,
  TokenDistributionClient.Default,
  SwapsClient.Default,
)

export const AppRuntime = ManagedRuntime.make(AppLayerLive)
export const createApiFromEffectTag = createApiFromEffectTagFactory<RuntimeServices>()
