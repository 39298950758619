import { useEffect, type ReactNode, useState } from 'react'
import { animated, useSpringRef, useTransition, type UseTransitionProps } from '@react-spring/web'
import { clampedGentleFigmaFast } from '../../../constants/springs'
import type { BridgeTabsStep } from '../types/bridge-widget.types'
import type { BridgeProgressStep } from '../slices/bridgeProgress.slice'
import { disableTransformInStyleOnCondition } from '../helpers/disableTransformInStyleOnCondition'
import { useIsMobile } from '../../../hooks/useIsMobile'

type Props<T extends BridgeProgressStep | BridgeTabsStep> = {
  currentState: T
  children: ({ step }: { step: T }) => ReactNode
}

export const BridgeTransitionStates = <T extends BridgeProgressStep | BridgeTabsStep>({
  currentState,
  children,
}: Props<T>) => {
  const { isMobile } = useIsMobile()
  const [hideTransform, setHideTransform] = useState(false)

  const transRef = useSpringRef()
  const transitionProps: UseTransitionProps<T> = {
    ref: transRef,
    keys: null,
    from: isMobile ? {} : { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: isMobile ? {} : { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: isMobile ? {} : { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    config: clampedGentleFigmaFast,
    exitBeforeEnter: true,
    // @ts-expect-error: TS parsing issue with multiple declarations of the useTransition function
    onStart: () => {
      setHideTransform(false)
    },
    // @ts-expect-error: TS parsing issue with multiple declarations of the useTransition function
    onRest: () => {
      setHideTransform(true)
    },
  }

  const transitions = useTransition(currentState, transitionProps)

  useEffect(() => {
    void transRef.start()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on step change
  }, [currentState])

  if (!children) {
    return null
  }

  return (
    <>
      {transitions((style, step) => {
        const styleFormatted = disableTransformInStyleOnCondition({ style, hideTransform })

        return <animated.div style={styleFormatted}>{children({ step })}</animated.div>
      })}
    </>
  )
}
