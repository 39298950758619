import { translate } from '../../../intl/i18n'
import { useMobileMenuCloseOnExternalAction } from '../../MobileMenu/hooks/useMobileMenuCloseOnExternalAction'
import type { RequireChainContentProps } from '../types/RequireChainTypes'
import { useEffect, useState } from 'react'
import { MobilePopup } from '../../MobilePopup/MobilePopup'
import { RequireChainContent } from './RequireChainContent'

export const RequireChainPopup = (props: RequireChainContentProps) => {
  const [show, setShow] = useState(props.mustSwitchNetwork)

  const close = useMobileMenuCloseOnExternalAction({
    close: () => {
      setShow(false)
    },
    externalAction: () => {
      props.onClickClose()
    },
  })

  useEffect(() => {
    if (!props.mustSwitchNetwork && show) {
      close()
    }

    setShow(props.mustSwitchNetwork)
  }, [props.mustSwitchNetwork, close, show])

  return (
    <MobilePopup show={show} handleClose={close} headerText={translate('global.require_chain_modal_title')} alwaysOpen>
      <RequireChainContent {...props} />
    </MobilePopup>
  )
}
