import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRedirects } from './components/App/AppRedirects'
import { AppLoader } from './components/common/Helpers/AppLoader'
import { GlobalModals } from './components/GlobalModals'
import { HeaderLayout } from './components/HeaderLayout/HeaderLayout'
import { Social } from './components/Social/Social'
import { TopAlerts } from './components/TopAlerts/TopAlerts'
import { F_FLAGS_TYPES } from './constants/types'
import { ResizeContextProvider } from './contexts'
import { useAppSelector } from './hooks'
import { usePollEndpoints } from './hooks/usePollEndpoints'
import { useQueryParamsTracker } from './hooks/useQueryParamsTracker'
import { BridgePage } from './pages/Bridge/BridgePage'
import { IframePlayground } from './pages/IframePlayground/IframePlayground'
import { NationMissionsNotifications } from './pages/Nation/components/NationContent/NationMissionsNotifications/NationMissionsNotifications'
import { Main } from './Root.styled'
import { publicRoutes } from './router'
import { useChainBalances } from './services/ethereum/useChainBalances'
import { isFeatureEnabled } from './services/helperService/isFeatureEnabled'
import { selectIsWidget } from './store/selectors/portal.selectors'
import { useObserveWCModal } from './components/Widget/useObserveWCModal'

const isMemecoinTrackerEnabled = isFeatureEnabled(F_FLAGS_TYPES.MEMECOIN_TRACKER)
const isStarkexClaimEnabled = isFeatureEnabled(F_FLAGS_TYPES.STARKEX_CLAIM)

const NationRoutes = lazy(() =>
  import('./pages/Nation/NationRoutes').then((module) => ({
    default: module.NationRoutes,
  })),
)

const ChainRoutes = lazy(() =>
  import('./pages/Chain/ChainRoutes').then((module) => ({
    default: module.ChainRoutes,
  })),
)

const HistoryPage = lazy(() =>
  import('./pages/History/HistoryPage').then((module) => ({
    default: module.HistoryPage,
  })),
)

const MemecoinTracker = lazy(() =>
  import('./pages/MemecoinTracker/MemecoinTracker').then((module) => ({
    default: module.MemecoinTracker,
  })),
)

const StarkexClaims = lazy(() =>
  import('./pages/LegacyRhinoClaims/LegacyRhinoClaims').then((module) => ({
    default: module.StarkexClaims,
  })),
)

export const MainApp = () => {
  const isWidget = useAppSelector(selectIsWidget)

  useChainBalances()
  usePollEndpoints()
  useQueryParamsTracker()
  useObserveWCModal()

  return (
    <ResizeContextProvider>
      <Main $isWidget={isWidget}>
        {!isWidget && <TopAlerts />}

        <HeaderLayout>
          <Suspense fallback={<AppLoader showOnPage source="suspense-mainapp" />}>
            <Routes>
              {isWidget ? (
                <>
                  <Route path={publicRoutes.bridge} element={<BridgePage />} />
                  <Route path="*" element={<BridgePage />} />
                </>
              ) : (
                <>
                  <Route path={publicRoutes.bridge} element={<BridgePage />} />
                  <Route path={`${publicRoutes.nation}/*`} element={<NationRoutes />} />
                  <Route path={`${publicRoutes.chainFeature}/*`} element={<ChainRoutes />} />
                  <Route path={publicRoutes.history} element={<HistoryPage />} />
                  {isMemecoinTrackerEnabled && (
                    <Route path={publicRoutes.memecoinTracker} element={<MemecoinTracker />} />
                  )}
                  {isStarkexClaimEnabled && <Route path={publicRoutes.legacyRhinoClaim} element={<StarkexClaims />} />}
                  <Route path="/playground" element={<IframePlayground />} />
                  {AppRedirects()}
                </>
              )}
            </Routes>
          </Suspense>
        </HeaderLayout>

        <GlobalModals />
        {!isWidget && (
          <>
            <Social />
            <NationMissionsNotifications />
          </>
        )}
      </Main>
    </ResizeContextProvider>
  )
}
