import { DepositLimitReachedSchema, WithdrawLimitReachedSchema } from '@rhinofi/bridge-api-spec'
import { Match, Schema } from 'effect'
import { useCallback, useEffect, useState } from 'react'
import { heapEvents } from '../../../constants/heapEvents'
import { trackHeapEvent } from '../../../services/apiService'
import type { BridgeApiError } from '../../../store/apis/bridge.api'

type Props = {
  quoteError: BridgeApiError | undefined
  user: string
  chainIn: string
  chainOut: string
  token: string
}

export const useTrackBridgeLimitReached = ({ quoteError, user, chainIn, chainOut, token }: Props) => {
  const [eventsAlreadySent, setEventsAlreadySent] = useState(new Set())

  const makeEventKey = useCallback(
    (type: 'deposit' | 'withdraw') => `${type}-${user}-${chainIn}-${chainOut}-${token}`,
    [user, chainIn, chainOut, token],
  )

  useEffect(() => {
    if (quoteError) {
      Match.value(quoteError).pipe(
        Match.when(Match.undefined, () => null),
        Match.tags({
          WithdrawLimitReached: (taggedError) => {
            const eventKey = makeEventKey('withdraw')
            if (!eventsAlreadySent.has(eventKey)) {
              trackHeapEvent(
                heapEvents.withdrawlLimitReached,
                Schema.encodeSync(WithdrawLimitReachedSchema)(taggedError),
              )
              setEventsAlreadySent((currentValue) => currentValue.add(eventKey))
            }
          },
          DepositLimitReached: (taggedError) => {
            const eventKey = makeEventKey('deposit')
            if (!eventsAlreadySent.has(eventKey)) {
              trackHeapEvent(heapEvents.depositLimitReached, Schema.encodeSync(DepositLimitReachedSchema)(taggedError))
              setEventsAlreadySent((currentValue) => currentValue.add(eventKey))
            }
          },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no need to include eventsAlreadySent
  }, [quoteError, makeEventKey])
}
