import type { Middleware } from '@reduxjs/toolkit'
import { setWallet } from '../slices/user.slice'
import { gatedFeaturesApi } from '../../pages/Nation/services/gatedFeatures.api'
import { nationApi } from '../../pages/Nation/services/nation.api'
import { mintedNFTsApi } from '../../pages/Chain/services/mintedNFTsApi'
import { userApi } from '../../services/userApi'
import { authApi } from '../apis/auth.api'
import { referralApi } from '../../services/referralApi'
import { contractsInteractionsApi } from '../../pages/Chain/services/contractsInteractions.api'
import { bridgeApi } from '../apis/bridge.api'
import { activityTrackersApi } from '../../pages/Chain/services/activityTrackers.api'
import { tokenDistributionApi } from '../../pages/TokenDistribution/services/tokenDistribution.api'

export const clearRTKCacheMiddleware: Middleware = (storeApi) => (next) => (action) => {
  if (setWallet.match(action)) {
    storeApi.dispatch(authApi.util.resetApiState())
    storeApi.dispatch(bridgeApi.util.resetApiState())
    storeApi.dispatch(userApi.util.resetApiState())
    storeApi.dispatch(mintedNFTsApi.util.resetApiState())
    storeApi.dispatch(contractsInteractionsApi.util.resetApiState())
    storeApi.dispatch(activityTrackersApi.util.resetApiState())
    storeApi.dispatch(gatedFeaturesApi.util.resetApiState())
    storeApi.dispatch(tokenDistributionApi.util.resetApiState())

    storeApi.dispatch(referralApi.util.resetApiState())
    // Only clear the cache of the endpoints related to the user address
    storeApi.dispatch(nationApi.util.invalidateTags(['Authenticated']))
  }

  return next(action)
}
