import { FetchHttpClient, HttpApiClient } from '@effect/platform'
import { flattenHttpApiClient } from '@rhinofi/effect-rtk'
import { apiSpec } from '@rhinofi/swaps-api-spec'
import { Effect } from 'effect'
import { AppConfig } from '../../../../services/AppConfig'

export class SwapsClient extends Effect.Service<SwapsClient>()('SwapsClient', {
  effect: Effect.gen(function* () {
    const baseUrl = `${yield* AppConfig.tradingApi}/swaps`

    const client = yield* HttpApiClient.make(apiSpec, { baseUrl })

    return flattenHttpApiClient(client)
  }),
  dependencies: [AppConfig.Default, FetchHttpClient.layer],
}) {}
