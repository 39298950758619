import { MOBILE_MENU_EXIT_TRANSITION_LENGTH } from '../constants/MobileMenu.constants'

export const useMobileMenuCloseOnExternalAction =
  ({ externalAction, close }: { externalAction: () => void; close: () => void }) =>
  () => {
    close()
    setTimeout(() => {
      externalAction()
    }, MOBILE_MENU_EXIT_TRANSITION_LENGTH)
  }
