import { Button, ButtonVariant, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { Link } from 'react-router-dom'
import RhinoWithText from '../../../assets/icons/rhino-with-text.svg?react'
import { modalKeys } from '../../../constants/modalKeys'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { translate } from '../../../intl/i18n'
import { publicRoutes } from '../../../router'
import { showModal } from '../../../store/actions/modalActions/showModal'
import { selectAddress } from '../../../store/selectors/user.selectors'
import { MobileHeaderContainer } from '../MobileNavigation.styled'
import { MenuConnectedWalletsButtonContent } from '../../Menu/MenuConnectedWalletsButtonContent'
import styled from 'styled-components'

export const MobileHeader = () => {
  const dispatch = useAppDispatch()
  const walletAddress = useAppSelector(selectAddress)

  return (
    <MobileHeaderContainer>
      <Link to={publicRoutes.bridge} className="logo-link">
        <RhinoWithText />
      </Link>
      {!walletAddress ? (
        <Button id="mobile-connect" onClick={() => showModal(dispatch)(modalKeys.connectWallet)}>
          <Text size={TextSize.XS} bold>
            {translate('global.connect')}
          </Text>
        </Button>
      ) : (
        <ConnectedWalletsButton
          id="mobile-connected-wallets"
          variant={ButtonVariant.ghost}
          onClick={() => {
            showModal(dispatch)(modalKeys.connectedWalletsMobile)
          }}
        >
          <MenuConnectedWalletsButtonContent />
        </ConnectedWalletsButton>
      )}
    </MobileHeaderContainer>
  )
}

const ConnectedWalletsButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: none;
`
