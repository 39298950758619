import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  selectSecondaryWalletAddress,
  selectSecondaryWalletChain,
} from '../../store/selectors/secondaryWallet.selectors'
import { useCopyText } from '../common/Helpers/Copy'
import { disconnectSecondaryWallet } from '../../store/slices/secondaryWallet.slice'
import { useWallet } from '../../hooks/useWallet'
import { selectAddress, selectConnectedNetwork } from '../../store/selectors/user.selectors'
import { FlexContainer } from '../common/Wrappers/Containers'
import { TextSize, TokenIcon, Button, Icon, Text } from '@rhinofi/dvf-shared-ui'
import { shortenAddress } from '../../services/helperService/shortenAddress'
import { selectBridgeConfig } from '../../store/apis/config.api'
import { findBridgeKeyForNetworkId } from '../../services/helperService/findBridgeKeyForNetworkId'

export const BridgeConnectedWalletContent = ({ close = () => {} }: { close?: () => void }) => {
  const dispatch = useAppDispatch()

  const address = useAppSelector(selectAddress)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const secondaryWalletChain = useAppSelector(selectSecondaryWalletChain)
  const network = useAppSelector(selectConnectedNetwork)
  const bridgeConfig = useAppSelector(selectBridgeConfig)
  const chainID = findBridgeKeyForNetworkId(network?.toString() || '', bridgeConfig) || ''

  const { copyToClipboard: copyPrimary } = useCopyText({
    disabled: false,
    text: address || '',
  })

  const { copyToClipboard: copySecondary } = useCopyText({
    disabled: false,
    text: secondaryWalletAddress,
  })

  const wallet = useWallet()

  const disconnectPrimary = async () => {
    await wallet.disconnect()
    close()
  }

  const disconnectSecondary = () => {
    dispatch(disconnectSecondaryWallet())
    close()
  }
  return (
    <FlexContainer $gap="20px" $direction="column" $fullWidth>
      {address && (
        <FlexContainer $gap="8px" $fullWidth $justifyContent="space-between">
          <FlexContainer $gap="8px" $flexGrow="1" $fullWidth $justifyContent="space-between">
            <TokenIcon token={chainID} customSize="20px" />
            <Text size={TextSize.XS} bold>
              {shortenAddress(address, false, 4)}
            </Text>
            <Icon id="copy" active handleClick={copyPrimary} />
          </FlexContainer>
          <Button id="disconnect-widget" iconLeft="power" onClick={disconnectPrimary}>
            Disconnect
          </Button>
        </FlexContainer>
      )}
      {secondaryWalletAddress && (
        <FlexContainer $gap="8px" $fullWidth $justifyContent="space-between">
          <FlexContainer $gap="8px" $justifyContent="space-between" $flexGrow="1" $fullWidth>
            <TokenIcon token={secondaryWalletChain} customSize="20px" />
            <Text size={TextSize.XS} bold>
              {shortenAddress(secondaryWalletAddress, false, 4)}
            </Text>
            <Icon id="copy" active handleClick={copySecondary} />
          </FlexContainer>
          <Button id="disconnect-widget" iconLeft="power" onClick={disconnectSecondary}>
            Disconnect
          </Button>
        </FlexContainer>
      )}
    </FlexContainer>
  )
}
