import type { Highlights } from '@rhinofi/campaigns-api-spec'
import { Text, TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { Modal } from '../../../../../components/common/Modal/Modal'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { useIsMobile } from '../../../../../hooks/useIsMobile'
import { translate } from '../../../../../intl/i18n'
import { clearModal } from '../../../../../store/actions/modalActions/clearModal'
import { BridgeHighlightItem } from '../../BridgeHighlights/BridgeHighlightItem'
import { BridgeHighlightsContainer } from '../../BridgeHighlights/BridgeHighlights.styled'

type Props = {
  chain: string
  chainProjects: Highlights['items']
}

export const ExploreChainProjectsModal = ({ chain, chainProjects }: Props) => {
  const dispatch = useAppDispatch()
  const { isMobile } = useIsMobile()

  return (
    <Modal isVisible onClose={() => clearModal(dispatch)()} width="400px" increasedPadding={!isMobile}>
      <Container>
        <Text size={TextSize.M} color="secondary80" weight={600} align={TypographyAlign.Left}>
          {translate('bridge.explore_chain', { '%chain': chain })}
        </Text>
        <BridgeHighlightsContainer id="chain-projects-container">
          {chainProjects.map((item) => (
            <BridgeHighlightItem key={item.id} highlight={item} width={352} height={71} />
          ))}
        </BridgeHighlightsContainer>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: justify-center;
`
