import {
  Button,
  ButtonSizes,
  ButtonVariant,
  LabelSize,
  Text,
  TextSize,
  FieldToggle,
  ToggleLabel,
} from '@rhinofi/dvf-shared-ui'
import { useMemo, useCallback, useEffect } from 'react'
import { type GasBoostOptions, ToggleableActionKeys, useBridgeState } from '../../hooks'
import { useAppSelector } from '../../../../hooks'
import { selectBridgeChainsConfig } from '../../../../store/selectors/bridgeConfig.selectors'
import { GasBoostGrid } from '../BridgeWidget/BridgeWidget.styled'
import { translate } from '../../../../intl/i18n'
import { GasBoostButtons } from './GasBoost.styled'
import { NETWORKS } from '../../../../constants/types'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

const DEFAULT_GAS_BOOST_OPTIONS = [1, 2, 3, 5]

// XLAYER matches default, keeping as example for future
// chains requiring custom gas boost options.
const gasBoostPerChain: Record<string, number[]> = {
  [NETWORKS.XLAYER]: DEFAULT_GAS_BOOST_OPTIONS,
}

export const GasBoost = ({
  token,
  chainOut,
  gasBoostOptions,
  setFieldValue,
}: {
  token: string
  chainOut: string
  gasBoostOptions: GasBoostOptions
  setFieldValue: (field: string, value: string | boolean) => void
}) => {
  const { chainOutConfig } = useAppSelector(selectBridgeChainsConfig)

  const { handleToggleChange, bridgeState } = useBridgeState({ setFieldValue })

  const gasBoostValues = useMemo(() => {
    if (gasBoostPerChain.hasOwnProperty(chainOut)) {
      return gasBoostPerChain[chainOut] || DEFAULT_GAS_BOOST_OPTIONS
    }
    return DEFAULT_GAS_BOOST_OPTIONS
  }, [chainOut])

  const { setGasBoost, gasBoost } = gasBoostOptions

  const toggleLabelText = useMemo(() => {
    if (!chainOutConfig) {
      return ''
    }
    return translate('bridge.get_native_token_on_chain_name', {
      '%nativeToken': chainOutConfig.nativeTokenName,
      '%chain': chainOutConfig.name,
    })
  }, [chainOutConfig])

  const handleToggle = useCallback(
    (isEnabled: boolean) => {
      if (!isEnabled) {
        // On switching from enabled to disabled, set the gas boost value to 0
        setGasBoost(0)
      } else {
        // On switching from disabled to enabled, set the gas boost value to the first available value
        setGasBoost(gasBoostValues[0] ?? 0)
      }

      handleToggleChange(ToggleableActionKeys.GasBoostEnabled, isEnabled)
    },
    [handleToggleChange, setGasBoost, gasBoostValues],
  )

  useEffect(() => {
    if ((chainOutConfig?.nativeTokenName || '') === token || !chainOutConfig?.gasBoostEnabled) {
      handleToggleChange(ToggleableActionKeys.GasBoostEnabled, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only on token/chain change
  }, [token, chainOut, chainOutConfig])

  if (!chainOutConfig || chainOutConfig.nativeTokenName === token || !chainOutConfig?.gasBoostEnabled) {
    return null
  }

  return (
    <GasBoostGrid>
      <FlexContainer $direction="column" $gap="16px" $alignItems="stretch">
        <FlexContainer $gap="6px" $padding="0 12px">
          <FieldToggle
            name="gasBoostEnabled"
            color="primary"
            size={TextSize.L}
            reverse
            handleChange={handleToggle}
            isChecked={bridgeState.gasBoostEnabled}
          />
          <ToggleLabel size={LabelSize.Label} $selected={false} title={toggleLabelText}>
            {toggleLabelText} <span className="icon">{` ⛽`}</span>
          </ToggleLabel>
        </FlexContainer>

        {bridgeState.gasBoostEnabled && (
          <FlexContainer $direction="column" $gap="16px" $alignItems="stretch">
            <Text size={TextSize.XS} color="textSecondary">
              {translate('bridge.gas_boost_desc', {
                '%nativeToken': chainOutConfig.nativeTokenName,
                '%chain': chainOutConfig.name,
                '%value': gasBoost,
              })}
            </Text>

            <GasBoostButtons>
              {gasBoostValues.map((value) => (
                <Button
                  className="gas-boost-button"
                  id={`gas-boost-${value}`}
                  key={value}
                  variant={ButtonVariant.tab}
                  isSelected={value === gasBoost}
                  size={ButtonSizes.Small}
                  onClick={() => setGasBoost(value)}
                >
                  <Text size={TextSize.XS} weight={600}>
                    ${value}
                  </Text>
                </Button>
              ))}
            </GasBoostButtons>
          </FlexContainer>
        )}
      </FlexContainer>
    </GasBoostGrid>
  )
}
