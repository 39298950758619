import { ButtonVariant, Tooltip } from '@rhinofi/dvf-shared-ui'
import { Controls, HistoryButton } from './BridgeControls.styled'
import ChequeArrowIcon from '../../../../assets/icons/cheque-arrow.svg?react'
import { BridgeLayoutVisibleTab, BridgeTabsStep } from '../../types/bridge-widget.types'
import { selectBridgeLayoutVisibleTab, setActiveBridgeTab, setVisibleTab } from '../../slices/bridgeLayout.slice'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { translate } from '../../../../intl/i18n'

export const BridgeControls = () => {
  const dispatch = useAppDispatch()
  const visibleTab = useAppSelector(selectBridgeLayoutVisibleTab)
  const historyVisible = visibleTab === BridgeLayoutVisibleTab.history

  return (
    <Controls $justifyContent="flex-end">
      <Tooltip tooltipContent={historyVisible ? translate('bridge.hide_history') : translate('bridge.show_history')}>
        <HistoryButton
          id="bridge-open-history"
          variant={ButtonVariant.icon}
          onClick={() => {
            if (historyVisible) {
              dispatch(setVisibleTab(BridgeLayoutVisibleTab.none))
            } else {
              dispatch(setVisibleTab(BridgeLayoutVisibleTab.history))
              dispatch(setActiveBridgeTab(BridgeTabsStep.history))
            }
          }}
          $selected={historyVisible}
        >
          <ChequeArrowIcon />
        </HistoryButton>
      </Tooltip>
    </Controls>
  )
}
