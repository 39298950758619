import { MobileNavigationMenuWallet } from './MobileNavigationMenuWallet/MobileNavigationMenuWallet'
import { MobileNavigationMenuSocials } from './MobileNavigationMenuSocials'
import { MobileNavigationMenuSections } from './MobileNavigationMenuSections/MobileNavigationMenuSections'
import type { MobileNavigationMenuControls } from '../../types/MobileNavigation.types'
import { MobileMenuContent } from '../../../MobileMenu/components/MobileMenuContent.styled'

type Props = {
  controls: MobileNavigationMenuControls
}

export const MobileNavigationMenu = ({ controls }: Props) => (
  <MobileMenuContent>
    <MobileNavigationMenuWallet controls={controls} />
    <MobileNavigationMenuSections controls={controls} />
    <MobileNavigationMenuSocials />
  </MobileMenuContent>
)
