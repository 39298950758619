import { NETWORKS } from '../../../../constants/types'

export const PARADEX_ALLOWED_CHAINS = [
  NETWORKS.ARBITRUM,
  NETWORKS.AVALANCHE,
  NETWORKS.BINANCE,
  NETWORKS.ETHEREUM,
  NETWORKS.OPTIMISM,
  NETWORKS.MATIC_POS,
  NETWORKS.BASE,
  NETWORKS.LINEA,
  NETWORKS.MODE,
  NETWORKS.MANTLE,
  NETWORKS.SCROLL,
  NETWORKS.ZKSYNC,
  NETWORKS.MANTA,
  NETWORKS.STARKEX,
  NETWORKS.STARKNET,
  NETWORKS.SOLANA,
]

export const isParadexAllowedChain = ({ chain }: { chain: string }) => {
  return PARADEX_ALLOWED_CHAINS.includes(chain)
}
