import styled, { css } from 'styled-components'
import { Button, Text } from '@rhinofi/dvf-shared-ui'
import type { TopAlertType } from './topAlerts.types'

export const TopAlertContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`

export const TopAlertsPosition = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
`

const handleAlertBackgroundPerType = (type: TopAlertType) => {
  switch (type) {
    case 'info':
      return css`
        background: ${({ theme }) => theme.cardBackground};
      `
    default:
      return ''
  }
}

export const TopAlertContainer = styled.div<{ $type: TopAlertType }>`
  padding: 8px 16px;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  ${({ $type }) => handleAlertBackgroundPerType($type)};
`

export const TopAlertText = styled(Text)<{ $type: TopAlertType }>`
  font-weight: 600;

  ${({ $type }) =>
    $type === 'info'
      ? css`
          color: ${({ theme }) => theme.warningDark};
        `
      : css`
          color: ${({ theme }) => theme.contrast};
        `}
`

export const CloseButton = styled(Button)<{ $type: TopAlertType }>`
  padding: 0;
  margin: 0;
  border: none;

  ${({ $type }) =>
    $type === 'info'
      ? css`
          .coloredIcon {
            color: ${({ theme }) => theme.componentsIconPrimary};
          }
        `
      : css`
          .coloredIcon {
            color: ${({ theme }) => theme.componentsIconContrast};
          }
        `}
`

export const InfoIconWrapper = styled.div<{ $type: TopAlertType }>`
  display: flex;

  ${({ $type }) =>
    $type === 'info'
      ? css`
          svg path {
            fill: ${({ theme }) => theme.warningDark};
          }
        `
      : css`
          svg path {
            fill: ${({ theme }) => theme.componentsIconContrast};
          }
        `}
`

export const ActionButton = styled(Button)`
  flex-shrink: 0;
`
