import styled, { css } from 'styled-components'
import { Button, Text } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../common/Wrappers/Containers'

export const Wrapper = styled(Button)<{ $isActive: boolean }>`
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  flex: 1;
`

export const IconWrapper = styled(FlexContainer)<{ $isActive: boolean }>`
  justify-content: center;

  .mobile-navigation-link-icon {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.secondaryDark};
      fill-opacity: 1;
      transition: all 0.15s ease-in-out;
    }
  }

  ${({ $isActive }) =>
    $isActive
      ? css`
          .mobile-navigation-link-icon path {
            fill: ${({ theme }) => theme.primaryDark};
          }
        `
      : ''}
`

export const CustomText = styled(Text)<{ $isActive: boolean }>`
  transition: all 0.15s ease-in-out;
  color: ${({ $isActive, theme }) => ($isActive ? theme.primaryDark : theme.textPrimary)};
`
