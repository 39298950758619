import styled, { css } from 'styled-components'
import { ButtonLink } from '../../../../common/Helpers/LinkHelpers'
import { Button } from '@rhinofi/dvf-shared-ui'

const creteButtonStyles = (disable?: boolean) => css`
  transition: all 150ms ease-in;
  min-height: 54px;
  margin: 0;
  border: none;
  border-radius: 8px;
  padding: 8px 16px 8px 8px;

  .button-content {
    width: 100%;
  }

  &:active,
  &:focus,
  &:hover,
  &:enabled:hover {
    background: ${({ theme }) => (disable ? 'transparent' : theme.bridgePicker)};
  }
`

export const MobileNavigationMenuButtonLink = styled(ButtonLink)<{ $disable?: boolean }>`
  ${({ $disable }) => creteButtonStyles($disable)}
`

export const MobileNavigationMenuButton = styled(Button)<{ $disable?: boolean }>`
  ${({ $disable }) => creteButtonStyles($disable)}
`

export const SubLinksWrapper = styled.div`
  height: 0;
  opacity: 0;
  transition: all 150ms ease-in;

  &.sublinks-exit,
  &.sublinks-exit-active,
  &.sublinks-exit-done,
  &.sublinks-appear,
  &.sublinks-appear-active {
    height: 0;
    opacity: 0;
  }

  &.sublinks-enter,
  &.sublinks-enter-done,
  &.sublinks-enter-active {
    height: 100%;
    opacity: 1;
  }
`
