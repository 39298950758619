export const zIndices = {
  mobileMenuOpen: 10007,
  mobilePopupOpen: 10007,
  cookies: 10006,
  devTools: 10004,
  social: 10003,
  airdropClaimModal: 9999,
  globalSearch: 9999,
  mobileSupportChat: 9999,
  mobileHeader: 9998,
  modalMobileFullscreen: 9997,
  modalDesktop: 9997,
  threeDotsMenu: 9996,
  rhinoNationNotifications: 9995,
  topHeader: 1000,
  nationWidget: 1000,
  leftMenu: 999,
}
