import { type FC, type PropsWithChildren, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Container, Wrapper } from './OpacitySlideTransition.styled'
import { SlideTransitionType } from './types/OpacitySlideTransition.types'

type Props = {
  show: boolean
  zIndex: number
  timeOutOpacity: number
  timeOutSlideEnter: number
  timeOutSlideExit: number
  showBackground?: boolean
  slideType?: SlideTransitionType
}

export const OpacitySlideTransition: FC<PropsWithChildren<Props>> = ({
  show,
  timeOutOpacity,
  timeOutSlideEnter,
  timeOutSlideExit,
  zIndex,
  children,
  showBackground = true,
  slideType = SlideTransitionType.FROM_RIGHT,
}) => {
  const animationRefContainer = useRef<HTMLDivElement>(null)
  const animationRef = useRef<HTMLDivElement>(null)

  return (
    <CSSTransition
      in={show}
      timeout={timeOutOpacity}
      appear={show}
      classNames="fade"
      unmountOnExit
      nodeRef={animationRefContainer}
    >
      <Container $isVisible={show} ref={animationRefContainer} $zIndex={zIndex} $duration={timeOutOpacity}>
        <CSSTransition
          in={show}
          timeout={{
            enter: timeOutSlideEnter,
            exit: timeOutSlideExit,
          }}
          appear={show}
          classNames="slide-in"
          nodeRef={animationRef}
        >
          <Wrapper
            ref={animationRef}
            $enterDuration={timeOutSlideEnter}
            $exitDuration={timeOutSlideEnter}
            $alignItems="stretch"
            $direction="column"
            $showBackground={showBackground}
            $slideType={slideType}
          >
            {children}
          </Wrapper>
        </CSSTransition>
      </Container>
    </CSSTransition>
  )
}
