import { useMemo } from 'react'
import type { TokenInputProps } from '@rhinofi/dvf-shared-ui/lib/components/token-input/TokenInput'
import { createTokenInputDictionary } from '../../../utils/dictionaries/tokenInputDictionary'
import { translate } from '../../../intl/i18n'
import { useIsSwapUiAbTestEnabled } from '../../../hooks/useIsSwapUiAbTestEnabled'
import { useAppSelector } from '../../../hooks'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { selectBridge } from '../slices/bridge.slice'

export const useBridgeFormDictionaries = () => {
  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)
  const isSwapUiAbTestEnabled = useIsSwapUiAbTestEnabled()
  const defaultTokenInputDictionary = createTokenInputDictionary('')
  const bridgeState = useAppSelector(selectBridge)

  const tokenInputInDictionary: TokenInputProps['tokenSelectProps']['dictionary'] = useMemo(
    () => ({
      ...createTokenInputDictionary(chainInConfig?.name ?? ''),
      listLabel: isSwapUiAbTestEnabled ? translate('global.select_token_from') : defaultTokenInputDictionary.listLabel,
    }),
    [chainInConfig?.name, defaultTokenInputDictionary, isSwapUiAbTestEnabled],
  )
  const tokenInputOutDictionary: TokenInputProps['tokenSelectProps']['dictionary'] = useMemo(
    () => ({
      ...createTokenInputDictionary(chainOutConfig?.name ?? ''),
      listLabel: isSwapUiAbTestEnabled ? translate('global.select_token_to') : defaultTokenInputDictionary.listLabel,
      emptyListRoute: isSwapUiAbTestEnabled
        ? (filteredChain: string) => [
            translate('bridge.token_not_supported_for_route', { '%token': bridgeState.token }),
            `${chainInConfig?.name} - ${filteredChain}`,
          ]
        : undefined,
    }),
    [chainOutConfig?.name, chainInConfig?.name, defaultTokenInputDictionary, isSwapUiAbTestEnabled, bridgeState],
  )

  return {
    tokenInputInDictionary,
    tokenInputOutDictionary,
  }
}
