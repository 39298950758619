import type { MobileNavigationMenuSection, MobileSocial } from '../types/MobileNavigation.types'
import { translate } from '../../../intl/i18n'
import { NAVIGATION_LINKS } from '../../NavigationDesktop/NavigationDesktop.constants'

export const MOBILE_SOCIALS: MobileSocial[] = [
  {
    id: 'twitter',
    icon: 'twitter-x',
    href: 'https://twitter.com/rhinofi',
  },
  {
    id: 'telegram',
    icon: 'telegram',
    href: 'https://t.me/rhinofiannouncements',
  },
  {
    id: 'discord',
    icon: 'discord',
    href: 'https://discord.gg/26sXx2KAhy',
  },
]

export const MOBILE_MENU_SECTION_DEFAULT: MobileNavigationMenuSection = {
  id: 'mobile-nav-section-default',
  title: translate('helpers.explore_rhino'),
  links: NAVIGATION_LINKS.filter(({ showOnMobile }) => showOnMobile),
}
