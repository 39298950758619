import { Loader } from '@rhinofi/dvf-shared-ui'
import { MobilePopup } from '../../../../components/MobilePopup/MobilePopup'
import type { UserHistoryItemSchema } from '@rhinofi/bridge-api-spec'
import { useState } from 'react'
import { useMobileMenuCloseOnExternalAction } from '../../../../components/MobileMenu/hooks/useMobileMenuCloseOnExternalAction'
import { clearModal } from '../../../../store/actions/modalActions/clearModal'
import { useAppDispatch } from '../../../../hooks'
import { translate } from '../../../../intl/i18n'
import { capitalize } from '../../../../services/helperService'
import { LoaderWrapper } from './RecentBridgeTx.styled'
import { RecentBridgeTx } from './RecentBridgeTx'

type Props = {
  bridgeData: UserHistoryItemSchema | undefined
}

export const RecentBridgeTxMobilePopup = ({ bridgeData }: Props) => {
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(true)

  const close = useMobileMenuCloseOnExternalAction({
    close: () => {
      setShow(false)
    },
    externalAction: () => {
      clearModal(dispatch)()
    },
  })

  return (
    <MobilePopup show={show} handleClose={close} headerText={capitalize(translate('global.details'))}>
      {bridgeData ? (
        <RecentBridgeTx bridgeData={bridgeData} handleAfterCtaClick={close} />
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </MobilePopup>
  )
}
