import Aigle from '@rhino.fi/aigle'
import type { UserHistoryItemSchema } from '@rhinofi/bridge-api-spec'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { POLLING_INTERVALS } from '../../../constants/portalConfig'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { publicRoutes } from '../../../router'
import {
  useGetUserHistoryQuery,
  useLazyGetBridgeHistoryQuery,
  useLazyGetUserHistoryQuery,
} from '../../../store/apis/bridge.api'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import { setHistory } from '../slices/bridgeHistory.slice'
import { selectBridgeProgress } from '../slices/bridgeProgress.slice'
import { selectIsWidget } from '../../../store/selectors/portal.selectors'

const log = makeLog(LogFeature.BRIDGE_HISTORY)

const userHistoryPaginationParams = {
  limit: 5,
  page: 1,
  sortBy: 'createdAt',
  sortDirection: 'desc' as const,
}

export const useUserBridgeHistory = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const isWidget = useAppSelector(selectIsWidget)
  const userAddress = useAppSelector(selectAddress) ?? ''
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const [fetchBridgeById] = useLazyGetBridgeHistoryQuery()
  const [fetchUserHistory] = useLazyGetUserHistoryQuery()
  const [pendingBridgesUpdate, setPendingBridgesUpdate] = useState<UserHistoryItemSchema[]>()

  const bridgeProgress = useAppSelector(selectBridgeProgress)

  // we need to fetch the user history when a deposit is detected
  // else we would need to wait for the polling interval
  useEffect(() => {
    if (userAddress && bridgeProgress.bridgeId && !isWidget) {
      log('Deposit detected, updating history')
      fetchUserHistory({ urlParams: userHistoryPaginationParams }).catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding fetchUserHistory creates an infinite loop
  }, [bridgeProgress.bridgeId, userAddress])

  const { data: userHistory } = useGetUserHistoryQuery(
    {
      urlParams: userHistoryPaginationParams,
    },
    {
      skip: !isAuthenticated || !pathname.includes(publicRoutes.bridge) || isWidget,
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVALS.USER_BRIDGE_HISTORY,
    },
  )

  const pendingBridges = useMemo(() => {
    if (!userHistory?.items) {
      return []
    }
    return userHistory.items.filter(({ bridge }) => bridge.state === 'PENDING' || bridge.state === 'ACCEPTED')
  }, [userHistory])

  const fetchPendingBridgesUntilComplete = async () => {
    await Aigle.doUntil(
      async () => {
        log('Fetching pending bridges')
        const results = await Promise.all(
          pendingBridges.map(({ bridge }) => fetchBridgeById({ path: { bridgeId: bridge._id } })),
        )
        const updatedBridges = results.map(({ data }) => data).filter((item) => item !== undefined)
        setPendingBridgesUpdate(updatedBridges)

        const stillPending = updatedBridges.some(({ state }) => state === 'PENDING' || state === 'ACCEPTED')
        await Aigle.delay(POLLING_INTERVALS.PENDING_BRIDGE)
        return stillPending
      },
      (stillPending) => !stillPending,
    )
    log('All pending bridges are complete, refetching user history')
    await fetchUserHistory({ urlParams: userHistoryPaginationParams })
  }

  useEffect(() => {
    if (pendingBridges.length) {
      log('Start polling for pending bridges frequently until all are complete')
      fetchPendingBridgesUntilComplete().catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding fetchPendingBridgesUntilComplete creates an infinite loop
  }, [pendingBridges.length])

  useEffect(() => {
    if (userHistory) {
      const updatedHistory = {
        totalItems: userHistory.totalItems,
        items: userHistory.items.map((item) => {
          if (item.bridge.state === 'PENDING') {
            const updatedBridge = pendingBridgesUpdate?.find(({ _id }) => _id === item.bridge._id)
            return {
              bridge: updatedBridge
                ? {
                    // use transactionHash from bridgeProgress until we get it from the backend
                    depositTxHash: bridgeProgress.bridgeId === item.bridge._id ? bridgeProgress.transactionHash : '',
                    ...updatedBridge,
                  }
                : item.bridge,
              rejections: item.rejections,
            }
          }
          return item
        }),
      }

      dispatch(setHistory(updatedHistory))
    }
  }, [
    dispatch,
    userHistory,
    pendingBridgesUpdate,
    bridgeProgress.transactionHash,
    bridgeProgress.isTxConfirmed,
    bridgeProgress.bridgeId,
  ])
}
