import { ResponsivePage } from '../../components/common/Wrappers/Page'
import { FlexContainer } from '../../components/common/Wrappers/Containers'
import { envConfig } from '../../env/envConfig'

const { appURL } = envConfig

export const IframePlayground = () => (
  <ResponsivePage>
    <FlexContainer $direction="column">
      <iframe
        src={`https://${appURL}/bridge?widget=true&theme=equalizer&api_key=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfdGFnIjoiYXBwIiwidXNlcklkIjoiNjdiNWNmNDM2YWI2ZmMxMjUzMDEzZDU2IiwiYXBwTmFtZSI6Imphbm5pcy10ZXN0IiwiYmxvY2tlZEZlYXR1cmVzIjpbXSwiaWF0IjoxNzQwMDY4MjI5LCJleHAiOjQ4OTM2NjgyMjl9.g_ZZJqo8vfpT3Hakm07R0PSIvMQGA46gFe8zUzV6lK6Ium_TCTzmcfQogafbAeQsVFFkglZuaKZI7IBnQ4hkg6nFCqdo76ACJKPtCzmHBil8ulv34R5FAxRjav8t47XLjSB4eUlJlMNBRNcXggj1Xj-FCgcM-eCG6HYoKA0xNHtmDqd03fniz-fiDFS28ln4IGTm_SIfcbfIU30SdCRcX7fm7vXo-6CYqlAc6ypTji5km9vtHh7fsXxZEG8PgAjZsSpgHLtE2Bv5SrCuoh1LgOF06moFgKpJmjqcI_Bx3IHU6tfftI7UORlB1uytmeFrdO85p1nmG8-IXyqQerzU_g`}
        style={{ width: '400px', height: '1000px', maxWidth: '100%', border: 'none' }}
        scrolling="no"
      />
    </FlexContainer>
  </ResponsivePage>
)
