import { useTokenDistributionUserEligibilityManyQuery } from '../../TokenDistribution/services/tokenDistribution.api'
import { useAppSelector } from '../../../hooks'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { DISTRIBUTION_QUERY } from '../constants/legacyRhinoClaims.constants'

type QueryElement = {
  chain: string
  distributionId: number
}

type Props =
  | {
      skip: boolean
    }
  | undefined

export const useTokenDistributionUserEligibilityManyQueryWrapper = (props: Props = { skip: false }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const address = useAppSelector(selectAddress)

  return useTokenDistributionUserEligibilityManyQuery(
    {
      urlParams: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- skip makes sure undefined is eliminated
        user: address!,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- skip checks length of distributionQuery
        distribution: DISTRIBUTION_QUERY as [QueryElement, ...QueryElement[]],
      },
    },
    {
      skip: !isAuthenticated || !address || !DISTRIBUTION_QUERY.length || props.skip,
    },
  )
}
