import type { HighlightItem } from '@rhinofi/campaigns-api-spec'
import {
  NoUnderlineAnchor,
  NoUnderlineBridgeLink,
  NoUnderlineLink,
} from '../../../../components/common/Helpers/LinkHelpers'
import { publicRoutes } from '../../../../router'
import highlighPlaceholder from '../../assets/highlights/highlight-fallback.png'
import { BridgeHighlightsImage } from './BridgeHighlights.styled'

type Props = {
  highlight: HighlightItem
  width?: number
  height?: number
}

export const BridgeHighlightItem = ({ highlight, width = 352, height = 106 }: Props) => {
  const { id, alt, href, imageUrl, isInternalLink, isActive } = highlight
  const content = (
    <BridgeHighlightsImage
      id={id}
      $isActive={isActive}
      src={imageUrl}
      alt={alt}
      width={width}
      height={height}
      onError={(err) => {
        err.currentTarget.src = highlighPlaceholder
      }}
    />
  )

  if (isInternalLink) {
    const isBridgeLink = href.startsWith(publicRoutes.bridge)
    const LinkElement = isBridgeLink ? NoUnderlineBridgeLink : NoUnderlineLink
    return (
      <LinkElement to={href} className="highlight-link">
        {content}
      </LinkElement>
    )
  }

  if (href && isActive) {
    return (
      <NoUnderlineAnchor href={href} className="highlight-link" target="_blank" rel="noopener noreferrer">
        {content}
      </NoUnderlineAnchor>
    )
  }

  return content
}
