import moment from 'moment'
import { Icon, type TableProps, Text, TextSize, TokenIcon, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../common/Wrappers/Containers'
import { SmallSpinner } from '../common/Helpers/SmallSpinner'
import type { UserHistoryItemSchema } from '@rhinofi/bridge-api-spec'
import { translate } from '../../intl/i18n'
import { shortenAddress } from '../../services/helperService/shortenAddress'
import { formatFloat } from '../../services/helperService/formatFloat'
import { BigDecimal } from 'effect'
import { AnchorContainer } from './BridgeHistory.columns.styled'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { SkeletonCard } from '../common/Skeletons/Skeleton.styled'
import { NoUnderlineAnchor } from '../common/Helpers/LinkHelpers'
import { FromCell, ToCell } from './BridgeHistory.cells'

export const bridgeHistoryColumns = ({
  etherscanLink,
  isWidget,
}: {
  etherscanLink: (target: string, chain?: string, isTransaction?: boolean, forceIsTransaction?: boolean) => string
  isWidget: boolean
}): TableProps<UserHistoryItemSchema>['columns'] => [
  {
    id: 'state',
    title: isWidget ? '' : translate('helpers.status'),
    fitContent: true,
    loadingSkeleton: <SkeletonCard $height="16px" $width="16px" $borderRadius="100%" />,
    renderCell: ({ state }: UserHistoryItemSchema) => {
      return (
        <FlexContainer $alignItems="center">
          {state === 'EXECUTED' ? (
            <Icon id="check-circle-fill" className="success-bridge" color="primaryMain" />
          ) : state === 'CANCELLED' ? (
            <Icon id="cross-circle-fill" className="success-bridge" color="accentRed" />
          ) : (
            <SmallSpinner />
          )}
        </FlexContainer>
      )
    },
  },
  {
    id: 'createdAt',
    title: translate('helpers.date'),
    sortable: !isWidget,
    fitContent: isWidget,
    loadingSkeleton: <SkeletonCard $height="16px" $width="128px" />,
    renderCell: ({ createdAt }: UserHistoryItemSchema) => {
      return (
        <Text size={TextSize.S} noWrap>
          {moment(createdAt).format(isWidget ? 'DD MMM' : 'MMM DD, YYYY')}
        </Text>
      )
    },
  },
  {
    id: 'amountOut',
    title: translate('helpers.amount'),
    sortable: !isWidget,
    titleAlign: isWidget ? 'start' : 'end',
    loadingSkeleton: (
      <FlexContainer $justifyContent={isWidget ? 'start' : 'flex-end'}>
        <SkeletonCard $height="16px" $width="96px" />
      </FlexContainer>
    ),
    renderCell: ({ amountOut, ...element }: UserHistoryItemSchema) => {
      if (element._tag === 'bridgeSwap') {
        console.error('bridge swap not implemented in history columns')
        return
      }
      return (
        <FlexContainer $justifyContent={isWidget ? 'start' : 'flex-end'} $alignItems="center" $gap="4px">
          <Text size={TextSize.S}>{formatFloat(BigDecimal.format(amountOut))}</Text>
          {!isWidget && <TokenIcon token={element.token} size="small" />}
          <Text size={TextSize.S}>{element.token}</Text>
        </FlexContainer>
      )
    },
  },
  {
    id: 'recipient',
    title: translate('helpers.recipient'),
    sortable: !isWidget,
    loadingSkeleton: (
      <FlexContainer $justifyContent="center">
        <SkeletonCard $height="16px" $width="128px" />
      </FlexContainer>
    ),
    titleAlign: 'center',
    hidden: isWidget,
    renderCell: ({ recipient, chainOut }: UserHistoryItemSchema) => (
      <AnchorContainer>
        <NoUnderlineAnchor
          href={etherscanLink(recipient, chainOut, false, false)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => event.stopPropagation()}
        >
          <Text size={TextSize.S} align={TypographyAlign.Center}>
            {shortenAddress(recipient)}
          </Text>
        </NoUnderlineAnchor>
      </AnchorContainer>
    ),
  },
  {
    id: 'chainIn',
    title: `${translate('helpers.from')}/${translate('helpers.to')}`,
    hidden: !isWidget,
    loadingSkeleton: (
      <FlexContainer $alignItems="center" $gap="8px">
        <SkeletonCard $height="16px" $width="16px" $borderRadius="100%" />
        <Icon id="arrow-right" color="secondaryDark" />
        <SkeletonCard $height="16px" $width="16px" $borderRadius="100%" />
      </FlexContainer>
    ),
    renderCell: ({ chainIn, chainOut }: UserHistoryItemSchema) => (
      <FlexContainer $alignItems="center" $gap="8px">
        <TokenIcon token={chainIn} size="small" />
        <Icon id="arrow-right" color="secondaryDark" />
        <TokenIcon token={chainOut} size="small" />
      </FlexContainer>
    ),
  },
  {
    id: 'chainIn',
    title: translate('helpers.from'),
    hidden: isWidget,
    fitContent: true,
    loadingSkeleton: (
      <FlexContainer $alignItems="center" $gap="8px">
        <SkeletonCard $height="16px" $width="16px" $borderRadius="100%" />
        <SkeletonCard $height="16px" $width="64px" />
        <Icon id="arrow-right" color="secondaryDark" />
      </FlexContainer>
    ),
    renderCell: ({ chainIn }: UserHistoryItemSchema) => <FromCell chainIn={chainIn} showChainNames={!isWidget} />,
  },
  {
    id: 'chainOut',
    title: translate('helpers.to'),
    hidden: isWidget,
    loadingSkeleton: (
      <FlexContainer $alignItems="center" $gap="8px">
        <SkeletonCard $height="16px" $width="16px" $borderRadius="100%" />
        <SkeletonCard $height="16px" $width="64px" />
      </FlexContainer>
    ),
    renderCell: ({ chainOut }: UserHistoryItemSchema) => <ToCell chainOut={chainOut} showChainNames={!isWidget} />,
  },
  {
    id: 'fee',
    title: translate('helpers.fee'),
    titleAlign: 'end',
    fitContent: true,
    hidden: isWidget,
    loadingSkeleton: <SkeletonCard $height="16px" $width="64px" />,
    sortable: !isWidget,
    renderCell: ({ fee, ...element }: UserHistoryItemSchema) => {
      if (element._tag === 'bridgeSwap') {
        console.error('bridge swap not implemented in history columns')
        return
      }
      return (
        <Text size={TextSize.S} noWrap align={TypographyAlign.Right}>
          {bigDecimalToNumericString(fee)} {element.token}
        </Text>
      )
    },
  },
]
