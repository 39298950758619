import { Dropdown, TextSize, Text, Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { NAVIGATION_LINKS } from './NavigationDesktop.constants'
import {
  NavigationLink,
  NavigationLinksContent,
  NavigationSubLink,
  NavigationSubLinksContent,
} from './NavigationLinks.styled'
import type { NavigationLinkRoot, NavigationLinkSubLink } from './NavigationDesktop.types'
import { NoUnderlineLink } from '../common/Helpers/LinkHelpers'
import { FlexContainer } from '../common/Wrappers/Containers'

export const NavigationLinks = () => {
  const { pathname } = useLocation()

  const isRootNavItemActive = useCallback(
    (navigationLink: NavigationLinkRoot) => {
      if (navigationLink.type === 'to') {
        return pathname.includes(navigationLink.to)
      }

      if (navigationLink.type === 'sub-links') {
        return navigationLink.subLinks.some((subLink) => subLink.type === 'to' && pathname.includes(subLink.to))
      }

      return false
    },
    [pathname],
  )

  const isSubNavItemActive = useCallback(
    (navigationLink: NavigationLinkSubLink) => {
      if (navigationLink.type === 'to') {
        return navigationLink.to.split('/').pop() === pathname.split('/').pop()
      }

      return false
    },
    [pathname],
  )

  return (
    <NavigationLinksContent>
      {NAVIGATION_LINKS.map((link) => {
        if (link.type === 'to') {
          return (
            <NoUnderlineLink to={link.to} id={link.id} key={link.title}>
              <NavigationLink id={`navigation-link-${link.id}`} $isActive={isRootNavItemActive(link)}>
                <Text className="navigation-link-text" size={TextSize.S} bold>
                  {link.title}
                </Text>
              </NavigationLink>
            </NoUnderlineLink>
          )
        }

        if (link.type === 'sub-links') {
          return (
            <Dropdown
              key={link.title}
              triggerType="hover"
              isSecondaryCard
              element={
                <NavigationLink id={`navigation-link-${link.title}`} $isActive={isRootNavItemActive(link)} $noHover>
                  <Text className="navigation-link-text" size={TextSize.S} bold>
                    {link.title}
                  </Text>
                  <Icon id="caret-down-fill" size={IconSizes.XSmall} />
                </NavigationLink>
              }
            >
              <NavigationSubLinksContent>
                <FlexContainer $direction="column" $gap="4px" $alignItems="flex-start">
                  {link.subLinks.map((subLink) => {
                    if (subLink.type === 'to') {
                      return (
                        <NavigationSubLink key={subLink.id} to={subLink.to} $isActive={isSubNavItemActive(subLink)}>
                          {subLink.title}
                        </NavigationSubLink>
                      )
                    }

                    return null
                  })}
                </FlexContainer>
              </NavigationSubLinksContent>
            </Dropdown>
          )
        }

        return null
      })}
    </NavigationLinksContent>
  )
}
