import { useABTest } from './useABTest'
import { ABTestExperimentId } from '../services/ABTestService'
import { useAppSelector } from './useAppSelector'
import { selectIsWidget } from '../store/selectors/portal.selectors'

export const useIsSwapUiAbTestEnabled = () => {
  const variant = useABTest(ABTestExperimentId.SWAP_UI)
  const isWidget = useAppSelector(selectIsWidget)

  return !isWidget && variant === '1'
}
