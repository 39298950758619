import {
  Button,
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  Text,
  TextSize,
  TokenIcon,
  Tooltip,
} from '@rhinofi/dvf-shared-ui'
import { modalKeys } from '../../constants/modalKeys'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useWallet } from '../../hooks/useWallet'
import { translate } from '../../intl/i18n'
import { findBridgeKeyForNetworkId } from '../../services/helperService/findBridgeKeyForNetworkId'
import { shortenAddress } from '../../services/helperService/shortenAddress'
import { showModal } from '../../store/actions/modalActions/showModal'
import { selectBridgeConfig } from '../../store/apis/config.api'
import {
  selectSecondaryWalletAddress,
  selectSecondaryWalletChain,
} from '../../store/selectors/secondaryWallet.selectors'
import { selectAddress, selectConnectedNetwork, selectHasLegacyClaims } from '../../store/selectors/user.selectors'
import { disconnectSecondaryWallet } from '../../store/slices/secondaryWallet.slice'
import { useCopyText } from '../common/Helpers/Copy'
import { FlexContainer } from '../common/Wrappers/Containers'
import { ButtonLink } from '../common/Helpers/LinkHelpers'
import { publicRoutes } from '../../router'

export const MenuConnectedWallets = ({ close = () => {} }: { close?: () => void }) => {
  const dispatch = useAppDispatch()

  const address = useAppSelector(selectAddress)
  const network = useAppSelector(selectConnectedNetwork)
  const wallet = useWallet()
  const hasLegacyClaims = useAppSelector(selectHasLegacyClaims)

  const bridgeConfig = useAppSelector(selectBridgeConfig)
  const primaryWalletChain = findBridgeKeyForNetworkId(network?.toString() || '', bridgeConfig) || ''
  const { copyToClipboard: copyPrimary } = useCopyText({ disabled: false, text: address || '' })

  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const secondaryWalletChain = useAppSelector(selectSecondaryWalletChain)
  const { copyToClipboard: copySecondary } = useCopyText({ disabled: false, text: secondaryWalletAddress })

  const disconnectPrimary = async () => {
    await wallet.disconnect()
    close()
  }

  const disconnectSecondary = () => {
    dispatch(disconnectSecondaryWallet())
    close()
  }

  if (!address) {
    return (
      <Button
        size={ButtonSizes.Large}
        onClick={() => showModal(dispatch)(modalKeys.connectWallet)}
        id="heap-connect-wallet-menu"
        fullWidth
      >
        {translate('onboarding.connect_wallet')}
      </Button>
    )
  }

  return (
    <>
      {hasLegacyClaims && (
        <Text size={TextSize.XS}>
          {translate('claim.you_have_unclaimed_balances')}{' '}
          <ButtonLink
            id="dropdown-claim-link"
            to={publicRoutes.legacyRhinoClaim}
            variant={ButtonVariant.text}
            onClick={close}
          >
            {translate('claim.claim_now')}
          </ButtonLink>
        </Text>
      )}
      <Text size={TextSize.S} color="textSecondary" bold>
        {translate('onboarding.connected_evm_wallet')}
      </Text>
      <FlexContainer $justifyContent="space-between" $fullWidth>
        <FlexContainer $gap="8px">
          <TokenIcon token={primaryWalletChain} />
          <Text size={TextSize.S}>{shortenAddress(address, false, 8)}</Text>
        </FlexContainer>
        <FlexContainer $gap="4px">
          <Tooltip tooltipContent={translate('onboarding.copy_wallet_address')}>
            <Icon id="files" size={IconSizes.Medium} active handleClick={copyPrimary} />
          </Tooltip>
          <Tooltip tooltipContent={translate('onboarding.disconnect_wallet')}>
            <Icon id="x-lg" size={IconSizes.Medium} active handleClick={disconnectPrimary} />
          </Tooltip>
        </FlexContainer>
      </FlexContainer>
      {secondaryWalletAddress && (
        <>
          <Text size={TextSize.S} color="textSecondary">
            {translate('onboarding.connected_non_evm_wallet')}
          </Text>
          <FlexContainer $justifyContent="space-between" $fullWidth>
            <FlexContainer $gap="8px">
              <TokenIcon token={secondaryWalletChain} />
              <Text size={TextSize.S}>{shortenAddress(secondaryWalletAddress, false, 8)}</Text>
            </FlexContainer>
            <FlexContainer $gap="4px">
              <Tooltip tooltipContent={translate('onboarding.copy_wallet_address')}>
                <Icon id="files" size={IconSizes.Medium} active handleClick={copySecondary} />
              </Tooltip>
              <Tooltip tooltipContent={translate('onboarding.disconnect_wallet')}>
                <Icon id="x-lg" size={IconSizes.Medium} active handleClick={disconnectSecondary} />
              </Tooltip>
            </FlexContainer>
          </FlexContainer>
        </>
      )}
    </>
  )
}
