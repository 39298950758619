import { MobileMenu } from '../MobileMenu/MobileMenu'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useEffect, useState } from 'react'
import { useMobileMenuCloseOnExternalAction } from '../MobileMenu/hooks/useMobileMenuCloseOnExternalAction'
import { translate } from '../../intl/i18n'
import { MobileMenuContent } from '../MobileMenu/components/MobileMenuContent.styled'
import { MobileMenuSection } from '../MobileMenu/components/MobileMenuSection'
import { useWallet } from '../../hooks/useWallet'
import { ConnectEip963WalletOptions } from '../ConnectWallet/ConnectEip963WalletOptions'
import { ConnectOthersWalletOptions } from '../ConnectWallet/ConnectOthersWalletOptions'
import { selectAddress } from '../../store/selectors/user.selectors'

export const MobileMenuWalletConnect = () => {
  const userAddress = useAppSelector(selectAddress)
  const wallet = useWallet()

  const [menuOpen, setMenuOpen] = useState(true)
  const dispatch = useAppDispatch()

  const closeMenu = useMobileMenuCloseOnExternalAction({
    close: () => {
      setMenuOpen(false)
    },
    externalAction: () => {
      clearModal(dispatch)()
    },
  })

  useEffect(() => {
    if (userAddress) {
      closeMenu()
    }
  }, [userAddress, dispatch, closeMenu])

  const controls = {
    closeMenu,
  }

  return (
    <MobileMenu show={menuOpen} headerText={translate('onboarding.connect_wallet')} handleClose={controls.closeMenu}>
      <MobileMenuContent>
        {wallet.eip6963Providers.length > 0 && (
          <MobileMenuSection title={translate('onboarding.detected')} showBorder={false}>
            <ConnectEip963WalletOptions />
          </MobileMenuSection>
        )}

        <MobileMenuSection title={translate('helpers.popular')}>
          <ConnectOthersWalletOptions />
        </MobileMenuSection>
      </MobileMenuContent>
    </MobileMenu>
  )
}
