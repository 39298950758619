import { BigDecimal } from 'effect'
import moment from 'moment'
import {
  Button,
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  Spacing,
  Text,
  TextSize,
  TypographyAlign,
} from '@rhinofi/dvf-shared-ui'
import type { UserHistoryItemSchema } from '@rhinofi/bridge-api-spec'
import { translate } from '../../../../intl/i18n'
import { ChainCard, SummaryCard } from './RecentBridgeTx.styled'
import { ChainContainer } from './ChainContainer'
import { formatFloat } from '../../../../services/helperService/formatFloat'
import { useAppSelector, useConfig } from '../../../../hooks'
import { selectBridgeProgress } from '../../slices/bridgeProgress.slice'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { useBridgeNavigate } from '../../../../hooks/bridge'
import { makeRouteTo } from '../../../../router'

type Props = {
  bridgeData: UserHistoryItemSchema
  handleAfterCtaClick?: () => void
}

export const RecentBridgeTx = ({ bridgeData, handleAfterCtaClick = () => {} }: Props) => {
  const { config } = useConfig()
  const { isMobile } = useIsMobile()
  const bridgeNavigate = useBridgeNavigate()
  const bridgeProgress = useAppSelector(selectBridgeProgress)

  if (!bridgeData || !config || bridgeData._tag === 'bridgeSwap') {
    return null
  }

  const { createdAt, amountOut, token, chainIn, chainOut, state } = bridgeData

  const depositState =
    state === 'ACCEPTED' || (bridgeProgress.bridgeId === bridgeData._id && bridgeProgress.isTxConfirmed)
      ? 'EXECUTED'
      : state
  const withdrawState = state

  const prettyChainInName = config[chainIn]?.name || chainIn
  const prettyChainOutName = config[chainOut]?.name || chainOut

  const depositTxHash = 'depositTxHash' in bridgeData ? bridgeData.depositTxHash : ''
  const withdrawTxHash = 'withdrawTxHash' in bridgeData ? bridgeData.withdrawTxHash : ''

  return (
    <div>
      <ChainCard>
        <ChainContainer chain={chainIn} name={prettyChainInName} txHash={depositTxHash} state={depositState} id="in" />
        <Icon id="arrow-right" color="secondaryDark" size={IconSizes.Big} />
        <ChainContainer
          chain={chainOut}
          name={prettyChainOutName}
          txHash={withdrawTxHash}
          state={withdrawState}
          id="out"
        />
      </ChainCard>
      <Spacing size="8" />
      <SummaryCard>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.value')}
        </Text>
        <Text size={TextSize.S} bold align={TypographyAlign.Right} id="amountOut">
          {formatFloat(BigDecimal.format(amountOut))} {token}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.timestamp')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Right} id="createdAt">
          {createdAt ? moment(createdAt).format('HH:mm A, DD MMM YYYY') : '...'}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.from')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Right} id="from">
          {prettyChainInName}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.to')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Right} id="to">
          {prettyChainOutName}
        </Text>
      </SummaryCard>

      {isMobile ? (
        <Button
          id="bridge-history-bridge-again"
          fullWidth
          size={ButtonSizes.Large}
          onClick={() => {
            bridgeNavigate(makeRouteTo.bridgeOptional({ from: chainIn, token, to: chainOut }))
            handleAfterCtaClick()
          }}
        >
          {translate('bridge.bridge_again')}
        </Button>
      ) : (
        <Button
          id="bridge-history-support"
          variant={ButtonVariant.secondary}
          fullWidth
          size={ButtonSizes.Large}
          onClick={() => {
            window.open('https://support.rhino.fi/en', '_blank', 'noopener noreferrer')
          }}
          iconLeft="chat-left-text"
        >
          {translate('bridge.contact_support')}
        </Button>
      )}
    </div>
  )
}
