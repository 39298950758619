import type { BridgeConfigEntrySchema, PublicQuoteResponseSchema } from '@rhinofi/bridge-api-spec'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import type { FormikProps } from 'formik'
import { isEmpty } from 'lodash-es'
import type { RefObject } from 'react'
import { useCallback, useEffect } from 'react'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../services/usdPricesApi'
import { dynamicAsyncSchemaCreator } from '../../../services/validation/dynamicAsyncSchemaCreator'
import {
  selectBridgeChainsConfig,
  selectBridgeChainsTokenConfig,
} from '../../../store/selectors/bridgeConfig.selectors'
import { selectBlockchainBalances } from '../../../store/selectors/portal.selectors'
import { selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import { BridgeValidationSchema } from '../schemas/bridge-widget.schemas'
import { selectBridge } from '../slices/bridge.slice'
import type { BridgeFormValues } from '../types/bridge-widget.types'
import { useBridgeAmount } from './useBridgeAmount'

const log = makeLog(LogFeature.BRIDGE_VALIDATION)

type UseBridgeValidationInput = {
  // Safeguard
  gasSafeGuard: BridgeConfigEntrySchema['nativeTokenSafeguard'] | undefined
  // General
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
  bridgeFormRef: RefObject<FormikProps<BridgeFormValues>>
}

export const useBridgeValidation = ({ gasSafeGuard, payAmount, bridgeFormRef }: UseBridgeValidationInput) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const blockchainBalance = useAppSelector(selectBlockchainBalances)
  const { chainIn, chainOut, token } = useAppSelector(selectBridge)
  const amount = useBridgeAmount()
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const { chainInTokenConfig } = useAppSelector(selectBridgeChainsTokenConfig)

  const bridgeValidation = useCallback(
    async (values: BridgeFormValues) => {
      if (amount === '0' || amount === '') {
        log('early validation exit')
        return {}
      }

      const bridgeValidationResult = await dynamicAsyncSchemaCreator(values, BridgeValidationSchema, {
        // General
        decimals: chainInTokenConfig?.decimals,
        token,
        nativeToken: chainInConfig?.nativeTokenName,
        // Base amount validator args
        usdPrice: getTokenPrice(tokenPrices, token),
        balance: isAuthenticated ? parseFloat(blockchainBalance?.[chainIn]?.[token]?.balance || '0') : Number.MAX_VALUE,
        payAmount: payAmount ? bigDecimalToNumericString(payAmount) : `0`,
        // Bridge amount validator args
        safeguardAmount: gasSafeGuard ? +gasSafeGuard : 0,
        // recipientAddressValidator
        chainOut,
        isOtherAddress: values.isOtherAddress,
      })

      log('bridgeValidationResult', bridgeValidationResult)
      return bridgeValidationResult
    },
    [
      amount,
      tokenPrices,
      token,
      isAuthenticated,
      blockchainBalance,
      chainIn,
      chainOut,
      chainInConfig?.nativeTokenName,
      chainInTokenConfig?.decimals,
      gasSafeGuard,
      payAmount,
    ],
  )

  // validate on non-form validation parameters change
  useEffect(() => {
    const validate = async () => {
      if (bridgeFormRef.current) {
        const validationResult = await bridgeValidation(bridgeFormRef.current.values)
        if (!isEmpty(validationResult) || !isEmpty(bridgeFormRef.current.errors)) {
          bridgeFormRef.current.setErrors(validationResult)
          void bridgeFormRef.current.validateForm()
        }
      }
    }
    void validate()
  }, [bridgeValidation, bridgeFormRef])

  return {
    bridgeValidation,
  }
}
