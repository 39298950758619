import type { PublicQuoteResponseSchema, QuoteFeeSchema, QuotePromotionSchema } from '@rhinofi/bridge-api-spec'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import {
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  NetworkSelect,
  type NetworkSelectProps,
  Text,
  TokenInput,
} from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useEffect, useMemo, useState } from 'react'
import SwitchIcon from '../../../../assets/icons/switch.svg?react'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { useConnectWallet } from '../../../../hooks/useConnectWallet'
import { translate } from '../../../../intl/i18n'
import { formatFloat } from '../../../../services/helperService/formatFloat'
import { selectTokenPrices } from '../../../../services/usdPricesApi'
import { selectBridgeChainsConfig } from '../../../../store/selectors/bridgeConfig.selectors'
import {
  networkSelectorDictionaryChain,
  networkSelectorDictionaryChainFrom,
  networkSelectorDictionaryChainTo,
} from '../../../../utils/dictionaries/networkSelectorDictionary'
import { LogFeature, makeLog } from '../../../../utils/makeLog'
import { TOKEN_INPUT_CHAIN_CUSTOM_SIZE } from '../../constants/bridge.constants'
import { bridgeWidgetLabels } from '../../constants/bridgeWidgetLabels.constants'
import type { NormalizedError } from '../../helpers'
import { getBridgeReceiveToken } from '../../helpers'
import { selectAddress } from '../../../../store/selectors/user.selectors'
import type { GasBoostOptions } from '../../hooks'
import { InputActionKeys, SelectableActionKeys, useBridgeDataWithBalances, useBridgeState } from '../../hooks'
import { type BridgeFormValues } from '../../types/bridge-widget.types'
import { BridgeFeeBreakdown } from '../BridgeFeeBreakdown/BridgeFeeBreakdown'
import { BridgeToAny } from '../BridgeToAny/BridgeToAny'
import {
  BridgeLayout,
  ComponentPanelNew,
  ComponentPanelRowNew,
  ComponentPanelRowSmall,
  LabelGroup,
  MaxButton,
  SwitchNetworks,
  TextNetworkSelectWrapper,
  TokenAmounts,
  TokenLabel,
  LabelText,
} from '../BridgeWidget/BridgeWidget.styled'
import { GasBoost } from '../GasBoost/GasBoost'
import { ReviewButton } from '../ReviewButton'
import { setMode } from '../../slices/bridge.slice'
import { formatBridgeAmountUsd } from '../../helpers/formatBridgeAmountUsd'
import { BridgeConnectedWallet } from '../../../../components/Widget/BridgeConnectedWallet'
import { selectIsWidget } from '../../../../store/selectors/portal.selectors'
import { selectSecondaryWalletAddress } from '../../../../store/selectors/secondaryWallet.selectors'
import { PoweredByRhino } from '../../../../components/Widget/PoweredByRhino'
import { zIndices } from '../../../../constants/zIndex'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { BridgeControls } from '../BridgeControls/BridgeControls'
import { useIsSwapUiAbTestEnabled } from '../../../../hooks/useIsSwapUiAbTestEnabled'
import { useBridgeFormDictionaries } from '../../hooks/useBridgeFormDictionaries'
import type { TokenInputProps } from '@rhinofi/dvf-shared-ui/lib/components/token-input/TokenInput'

const log = makeLog(LogFeature.BRIDGE_FORM_STATE)

type BridgeFormStateProps = {
  values: BridgeFormValues
  normalizedError: NormalizedError
  setFieldValue: (field: string, value: string | boolean) => void
  withdrawalFeeConfig: QuoteFeeSchema | undefined
  withdrawalPromoConfig: QuotePromotionSchema | undefined
  tokenBalanceChainIn: string
  tokenBalanceChainOut: string
  maxBridgeableAmount: string
  gasBoostOptions: GasBoostOptions
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
  receiveAmount: PublicQuoteResponseSchema['receiveAmount'] | undefined
  isFetchingQuote: boolean
}

export const BridgeFormState = ({
  values,
  normalizedError,
  setFieldValue,
  withdrawalFeeConfig,
  withdrawalPromoConfig,
  tokenBalanceChainIn,
  tokenBalanceChainOut,
  maxBridgeableAmount,
  gasBoostOptions,
  payAmount,
  receiveAmount,
  isFetchingQuote,
}: BridgeFormStateProps) => {
  const dispatch = useAppDispatch()

  const address = useAppSelector(selectAddress)
  const isWidget = useAppSelector(selectIsWidget)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)
  const isEvmWalletConnected = !!useAppSelector(selectAddress)
  const isNonEvmWalletConnected = !!useAppSelector(selectSecondaryWalletAddress)
  const { isMobile } = useIsMobile()
  const isSwapUiAbTestEnabled = useIsSwapUiAbTestEnabled()
  const { tokenInputInDictionary, tokenInputOutDictionary } = useBridgeFormDictionaries()
  const showUserDataWhenWalletNotConnected = isSwapUiAbTestEnabled ? !!address : true

  const [otherFieldUpdated, setOtherFieldUpdated] = useState(false)

  const { handleSelectChange, handleSwitchChains, handleInputChange, bridgeState } = useBridgeState({
    setFieldValue,
  })
  const {
    availableChainsInWithBalances,
    availableChainsOutWithBalances,
    tokensListWithBalancesChainIn,
    tokensListWithBalancesChainOut,
    highlightedPickerChains,
  } = useBridgeDataWithBalances()
  const connectWallet = useConnectWallet()

  const clearRecipient = () => handleInputChange(InputActionKeys.Recipient, '')
  const clearAmount = () => handleInputChange(InputActionKeys.Amount, '')
  const clearAmountOut = () => handleInputChange(InputActionKeys.AmountOut, '')

  const amountUsd = useMemo(
    () =>
      formatBridgeAmountUsd({
        token: values.token,
        amount: values.amount,
        tokenPrices,
      }),
    [values.amount, values.token, tokenPrices],
  )
  const amountOutUsd = useMemo(
    () =>
      formatBridgeAmountUsd({
        token: values.token,
        amount: values.amountOut,
        tokenPrices,
      }),
    [values.amountOut, values.token, tokenPrices],
  )

  const showBridgeControls = useMemo(
    () => !isWidget && !isMobile && isEvmWalletConnected,
    [isWidget, isMobile, isEvmWalletConnected],
  )

  useEffect(() => {
    if (otherFieldUpdated || isFetchingQuote) {
      return
    }

    setOtherFieldUpdated(false)

    if (bridgeState.mode === 'receive' && payAmount) {
      handleInputChange(InputActionKeys.Amount, bigDecimalToNumericString(payAmount))
    }
    if (bridgeState.mode === 'pay' && receiveAmount) {
      handleInputChange(InputActionKeys.AmountOut, bigDecimalToNumericString(receiveAmount))
    }

    // Ignoring handleInputChange because it is just a utility, does not contain no relevant dependency
    // Including it in the dependency array causes an infinite loop due to the bridgeState update in useBridge
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Message above
  }, [payAmount, receiveAmount, bridgeState.mode, isFetchingQuote, otherFieldUpdated])

  const sharedNetworkSelectProps: Pick<
    NetworkSelectProps,
    'token' | 'onConnectWallet' | 'isEvmWalletConnected' | 'fullScreenMobile' | 'mobileZIndex'
  > = {
    token: values.token,
    onConnectWallet: connectWallet,
    isEvmWalletConnected: isEvmWalletConnected || isWidget,
    fullScreenMobile: isMobile && !isWidget,
    mobileZIndex: zIndices.mobileMenuOpen,
  }

  const sharedTokenInputProps: {
    inputPadding: TokenInputProps['inputPadding']
    showBorder: TokenInputProps['showBorder']
    disableHover: TokenInputProps['disableHover']
    tokenSelectProps: Pick<
      TokenInputProps['tokenSelectProps'],
      | 'shouldOverridePadding'
      | 'showTokenSearch'
      | 'showChainIcon'
      | 'chainIconCustomSize'
      | 'fullScreenMobile'
      | 'mobileZIndex'
      | 'chainFilterList'
      | 'useGroupedLists'
      | 'showActiveChainName'
    >
  } = {
    disableHover: true,
    showBorder: false,
    inputPadding: false,
    tokenSelectProps: {
      shouldOverridePadding: true,
      showTokenSearch: isSwapUiAbTestEnabled,
      showChainIcon: true,
      chainIconCustomSize: TOKEN_INPUT_CHAIN_CUSTOM_SIZE,
      fullScreenMobile: isMobile && !isWidget,
      mobileZIndex: zIndices.mobileMenuOpen,
      chainFilterList: isSwapUiAbTestEnabled ? highlightedPickerChains : undefined,
      useGroupedLists: !isSwapUiAbTestEnabled,
      showActiveChainName: isSwapUiAbTestEnabled,
    },
  }

  log({
    availableChainsInWithBalances,
    availableChainsOutWithBalances,
    tokensListWithBalancesChainIn,
    tokensListWithBalancesChainOut,
    chainInConfig,
    chainOutConfig,
  })

  return (
    <BridgeLayout>
      <TokenLabel>
        <Text weight={600} id="bridge-title-form-state">
          {bridgeWidgetLabels.bridge()}
        </Text>
      </TokenLabel>
      {isWidget && (isNonEvmWalletConnected || isEvmWalletConnected) && <BridgeConnectedWallet />}
      {showBridgeControls && <BridgeControls />}

      <TokenAmounts $bigInputs={isSwapUiAbTestEnabled}>
        <LabelGroup $isWrapper={isSwapUiAbTestEnabled}>
          <TextNetworkSelectWrapper $justifyContent="space-between">
            <LabelText $indented={isSwapUiAbTestEnabled} size={TextSize.XS} color="textSecondary">
              {translate('helpers.from')}
            </LabelText>

            {!isSwapUiAbTestEnabled && (
              <NetworkSelect
                {...sharedNetworkSelectProps}
                name="chainIn"
                networks={availableChainsInWithBalances}
                dictionary={networkSelectorDictionaryChainFrom}
                handleSelection={({ chain, token }) => {
                  if (chain === values.chainOut) {
                    clearRecipient()
                    handleSwitchChains()
                  } else {
                    handleSelectChange(SelectableActionKeys.ChainIn, chain)
                  }
                  if (token) {
                    handleSelectChange(SelectableActionKeys.Token, token)
                  }
                }}
              />
            )}
          </TextNetworkSelectWrapper>

          <ComponentPanelNew $inWrapper={isSwapUiAbTestEnabled} $disabled>
            <ComponentPanelRowNew $autoHeight $smallLeftMargin>
              <TokenInput
                {...sharedTokenInputProps}
                tokenSelectProps={{
                  ...sharedTokenInputProps.tokenSelectProps,
                  id: 'deposit-token-select',
                  name: 'token',
                  list: tokensListWithBalancesChainIn,
                  dictionary: tokenInputInDictionary,
                  showButtonBackground: true,
                  activeChain: values.chainIn,
                  activeChainName: chainInConfig?.name,
                  showUserTokenBalance: showUserDataWhenWalletNotConnected,
                  displayValue: getBridgeReceiveToken(values.chainIn, values.token),
                  chainFilterNetworkSelectProps: isSwapUiAbTestEnabled
                    ? {
                        ...sharedNetworkSelectProps,
                        name: 'chain-in-filter',
                        networks: availableChainsInWithBalances,
                        dictionary: networkSelectorDictionaryChain,
                      }
                    : undefined,
                  onSelect: (token?: string, chain?: string) => {
                    if (token) {
                      handleSelectChange(SelectableActionKeys.Token, token)
                    }
                    if (chain && isSwapUiAbTestEnabled) {
                      handleSelectChange(SelectableActionKeys.ChainIn, chain)
                    }
                  },
                }}
                inputProps={{
                  id: 'deposit-input',
                  name: 'amount',
                  valueError: normalizedError?.errorKey === InputActionKeys.Amount.toLowerCase(),
                  inputValue: bridgeState.amount,
                  onInputChange: (newDepositAmount) => {
                    dispatch(setMode('pay'))
                    clearAmountOut()
                    handleInputChange(InputActionKeys.Amount, newDepositAmount)
                    setOtherFieldUpdated(false)
                  },
                }}
              />
            </ComponentPanelRowNew>
            <ComponentPanelRowSmall
              $autoHeight
              $justifyContent={showUserDataWhenWalletNotConnected ? 'space-between' : 'flex-end'}
            >
              {showUserDataWhenWalletNotConnected && (
                <FlexContainer $gap="4px">
                  <Text size={TextSize.XS} color="textSecondary" noWrap>
                    {formatFloat(tokenBalanceChainIn)}{' '}
                    {getBridgeReceiveToken(values.chainIn, values.token) || values.token || ''}
                  </Text>
                  <MaxButton
                    id="max-bridge"
                    variant={ButtonVariant.text}
                    size={ButtonSizes.ExtraSmall}
                    onClick={() => {
                      // Do not set the max again if the input value is already set to max
                      // If this is not done, the amount out will just get cleared without
                      // getting updated again because the quote is cached
                      if (maxBridgeableAmount !== values.amount) {
                        dispatch(setMode('pay'))
                        clearAmountOut()
                        handleInputChange(InputActionKeys.Amount, maxBridgeableAmount)
                        setOtherFieldUpdated(false)
                      }
                    }}
                  >
                    {bridgeWidgetLabels.max().toUpperCase()}
                  </MaxButton>
                </FlexContainer>
              )}
              <Text size={TextSize.XS} color="textSecondary">
                ${amountUsd}
              </Text>
            </ComponentPanelRowSmall>
          </ComponentPanelNew>
        </LabelGroup>

        <SwitchNetworks $inBetweenInputs={isSwapUiAbTestEnabled}>
          <Icon
            background={isSwapUiAbTestEnabled ? 'cardBackground' : undefined}
            id="arrow-left-right"
            active
            handleClick={() => {
              clearRecipient()
              handleSwitchChains()
            }}
            size={isSwapUiAbTestEnabled ? IconSizes.Small : IconSizes.Medium}
            customIconElement={<SwitchIcon />}
          />
        </SwitchNetworks>

        <LabelGroup $isWrapper={isSwapUiAbTestEnabled}>
          <TextNetworkSelectWrapper $justifyContent="space-between">
            <LabelText $indented={isSwapUiAbTestEnabled} size={TextSize.XS} color="textSecondary">
              {translate('helpers.to')}
            </LabelText>

            {!isSwapUiAbTestEnabled && (
              <NetworkSelect
                {...sharedNetworkSelectProps}
                name="chainOut"
                networks={availableChainsOutWithBalances}
                dictionary={networkSelectorDictionaryChainTo}
                handleSelection={({ chain, token }) => {
                  clearRecipient() // Clear recipient needs to be triggered first to avoid history issues
                  if (chain === values.chainIn) {
                    handleSwitchChains()
                  } else {
                    handleSelectChange(SelectableActionKeys.ChainOut, chain)
                  }
                  if (token) {
                    handleSelectChange(SelectableActionKeys.Token, token)
                  }
                }}
              />
            )}
          </TextNetworkSelectWrapper>

          <ComponentPanelNew $inWrapper={isSwapUiAbTestEnabled} $disabled>
            <ComponentPanelRowNew $autoHeight $smallLeftMargin>
              <TokenInput
                {...sharedTokenInputProps}
                tokenSelectProps={{
                  ...sharedTokenInputProps.tokenSelectProps,
                  id: 'withdraw-token-select',
                  name: 'token',
                  disabled: !isSwapUiAbTestEnabled,
                  activeChain: values.chainOut,
                  activeChainName: chainOutConfig?.name,
                  dictionary: tokenInputOutDictionary,
                  showButtonBackground: isSwapUiAbTestEnabled,
                  list: tokensListWithBalancesChainOut,
                  displayValue: getBridgeReceiveToken(values.chainOut, values.token),
                  showRouteEmptyState: isSwapUiAbTestEnabled,
                  chainFilterNetworkSelectProps: isSwapUiAbTestEnabled
                    ? {
                        ...sharedNetworkSelectProps,
                        name: 'chain-out-filter',
                        networks: availableChainsOutWithBalances,
                        dictionary: networkSelectorDictionaryChain,
                      }
                    : undefined,
                  onSelect: (_?: string, chain?: string) => {
                    if (chain && isSwapUiAbTestEnabled) {
                      handleSelectChange(SelectableActionKeys.ChainOut, chain)
                    }
                  },
                }}
                inputProps={{
                  id: 'deposit-input-out',
                  name: 'amountOut',
                  valueError: normalizedError?.errorKey === InputActionKeys.AmountOut.toLowerCase(),
                  inputValue: bridgeState.amountOut,
                  onInputChange: (newDepositAmount) => {
                    dispatch(setMode('receive'))
                    clearAmount()
                    handleInputChange(InputActionKeys.AmountOut, newDepositAmount)
                    setOtherFieldUpdated(false)
                  },
                }}
              />
            </ComponentPanelRowNew>
            <ComponentPanelRowSmall $autoHeight>
              <Text size={TextSize.XS} color="textSecondary" noWrap>
                {formatFloat(tokenBalanceChainOut)}{' '}
                {getBridgeReceiveToken(values.chainOut, values.token) || values.token || ''}
              </Text>

              <Text size={TextSize.XS} color="textSecondary">
                ${amountOutUsd}
              </Text>
            </ComponentPanelRowSmall>
          </ComponentPanelNew>
        </LabelGroup>
      </TokenAmounts>

      <BridgeToAny
        values={values}
        setFieldValue={setFieldValue}
        valueError={normalizedError?.errorKey === InputActionKeys.Recipient.toLowerCase()}
      />
      <ReviewButton
        isFetchingQuote={isFetchingQuote}
        chain={values.chainIn}
        withdrawChain={values.chainOut}
        amount={values.amount}
        chainName={chainInConfig?.name ?? ''}
        withdrawChainName={chainOutConfig?.name ?? ''}
        normalizedError={normalizedError}
        isOtherAddress={values.isOtherAddress}
        token={values.token}
        payAmount={payAmount}
      />
      <BridgeFeeBreakdown
        amount={values.amount}
        token={values.token}
        chainIn={values.chainIn}
        chainOut={values.chainOut}
        feesConfig={withdrawalFeeConfig}
        promoConfig={withdrawalPromoConfig}
        payAmount={payAmount}
      />
      <GasBoost
        token={values.token}
        chainOut={values.chainOut}
        gasBoostOptions={gasBoostOptions}
        setFieldValue={setFieldValue}
      />
      {isWidget && <PoweredByRhino />}
    </BridgeLayout>
  )
}
