import styled from 'styled-components'
import { translate } from '../../../intl/i18n'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { Button, Text, TextSize, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { shortenAddress } from '../../../services/helperService/shortenAddress'
import { MobileMenuSection } from './MobileMenuSection'
import { useWallet } from '../../../hooks/useWallet'
import PowerIcon from '../../../assets/icons/power.svg?react'
import { useAppSelector } from '../../../hooks'
import { selectAddress, selectConnectedNetwork } from '../../../store/selectors/user.selectors'
import { selectBridgeConfig } from '../../../store/apis/config.api'
import { findBridgeKeyForNetworkId } from '../../../services/helperService/findBridgeKeyForNetworkId'

type Props = {
  showBorder?: boolean
}

export const MobileMenuSectionConnectedEvmWallet = ({ showBorder = true }: Props) => {
  const walletProvider = useWallet()

  const walletAddress = useAppSelector(selectAddress)
  const network = useAppSelector(selectConnectedNetwork)

  const bridgeConfig = useAppSelector(selectBridgeConfig)
  const chainID = findBridgeKeyForNetworkId(network?.toString() || '', bridgeConfig) || ''

  return (
    <MobileMenuSection title={translate('onboarding.connected_evm_wallet')} showBorder={showBorder}>
      <WalletInfoWrapper $justifyContent="space-between" $padding="0 8px">
        <FlexContainer $gap="12px">
          <TokenIcon token={chainID} size="large" />

          <Text size={TextSize.S} bold>
            {shortenAddress(walletAddress, false, 4)}
          </Text>
        </FlexContainer>

        <Button id="mobile-disconnect-evm" onClick={walletProvider.disconnect}>
          <FlexContainer $gap="8px">
            <PowerIcon />
            <Text bold size={TextSize.XS}>
              {translate('helpers.disconnect')}
            </Text>
          </FlexContainer>
        </Button>
      </WalletInfoWrapper>
    </MobileMenuSection>
  )
}

export const WalletInfoWrapper = styled(FlexContainer)`
  min-height: 54px;
`
