import type EthereumProvider from '@walletconnect/ethereum-provider'
import { WALLET_CONNECT_PROJECT_ID } from '../../../constants/portalConfig'

// eslint-disable-next-line functional/no-let -- pattern requires let
let provider: EthereumProvider | undefined

export const getWalletConnectProvider = async () => {
  const { default: WalletConnectProvider } = await import('@walletconnect/ethereum-provider')
  if (!provider) {
    provider = await WalletConnectProvider.init({
      projectId: WALLET_CONNECT_PROJECT_ID,
      metadata: {
        name: 'rhino.fi',
        description: 'Bridge your crypto to and from multiple chains in seconds',
        url: 'https://app.rhino.fi/', // origin must match your domain & subdomain
        icons: [''],
      },
      showQrModal: true,
      optionalChains: [1, 137, 2020],
    })
  }
  return provider
}
