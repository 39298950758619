import { default as styled } from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

export const Main = styled.div<{ $isWidget: boolean }>`
  position: relative;
  background: ${({ theme, $isWidget }) => ($isWidget ? 'transparent' : theme.pageBackground)};
  font-family: ${(props) => props.theme.defaultFont};
  color: ${(props) => props.theme.secondary};
  overflow: auto;
  min-width: unset;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;

  .simplebar-scrollbar:before {
    background: ${(props) => props.theme.scrollbar};
  }

  button {
    font-family: ${(props) => props.theme.defaultFont};
  }

  i {
    font-size: 24px;
  }

  @media ${respondBelow('xxs')} {
    min-width: 0;
    max-width: 100vw;
    width: 100%;
  }
`
