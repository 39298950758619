export const shortenAddress = (address?: string, onlyBeginning?: boolean, numChars?: number) => {
  const characterLimitBeginning = numChars || 8
  const characterLimitEnd = numChars || 5

  return address
    ? onlyBeginning
      ? `${address.slice(0, characterLimitBeginning)}...`
      : `${address.slice(0, characterLimitEnd + 2)}...${address.slice(-characterLimitEnd)}`
    : ''
}
