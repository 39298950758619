import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 299px;
`

export const SummaryCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.divider}`};
  border-radius: 8px;
  margin-bottom: 24px;
`

export const ChainCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.divider}`};
  border-radius: 8px;
  padding: 16px;
`

export const TxLink = styled.a`
  color: ${({ theme }) => theme.textLink};
  font-size: 12px;
  text-decoration: none;
`

export const StatusIconWrapper = styled.div`
  position: absolute;
  bottom: -3px;
  right: -3px;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 100%;
`
