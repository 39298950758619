import styled from 'styled-components'
import { Text, Title } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

export const WalletsListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const WalletButtonStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;

  background: transparent;
  border: none;

  outline: none;

  height: 54px;
  box-sizing: border-box;
  padding: 0 16px 0 8px;
  border-radius: 8px;

  transition: all 0.1s ease-in-out;
  cursor: pointer;

  .wallet-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
  }

  .wallet-icon {
    max-width: 32px;
    border-radius: 8px;
  }

  &:hover {
    background: ${({ theme }) => theme.bridgePicker};
  }
  &:active {
    background: ${({ theme }) => theme.primaryMain};
  }

  &:active {
  }
`

export const WalletSearchContainer = styled.div`
  min-height: 344px;

  @media ${respondBelow('xxs')} {
    min-height: 532px;
  }
`

export const ConnectLoaderWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ConnectSectionText = styled(Text)`
  padding-left: 8px;
  margin-bottom: 4px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`

export const ConnectSectionTitle = styled(Title)`
  padding-left: 8px;
`

export const NormalIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
`
