/* eslint-disable -- whatever */
export const Binary = {}
export const Decimal128 = {}
export const Double = {}
export const Int32 = {}
export const Long = {}
export class ObjectId {
  constructor(_?: any) {}
}
export class Timestamp {
  constructor(..._: any[]) {}
  getLowBits() {
    return 0
  }
  getHighBits() {
    return 0
  }
  static fromBits(..._: any[]) {
    return new Timestamp()
  }
}
export const UUID = {}
