import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import type { BridgeContractCallProps } from '../../../../pages/Bridge/services/bridge.contract'
import { getParadexProvider } from '../../providers/paradexProvider'

export const handleParadexDeposit = async ({
  tokenAddress,
  bridgeContractAddress,
  amount,
  commitmentId,
  callback,
}: BridgeContractCallProps) => {
  const Paradex = await import('@paradex/sdk')
  const { CallData, cairo } = await import('starknet')
  const { account, provider, config } = getParadexProvider()
  const formattedAmount = bigDecimalToNumericString(amount)
  const id = `0x${commitmentId}`

  const { receivableAmount, socializedLossFactor, receivableAmountChain } = await Paradex.Paraclear.getReceivableAmount(
    {
      provider: account,
      config,
      token: 'USDC',
      amount: formattedAmount,
    },
  )

  if (Number(socializedLossFactor) !== 0) {
    // Display a warning to the user, suggesting to withdraw a smaller
    // amount or to wait for the socialized loss factor to decrease.
    console.warn(`Socialized loss is active. You will receive` + ` ${receivableAmount} USDC.`)
  }
  const withdrawResult = await Paradex.Paraclear.withdraw({
    config,
    account,
    token: 'USDC',
    amount: formattedAmount,
    bridgeCall: [
      {
        contractAddress: tokenAddress,
        entrypoint: 'approve',
        calldata: CallData.compile({
          spender: bridgeContractAddress,
          amount: cairo.uint256(receivableAmountChain),
        }),
      },
      {
        contractAddress: bridgeContractAddress,
        entrypoint: 'deposit_with_id',
        calldata: CallData.compile({
          token: tokenAddress,
          amount: cairo.uint256(receivableAmountChain),
          commitment_id: id,
        }),
      },
    ],
  })

  callback?.(withdrawResult.hash, commitmentId)

  const result = await provider.waitForTransaction(withdrawResult.hash)

  if ('revert_reason' in result && 'execution_status' in result) {
    if (result.execution_status === 'REVERTED') {
      throw new Error(result.revert_reason)
    }
  }

  return { transactionHash: withdrawResult.hash }
}
