import { translate } from '../../../intl/i18n'
import { RequireChainContent } from './RequireChainContent'
import { Modal } from '../../common/Modal/Modal'
import type { RequireChainContentProps } from '../types/RequireChainTypes'

export const RequireChainModal = (props: RequireChainContentProps) => (
  <Modal
    isVisible={props.mustSwitchNetwork}
    onClose={props.onClickClose}
    width="410px"
    closeOnOverlayClick={false}
    title={translate('global.require_chain_modal_title')}
  >
    <RequireChainContent {...props} />
  </Modal>
)
