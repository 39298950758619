import { useEffect, useState } from 'react'
import { Multicall } from 'ethereum-multicall'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { useAppSelector } from '../../../hooks'
import { envConfig } from '../../../env/envConfig'
import { CrossChainSwapAbi__factory } from '../../../contracts/CrossChainSwap'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import type Decimal from 'decimal.js'
import { mainnetChainConfig } from './mainnetChainConfig'
import { tokenPricesSnapshot } from './tokenPrices'

const { multiCallDoNotAggregate, LEGACY_CROSS_CHAIN_SWAP_CONTRACTS } = envConfig

const getTokens = () => import('./tokens.json').then((tokens) => tokens.default)

type Token = Awaited<ReturnType<typeof getTokens>>[number]
export type TokenBalance = Token & { balance: Decimal }

const USD_THRESHOLD = 1

export const useTokenBalances = () => {
  const [tokenBalances, setTokenBalances] = useState<TokenBalance[]>()
  const userAddress = useAppSelector(selectAddress)
  const isWalletConnected = useAppSelector(selectIsAuthenticated)
  useEffect(() => {
    if (!userAddress || !isWalletConnected) {
      setTokenBalances(undefined)
      return
    }
    void getTokens().then((tokens) =>
      Promise.all(
        LEGACY_CROSS_CHAIN_SWAP_CONTRACTS.flatMap(({ chain, contractAddress }) => {
          const config = mainnetChainConfig[chain]
          if (!config) {
            return []
          }
          return getMulticallBalances({
            userAddress,
            rpcUrl: config.rpc,
            chain,
            multicallContractAddress: config.multicallContractAddress,
            tokens: tokens.filter((token) => token.chain === chain),
            xChainContractAddress: contractAddress,
          })
        }),
      ).then((balances) => {
        const list = balances.flat()
        // filter balances to only those higher than THRESHOLD
        const nonDust = list.filter(({ balance, token, decimals }) =>
          balance
            .div(10 ** decimals)
            .mul(tokenPricesSnapshot[token] || 0)
            .gt(USD_THRESHOLD),
        )
        return setTokenBalances(nonDust)
      }),
    )
  }, [userAddress, isWalletConnected])
  return tokenBalances
}

const getMulticallBalances = async ({
  userAddress,
  rpcUrl,
  chain,
  multicallContractAddress,
  tokens,
  xChainContractAddress,
}: {
  userAddress: string
  rpcUrl: string
  chain: string
  multicallContractAddress: string | undefined
  tokens: Token[]
  xChainContractAddress: string
}) =>
  new Promise<TokenBalance[]>((resolve) => {
    // Init multicall
    const timeoutId = setTimeout(() => {
      resolve([])
    }, 6000)

    const multicall = new Multicall({
      // ethers v6 support is in progress so use custom json rpc provider
      // https://github.com/joshstevens19/ethereum-multicall/pull/72
      nodeUrl: rpcUrl,
      tryAggregate: !multiCallDoNotAggregate.includes(chain),
      ...(multicallContractAddress && { multicallCustomContractAddress: multicallContractAddress }),
    })

    // Build calls
    const contractCallContext = tokens.map((token) => {
      return {
        reference: token.token,
        contractAddress: xChainContractAddress,
        calls: [
          { reference: 'userBalancesCall', methodName: 'userBalances', methodParameters: [userAddress, token.address] },
        ],
        abi: [CrossChainSwapAbi__factory.abi[2]],
        context: token,
      }
    })

    multicall
      .call(contractCallContext)
      .then((calls) => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        const tokenBalances = Object.entries(calls.results)
          .flatMap(([, data]) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument -- handle untyped data
            const balance = SafeDecimal(data.callsReturnContext[0]?.returnValues?.[0]?.['hex'] ?? '0x0')
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- handle untyped data
            return {
              balance,
              ...data.originalContractCallContext.context,
            } as TokenBalance
          })
          .filter(({ balance }) => balance.gt(0))
        resolve(tokenBalances)
      })
      .catch(() => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        resolve([])
      })
  })
