import { Schema } from 'effect'
import { LogFeature, makeLog } from '../../utils/makeLog'
import type { AuthDataType } from './auth.types'
import { AuthDataSchema } from './auth.types'
import { authKey } from './authKey'
import jsonwebtoken from 'jsonwebtoken'
import { JwtDataSchema } from '@rhinofi/authentication-api-spec'

const log = makeLog(LogFeature.AUTH)
const urlParams = new URLSearchParams(window.location.search)
const widgetJwt = urlParams.get('api_key')

export const getAuthData = (address: string): AuthDataType | null => {
  if (widgetJwt) {
    const decodedToken = Schema.decodeUnknownSync(JwtDataSchema)(jsonwebtoken.decode(widgetJwt, { json: true }))
    return {
      jwt: widgetJwt,
      createdAt: new Date(),
      ethAddress: '0x0000000000000000000000000000000000000000',
      userId: decodedToken.userId,
    }
  }

  const item = localStorage.getItem(authKey(address))
  if (!item) {
    return null
  }

  const parsed = JSON.parse(item)

  try {
    const decoded = Schema.decodeUnknownSync(AuthDataSchema)(parsed)
    log('Loading auth from local storage', decoded)
    return decoded
  } catch {
    return null
  }
}
