export const LogFeature = {
  BRIDGE_STATE: 'BRIDGE_STATE',
  BRIDGE_FORM_STATE: 'BRIDGE_FORM_STATE',
  BRIDGE_NAVIGATE: 'BRIDGE_NAVIGATE',
  BRIDGE_VALIDATION: 'BRIDGE_VALIDATION',
  BRIDGE_WIDGET: 'BRIDGE_WIDGET',
  BRIDGE_APPROVALS: 'BRIDGE_APPROVALS',
  CONFIG: 'CONFIG',
  REOWN_CONNECTION: 'REOWN_CONNECTION',
  EIP1193_KIT: 'EIP1193_KIT',
  INJECTED_CONNECTION: 'INJECTED_CONNECTION',
  WALLET_CONTEXT: 'WALLET_CONTEXT',
  AUTH: 'AUTH',
  HEAP_TRACKING: 'HEAP_TRACKING',
  L1_BALANCES_FETCH: 'L1_BALANCES_FETCH',
  ADDRESSABLE_TRACKING: 'ADDRESSABLE_TRACKING',
  BRIDGE_HISTORY: 'BRIDGE_HISTORY',
} as const

const enabledLogs: (typeof LogFeature)[keyof typeof LogFeature][] = [
  // LogFeature.ADDRESSABLE_TRACKING,
  // LogFeature.BRIDGE_STATE,
  // LogFeature.BRIDGE_FORM_STATE,
  // LogFeature.BRIDGE_NAVIGATE,
  // LogFeature.BRIDGE_VALIDATION,
  // LogFeature.BRIDGE_WIDGET,
  // LogFeature.CONFIG,
  // LogFeature.REOWN_CONNECTION,
  // LogFeature.EIP1193_KIT,
  // LogFeature.INJECTED_CONNECTION,
  // LogFeature.WALLET_CONTEXT,
  // LogFeature.AUTH,
  // LogFeature.L1_BALANCES_FETCH,
  // LogFeature.BRIDGE_HISTORY,
]

export const makeLog =
  (feature: keyof typeof LogFeature) =>
  (...args: Parameters<typeof console.log>) => {
    if (enabledLogs.includes(feature)) {
      // eslint-disable-next-line no-console -- expected logger
      console.log(`[${feature}]`, ...args)
    }
  }
