import { ButtonVariant, Icon, IconSizes, TextSize } from '@rhinofi/dvf-shared-ui'
import type { MobileNavigationTabData } from '../../types/MobileNavigation.types'
import { CustomText, IconWrapper, Wrapper } from './MobileNavigationTab.styled'

type Props = {
  data: MobileNavigationTabData
}

export const MobileNavigationTab = ({ data: { onClick, icon, title, isActive, id } }: Props) => (
  <Wrapper id={`mobile-navigation-tab-${id}`} $isActive={isActive} onClick={onClick} variant={ButtonVariant.ghost}>
    <IconWrapper $isActive={isActive}>
      <Icon id="" customIconElement={icon} size={IconSizes.Medium} />
    </IconWrapper>

    <CustomText size={TextSize.XS} bold $isActive={isActive}>
      {title}
    </CustomText>
  </Wrapper>
)
