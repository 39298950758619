import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  resetInvalidInitialState,
  setAmount,
  setAmountOut,
  setBridgeState,
  setChainIn,
  setChainOut,
  setRecipient,
  setToken,
  switchChains,
} from '../../pages/Bridge/slices/bridge.slice'
import {
  getValidChains,
  isValidBridgeCombination,
  resetInvalidBridgeCombination,
  updateUrlFromState,
} from '../../utils/rtk/bridgeUrlSync'
import { configApi } from '../apis/config.api'
import type { AppDispatch, RootState } from '../configureStore'
import {
  selectAvailableChainByPassedToken,
  selectAvailableChainsByToken,
  selectBridgeConfigForChain,
} from '../selectors/bridgeConfig.selectors'

/**
 * Middleware to synchronize bridge state with the URL and validate state changes.
 */
export const bridgeListenerMiddleware = createListenerMiddleware()

bridgeListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
  matcher: isAnyOf(
    setChainIn,
    setChainOut,
    switchChains,
    setToken,
    setAmount,
    setAmountOut,
    setRecipient,
    resetInvalidInitialState,
  ),
  // eslint-disable-next-line @typescript-eslint/unbound-method -- noise
  effect: (_action, { dispatch, getState }) => {
    const state = getState()
    const bridgeState = state.bridge
    const chainConfig = configApi.endpoints.getConfig.select()(state).data
    const tokenAvailableChains = selectAvailableChainsByToken(state)

    const isSetChainInAction = _action.type === setChainIn.type
    const isSetChainOutAction = _action.type === setChainOut.type

    if (!chainConfig) {
      return
    }

    if (!isValidBridgeCombination({ bridgeState, chainConfig })) {
      // Picks a valid combination that works with the selected chain. Works for both chain in and chain out
      // 1. Get all supported tokens for the selected chain
      // 2. Get all chains that support the token
      // 3. Update the bridge state with valid config
      if ((isSetChainInAction || isSetChainOutAction) && typeof _action['payload'] === 'string') {
        const selectedChain = _action['payload']
        const selectedChainConfig = selectBridgeConfigForChain(state, selectedChain)

        if (selectedChainConfig) {
          const selectedChainTokens = Object.keys(selectedChainConfig.tokens)
          const selectedChainFirstToken = selectedChainTokens[0]

          if (selectedChainFirstToken) {
            const secondChain = selectAvailableChainByPassedToken(state, {
              token: selectedChainFirstToken,
              chain: selectedChain,
            })

            if (secondChain) {
              const newBridgeState = {
                ...bridgeState,
                chainIn: isSetChainInAction ? selectedChain : secondChain,
                chainOut: isSetChainOutAction ? selectedChain : secondChain,
                token: selectedChainFirstToken,
              }

              dispatch(setBridgeState(newBridgeState))
              return updateUrlFromState(newBridgeState)
            }
          }
        }
      }

      // If it's invalid bridge combination caused by the token change, try to set a valid combination for the new token
      if (_action.type === setToken.type || _action.type === resetInvalidInitialState.type) {
        if (bridgeState.token && tokenAvailableChains.length >= 2) {
          const { newChainIn, newChainOut } = getValidChains(tokenAvailableChains, bridgeState)

          if (newChainIn && newChainOut) {
            const newBridgeState = {
              ...bridgeState,
              chainIn: newChainIn,
              chainOut: newChainOut,
            }
            dispatch(setBridgeState(newBridgeState))
            return updateUrlFromState(newBridgeState)
          }
        }
      } else {
        // Fallback: reset to default if no valid chains are found
        return resetInvalidBridgeCombination(dispatch, 'bridgeListenerMiddleware', bridgeState, chainConfig)
      }
    }

    // Update the URL if the state is valid
    return updateUrlFromState(bridgeState)
  },
})
