import { FlexContainer } from '../../../common/Wrappers/Containers'
import { NoUnderlineAnchor } from '../../../common/Helpers/LinkHelpers'
import { Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { MOBILE_SOCIALS } from '../../constants/MobileNavigation.constants'

export const MobileNavigationMenuSocials = () => (
  <Wrapper $gap="16px" $justifyContent="center">
    {MOBILE_SOCIALS.map(({ icon, href, id }) => (
      <NoUnderlineAnchor key={id} id={id} href={href} rel="noopener noreferrer" target="_blank">
        <IconWrapper $justifyContent="center">
          <Icon id={icon} size={IconSizes.Large} />
        </IconWrapper>
      </NoUnderlineAnchor>
    ))}
  </Wrapper>
)

const Wrapper = styled(FlexContainer)`
  margin-top: 16px;
`

const IconWrapper = styled(FlexContainer)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.backgroundIconButton};
`
