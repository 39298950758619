import type { MulticallBalances } from './multicall'

export const mergeBlockchainBalanceState = ({
  currentBalances,
  updatedBalances,
}: {
  currentBalances: MulticallBalances
  updatedBalances: MulticallBalances
}) => {
  const updatedChains = Object.keys(updatedBalances)

  const mergedUpdatedChains = updatedChains.reduce((acc: MulticallBalances, chain) => {
    acc[chain] = {
      ...(currentBalances[chain] || {}),
      ...updatedBalances[chain],
    }

    return acc
  }, {})

  return {
    ...currentBalances,
    ...mergedUpdatedChains,
  }
}
