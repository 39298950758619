import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'

export const mainnetChainConfig: Record<string, BridgeConfigEntrySchema> = {
  ARBITRUM_MAINNET: {
    name: 'Arbitrum One',
    type: 'EVM',
    networkId: '42161',
    rpc: 'https://arb1.arbitrum.io/rpc',
    multicallContractAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
    tokens: {},
    confirmationBlocks: 1,
    avgBlockTime: 1,
    status: 'disabled' as const,
    blockExplorer: 'https://arbiscan.io',
    contractAddress: '',
    nativeTokenName: 'ETH',
    nativeTokenDecimals: 18,
    nativeTokenSafeguard: 0.001,
    gasBoostEnabled: false,
    site: '',
  },
  BINANCE_MAINNET: {
    name: 'BNB Smart Chain',
    type: 'EVM',
    networkId: '56',
    rpc: 'https://bsc-mainnet.public.blastapi.io/',
    multicallContractAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
    tokens: {},
    confirmationBlocks: 1,
    avgBlockTime: 1,
    status: 'disabled' as const,
    blockExplorer: 'https://bscscan.com',
    contractAddress: '',
    nativeTokenName: 'BNB',
    nativeTokenDecimals: 18,
    nativeTokenSafeguard: 0.008,
    gasBoostEnabled: false,
    site: '',
  },
  POLYGON_MAINNET: {
    name: 'Polygon',
    type: 'EVM',
    networkId: '137',
    rpc: 'https://rpc.ankr.com/polygon',
    multicallContractAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
    tokens: {},
    confirmationBlocks: 1,
    avgBlockTime: 1,
    status: 'disabled' as const,
    blockExplorer: 'https://polygonscan.com',
    contractAddress: '',
    nativeTokenName: 'POL',
    nativeTokenDecimals: 18,
    nativeTokenSafeguard: 0.03,
    gasBoostEnabled: false,
    site: '',
  },
}
