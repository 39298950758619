import { FlexContainer } from '../../../../common/Wrappers/Containers'
import type { MobileNavigationMenuControls } from '../../../types/MobileNavigation.types'
import { useMobileNavigationMenuSections } from '../../../hooks/useMobileNavigationMenuSections'
import { MobileNavigationMenuLinkRoot } from './MobileNavigationMenuLinkRoot'
import { MobileMenuSection } from '../../../../MobileMenu/components/MobileMenuSection'

type Props = {
  controls: MobileNavigationMenuControls
}

export const MobileNavigationMenuSections = ({ controls }: Props) => {
  const sections = useMobileNavigationMenuSections({ controls })

  return (
    <FlexContainer $flexGrow="1" $direction="column" $alignItems="stretch">
      {sections.map(({ id, title, links }) => (
        <MobileMenuSection key={id} title={title}>
          {links.map((link) => (
            <MobileNavigationMenuLinkRoot key={link.id} data={link} controls={controls} />
          ))}
        </MobileMenuSection>
      ))}
    </FlexContainer>
  )
}
