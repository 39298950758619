import { HttpApiClient } from '@effect/platform'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BridgeConfigsResponseSchema } from '@rhinofi/bridge-api-spec'
import { apiSpec } from '@rhinofi/bridge-api-spec'
import { createSelector } from 'reselect'
import { envConfig } from '../../env/envConfig'
import { makeApiClientRequest, runEffectHttpCallback } from '../../utils/effect'
import { isChainForceEnabled } from '../../services/helperService/isChainForceEnabled'
import type { Mutable } from 'effect/Types'
import { selectIsWidget } from '../selectors/portal.selectors'
import { NETWORKS } from '../../constants/types'

const BASE_URL = `${envConfig.tradingApi}/bridge`

const apiClient = HttpApiClient.make(apiSpec, { baseUrl: BASE_URL })

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getConfig: builder.query<Mutable<BridgeConfigsResponseSchema>, void>({
      queryFn: async () => {
        const response = await runEffectHttpCallback(() =>
          makeApiClientRequest(apiClient, (client) => client.configs.configs()),
        )

        if ('data' in response && response.data) {
          const entries = Object.entries(response.data)
          return {
            data: entries.reduce((acc: Mutable<BridgeConfigsResponseSchema>, [chain, config]) => {
              if (isChainForceEnabled(chain)) {
                acc[chain] = { ...config, status: 'enabled' as const }
              } else {
                acc[chain] = config
              }

              return acc
            }, {}),
          }
        }

        return response
      },
    }),
  }),
})

export const { useGetConfigQuery } = configApi

// Selectors
export const selectConfig = configApi.endpoints.getConfig.select()

export const selectBridgeConfig = createSelector(selectConfig, (result) => {
  if (!result?.data) {
    return {}
  }
  return result.data
})

export const selectAvailableChains = createSelector(selectConfig, selectIsWidget, (result, isWidget) => {
  if (!result?.data) {
    return []
  }
  const widgetDisabledChains = [NETWORKS.TON, NETWORKS.PARADEX]
  return Object.entries(result.data)
    .filter(([key, config]) => config.status === 'enabled' && (!isWidget || !widgetDisabledChains.includes(key)))
    .map(([chain, config]) => ({ chain, config }))
})

export const selectAvailableTokens = createSelector(selectAvailableChains, (availableChains) => {
  const availableTokens = availableChains.flatMap(({ config }) => Object.keys(config.tokens))

  return [...new Set(availableTokens)]
})
