import { NETWORKS } from '../../../constants/types'
import { callEvmBridgeContract } from './callEvmBridgeContract'
import type { Signer } from 'ethers'
import { handleTonDeposit } from '../../../services/secondaryWallet/services/tonService/handleTonDeposit'
import type { BigDecimal } from 'effect/BigDecimal'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { handleTronDeposit } from '../../../services/secondaryWallet/services/tronService/handleTronDeposit'
import { handleSolanaDeposit } from '../../../services/secondaryWallet/services/solanaService/handleSolanaDeposit'
import { handleStarknetDeposit } from '../../../services/secondaryWallet/services/starknetService/handleStarknetDeposit'
import { handleParadexDeposit } from '../../../services/secondaryWallet/services/paradexService/handleParadexDeposit'

export type BridgeContractCallProps = {
  amount: BigDecimal
  amountWithDecimals: string
  chain: string
  commitmentId: string
  bridgeContractAddress: string
  tokenAddress: string
  isNativeToken: boolean
  callback?: (txHash: string, bridgeId: string) => void
  secondaryWalletAddress?: string | undefined
  ethersSigner?: Signer | null | undefined
}

export const callBridgeContract = (props: BridgeContractCallProps) => {
  const { chain } = props
  if (isNonEVMChain(chain)) {
    if (chain === NETWORKS.TON) {
      return handleTonDeposit(props)
    } else if (chain === NETWORKS.TRON) {
      return handleTronDeposit(props)
    } else if (chain === NETWORKS.SOLANA) {
      return handleSolanaDeposit(props)
    } else if (chain === NETWORKS.STARKNET) {
      return handleStarknetDeposit(props)
    } else if (chain === NETWORKS.PARADEX) {
      return handleParadexDeposit(props)
    } else {
      throw new Error('Chain not supported')
    }
  } else {
    return callEvmBridgeContract(props)
  }
}
