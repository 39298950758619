import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { tryDisconnectSecondaryWallet } from '../../services/secondaryWallet/tryDisconnectSecondaryWallet'
import { fetchBlockChainBalanceForChain } from '../actions/user.actions'
import type { AppDispatch, RootState } from '../configureStore'
import { selectBridgeConfigForChain } from '../selectors/bridgeConfig.selectors'
import { selectSecondaryWalletAddressAndChain } from '../selectors/secondaryWallet.selectors'
import { selectAddress } from '../selectors/user.selectors'
import {
  connectSecondaryWallet,
  secondaryWalletSlice,
  updateSecondaryAddress,
  updateSecondaryNetworkId,
} from '../slices/secondaryWallet.slice'
import { setAuthData } from '../slices/user.slice'
import { getAuthData } from '../../services/auth/getAuthData'

export const secondaryWalletMiddleware = createListenerMiddleware()

secondaryWalletMiddleware.startListening.withTypes<RootState, AppDispatch>()({
  matcher: isAnyOf(
    updateSecondaryAddress.match,
    updateSecondaryNetworkId.match,
    connectSecondaryWallet.fulfilled.match,
  ),
  // eslint-disable-next-line @typescript-eslint/unbound-method -- noise
  effect: (_action, { dispatch, getState }) => {
    const secondaryWallet = selectSecondaryWalletAddressAndChain(getState())
    const address = selectAddress(getState())
    const chainConfig = selectBridgeConfigForChain(getState(), secondaryWallet.connectedChain)
    if (!chainConfig) {
      return
    }
    void fetchBlockChainBalanceForChain(dispatch)({
      chain: secondaryWallet.connectedChain,
      address,
      secondaryWallet,
      chainConfig,
    })
  },
})

secondaryWalletMiddleware.startListening.withTypes<RootState, AppDispatch>()({
  matcher: connectSecondaryWallet.fulfilled.match,
  // eslint-disable-next-line @typescript-eslint/unbound-method -- noise
  effect: (_action, { dispatch, getState }) => {
    const state = getState()
    if (state.portal.isWidget) {
      const authData = getAuthData('0x0000000000000000000000000000000000000000')
      dispatch(setAuthData(authData))
    }
  },
})

secondaryWalletMiddleware.startListening({
  matcher: secondaryWalletSlice.actions.disconnectSecondaryWallet.match,
  effect: () => {
    tryDisconnectSecondaryWallet()
  },
})
