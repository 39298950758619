import { Button, CoreCard, Text } from '@rhinofi/dvf-shared-ui'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { animated } from '@react-spring/web'
import styled, { css } from 'styled-components'
import { ComponentPanel, ComponentPanelRow } from '../../../../components/common/Form/ComponentPanel'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

export const FormWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const OverflowCoreCard = styled(CoreCard)<{ $isWidget: boolean }>`
  overflow: hidden;
  width: 100%;
  padding: 16px 16px 24px;

  max-width: 400px;
  min-width: 400px;

  ${({ $isWidget }) =>
    $isWidget
      ? css`
          @media ${respondBelow('xxxs')} {
            min-width: 100%;
          }
        `
      : css`
          @media ${respondBelow('xxs')} {
            min-width: 100%;
          }
        `}
`

export const BridgeLayout = styled(animated.div)`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
  grid-template-areas:
    'header badge'
    'token-amounts token-amounts'
    'destination destination'
    'gas-boost gas-boost'
    'submit submit'
    'saved saved'
    'notice notice'
    'poweredby poweredby';

  @media ${respondBelow('xxs')} {
    width: 100%;
  }
`

export const TokenAmounts = styled.div<{ $bigInputs: boolean }>`
  display: flex;
  grid-area: token-amounts;
  flex-direction: column;
  margin-top: 10px;
  gap: 12px;

  @media ${respondBelow('xxs')} {
    margin-top: 12px;
  }

  ${({ $bigInputs }) =>
    $bigInputs
      ? css`
          gap: 8px;
        `
      : ''}
`

export const TokenLabel = styled.div`
  grid-area: header;
  height: 38px;
  display: flex;
  align-items: center;

  @media ${respondBelow('xxs')} {
    height: 38px;
  }
`

export const LabelGroup = styled.div<{ $isWrapper: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ $isWrapper, theme }) =>
    $isWrapper
      ? css`
          gap: 6px;
          background: ${theme.bridgePicker};
          padding-top: 10px;
          border-radius: 8px;
        `
      : ''}
`

export const Max = styled.div`
  grid-area: max;
  justify-self: end;
  align-self: end;
`

export const ReviewButtonSpace = styled.div`
  margin-top: 24px;
  grid-area: submit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const FeeBreakdown = styled.div`
  padding: 26px 16px 20px;
  grid-area: saved;
  border-radius: 8px;
  margin-top: 16px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.divider};

  .breakdown-trigger {
    cursor: pointer !important;
  }

  &:hover {
    .see-details {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.textLink};
    }
  }

  @media ${respondBelow('xxs')} {
    margin-top: 24px;
  }
`

export const GasBoostGrid = styled.div`
  margin-top: 16px;
  grid-area: gas-boost;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Destination = styled.div`
  margin-top: 24px;
  grid-area: destination;

  @media ${respondBelow('xxs')} {
    margin-top: 20px;
  }
`

export const TextNetworkSelectWrapper = styled(FlexContainer)`
  position: static;
`

export const PoweredBySection = styled.div`
  grid-area: poweredby;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  user-select: none;
  margin-top: 20px;

  img {
    max-height: 16px;
  }
`

export const SwitchNetworks = styled.div<{ $inBetweenInputs: boolean }>`
  position: absolute;
  margin: 0 auto;
  top: 191px;
  align-self: center;

  ${({ $inBetweenInputs }) =>
    $inBetweenInputs
      ? css`
          top: 165px;
        `
      : css`
          @media ${respondBelow('xxs')} {
            display: none;
          }
        `}
`

export const MaxButton = styled(Button)`
  min-height: auto;
`

export const ComponentPanelRowNew = styled(ComponentPanelRow)`
  margin: 0 16px 0 8px;
`

export const ComponentPanelRowSmall = styled(ComponentPanelRow)<{ $justifyContent?: string }>`
  margin: 0 18px 0 16px;
  justify-content: ${({ $justifyContent }) => $justifyContent ?? 'space-between'};
`

export const ComponentPanelNew = styled(ComponentPanel)<{ $inWrapper: boolean }>`
  padding: 13px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $inWrapper }) =>
    $inWrapper
      ? css`
          gap: 8px;
          padding-top: 0;
          padding-bottom: 14px;
        `
      : ''}
`

export const LabelText = styled(Text)<{ $indented: boolean }>`
  ${({ $indented }) =>
    $indented
      ? css`
          margin-left: 16px;
        `
      : ''}
`
