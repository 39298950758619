import styled from 'styled-components'
import { Button } from '@rhinofi/dvf-shared-ui'

export const ConnectWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`

export const ConnectButton = styled(Button)`
  min-height: 48px;
`
