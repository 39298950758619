import styled, { css } from 'styled-components'
import { FlexContainer } from '../common/Wrappers/Containers'
import { SlideTransitionType } from './types/OpacitySlideTransition.types'

export const Container = styled.div<{ $isVisible: boolean; $zIndex: number; $duration: number }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  opacity: 0;
  z-index: ${({ $zIndex }) => $zIndex};
  background: ${({ theme }) => theme.actionModal};
  transition: opacity ${({ $duration }) => $duration}ms ease-in-out;

  &.fade-exit,
  &.fade-exit-active,
  &.fade-exit-done,
  &.fade-appear,
  &.fade-appear-active {
    opacity: 0;
  }

  &.fade-enter,
  &.fade-enter-done,
  &.fade-enter-active {
    opacity: 1;
  }
`

const handleWrapperSlideType = (slideType: SlideTransitionType, exitDuration: number) => {
  switch (slideType) {
    case SlideTransitionType.FROM_RIGHT:
      return css`
        transform: translateX(100%);

        &.slide-in-exit,
        &.slide-in-exit-active,
        &.slide-in-exit-done,
        &.slide-in-appear,
        &.slide-in-appear-active {
          transition: transform ${exitDuration}ms ease-in-out;
          transform: translateX(100%);
        }

        &.slide-in-enter,
        &.slide-in-enter-done,
        &.slide-in-enter-active {
          transform: translateX(0);
        }
      `

    case SlideTransitionType.FROM_BOTTOM:
      return css`
        transform: translateY(100%);
        justify-content: flex-end;

        &.slide-in-exit,
        &.slide-in-exit-active,
        &.slide-in-exit-done,
        &.slide-in-appear,
        &.slide-in-appear-active {
          transition: transform ${exitDuration}ms ease-in-out;
          transform: translateY(100%);
        }

        &.slide-in-enter,
        &.slide-in-enter-done,
        &.slide-in-enter-active {
          transform: translateY(0);
        }
      `
    default:
      return ''
  }
}

export const Wrapper = styled(FlexContainer)<{
  $slideType: SlideTransitionType
  $enterDuration: number
  $exitDuration: number
  $showBackground?: boolean
}>`
  height: 100%;
  width: 100%;
  transition: transform ${({ $enterDuration }) => $enterDuration}ms ease-in-out;
  background: ${({ theme, $showBackground }) => ($showBackground ? theme.cardBackground : 'none')};
  overflow-y: auto;

  ${({ $slideType, $exitDuration }) => handleWrapperSlideType($slideType, $exitDuration)};
`
