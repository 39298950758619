import { BridgeWidget } from './components/BridgeWidget/BridgeWidget'
import { ResponsivePage, ResponsivePageContainerCentered } from '../../components/common/Wrappers/Page'
import { BridgeTabs } from './components/BridgeTabs/BridgeTabs'
import { BridgePoweredBy } from './components/BridgePoweredBy/BridgePoweredBy'
import { FlexContainer } from '../../components/common/Wrappers/Containers'
import { Helmet } from 'react-helmet'
import { translate } from '../../intl/i18n'
import { useAppSelector } from '../../hooks'
import { selectIsWidget } from '../../store/selectors/portal.selectors'
import { useIsMobile } from '../../hooks/useIsMobile'
import { WidgetWrapper } from './Bridge.styled'
import { selectBridgeLayoutActiveMobileView, selectBridgeLayoutVisibleTab } from './slices/bridgeLayout.slice'
import { BridgeLayoutMobileView, BridgeLayoutVisibleTab } from './types/bridge-widget.types'
import { useIsSwapUiAbTestEnabled } from '../../hooks/useIsSwapUiAbTestEnabled'

export const BridgePage = () => {
  const { isMobile } = useIsMobile()
  const isWidget = useAppSelector(selectIsWidget)
  const activeMobileView = useAppSelector(selectBridgeLayoutActiveMobileView)
  const visibleTab = useAppSelector(selectBridgeLayoutVisibleTab)
  const isSwapUiAbTestEnabled = useIsSwapUiAbTestEnabled()

  return (
    <ResponsivePage $isWidget={isWidget}>
      <Helmet>
        <title>{translate('metadata.title_bridge')}</title>
        <meta name="description" content={translate('metadata.description_bridge')} />
      </Helmet>

      <ResponsivePageContainerCentered>
        {isMobile && !isWidget ? (
          <WidgetWrapper>
            {activeMobileView === BridgeLayoutMobileView.bridge && <BridgeWidget />}
            {(activeMobileView === BridgeLayoutMobileView.history ||
              activeMobileView === BridgeLayoutMobileView.portfolio) && <BridgeTabs />}
          </WidgetWrapper>
        ) : (
          <WidgetWrapper>
            <FlexContainer $direction="column" $fullWidth>
              <BridgeWidget />

              {!isWidget && !isSwapUiAbTestEnabled && <BridgePoweredBy />}
            </FlexContainer>

            {!isWidget && visibleTab === BridgeLayoutVisibleTab.history && <BridgeTabs />}
          </WidgetWrapper>
        )}
      </ResponsivePageContainerCentered>
    </ResponsivePage>
  )
}
