import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams, type NavigateOptions, type To } from 'react-router-dom'
import { initialState, type BridgeState } from '../../pages/Bridge/slices/bridge.slice'
import { publicRoutes } from '../../router'
import { selectAvailableChains } from '../../store/apis/config.api'
import { LogFeature, makeLog } from '../../utils/makeLog'
import { syncStateFromUrl } from '../../utils/rtk/bridgeUrlSync'
import { useAppDispatch } from '../useAppDispatch'
import { useAppSelector } from '../useAppSelector'
import { useConfig } from '../useConfig'
import { setActiveMobileView } from '../../pages/Bridge/slices/bridgeLayout.slice'
import { BridgeLayoutMobileView } from '../../pages/Bridge/types/bridge-widget.types'
import { useIsMobile } from '../useIsMobile'

const log = makeLog(LogFeature.BRIDGE_NAVIGATE)

type BridgeQueryParams = (keyof Omit<BridgeState, 'isOtherAddress'>)[]

const BRIDGE_QUERY_PARAMS: BridgeQueryParams = ['mode', 'chainIn', 'chainOut', 'token', 'amount', 'recipient'] as const

/**
 * Custom hook to navigate between bridge pages while ensuring that the app's state
 * remains synchronized with the URL.
 *
 * @returns {function} A callback function to navigate to a specified route and sync state.
 */
export const useBridgeNavigate = (): ((to: To, options?: NavigateOptions) => void) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [currentSearchParams, setUrlSearchParams] = useSearchParams()
  const { config } = useConfig()
  const { isMobile } = useIsMobile()

  const availableChains = useAppSelector(selectAvailableChains)

  const getAvailableChainsPerToken = useCallback(
    (token: string) =>
      availableChains.filter(({ config: chainConfig }) => chainConfig.tokens[token]).map(({ chain }) => chain),
    [availableChains],
  )

  // Get the fallback chain for the given parameter making sure it's not the same as the target chain
  const getFallbackChain = useCallback(
    (
      param: 'chainIn' | 'chainOut',
      currentParams: URLSearchParams,
      targetParams: URLSearchParams,
      availableTokenChains: string[],
    ) => {
      const oppositeParam = param === 'chainIn' ? 'chainOut' : 'chainIn'
      const currentChain = currentParams.get(param)
      const targetOppositeChain = targetParams.get(oppositeParam)
      const validChains = availableTokenChains.filter((chain) => chain !== targetOppositeChain)

      // Prefer the current chain if it's valid for a smoother transition
      // Otherwise, fallback to a default chain if available
      // As a last resort, return the first valid chain
      return currentChain && validChains.includes(currentChain)
        ? currentChain
        : validChains.find((chain) => chain === initialState[param] || chain === initialState[oppositeParam]) ||
            validChains[0]
    },
    [],
  )

  const bridgeNavigate = useCallback(
    (to: To, options?: NavigateOptions): void => {
      if (!config) {
        throw new Error('Config is not loaded yet')
      }

      // Getting the search params from the target URL
      const targetSearchParams = new URLSearchParams(typeof to === 'string' ? to.split('?')[1] : to?.search || '')

      const selectedToken = targetSearchParams.get('token') || currentSearchParams.get('token') || initialState.token
      const availableTokenChains = getAvailableChainsPerToken(selectedToken)

      // Check if target URL has missing any of the bridge query params and add them from the current URL, initialState or availableTokenChains
      BRIDGE_QUERY_PARAMS.forEach((param) => {
        if (!targetSearchParams.has(param)) {
          const fallbackValue = (() => {
            if (param === 'chainIn' || param === 'chainOut') {
              return getFallbackChain(param, currentSearchParams, targetSearchParams, availableTokenChains)
            }
            return currentSearchParams.get(param) ?? String(initialState[param])
          })()

          if (fallbackValue) {
            targetSearchParams.set(param, fallbackValue)
          }
        }
      })

      log('[bridgeNavigate]', {
        to,
        targetSearchParams: targetSearchParams.toString(),
        selectedToken,
        availableTokenChains,
      })

      if (isMobile) {
        dispatch(setActiveMobileView(BridgeLayoutMobileView.bridge))
      }

      navigate(
        {
          pathname: publicRoutes.bridge,
          search: targetSearchParams.toString(),
        },
        options,
      )
      syncStateFromUrl(dispatch, config)
    },
    [config, currentSearchParams, getAvailableChainsPerToken, navigate, dispatch, getFallbackChain, isMobile],
  )

  useEffect(() => {
    const updateSearchParams = () => {
      setUrlSearchParams(new URLSearchParams(window.location.search))
    }
    // updateUrlFromState does not trigger a re-render, so url is updated but search params are not
    // this is a workaround to update the search params from a custom event triggered by updateUrlFromState
    window.addEventListener('urlUpdated', updateSearchParams)

    return () => {
      window.removeEventListener('urlUpdated', updateSearchParams)
    }
  }, [setUrlSearchParams])

  return bridgeNavigate
}
