import { useEffect } from 'react'
import { useAppSelector } from '../../hooks'
import { selectIsWidget } from '../../store/selectors/portal.selectors'

export const useObserveWCModal = () => {
  const isWidget = useAppSelector(selectIsWidget)

  useEffect(() => {
    if (!isWidget) {
      return
    }
    const observer = new MutationObserver(() => {
      const modals = document.getElementsByTagName('wcm-modal')
      if (modals.length > 0) {
        const modal = modals[0]
        if (modal?.shadowRoot) {
          const style = document.createElement('style')
          style.textContent = `
            #wcm-modal { 
              bottom: auto !important;
              border-radius: 16px;
            }
          `

          if (!modal.shadowRoot.querySelector('style')) {
            modal.shadowRoot.appendChild(style)
          }

          observer.disconnect()
        }
      }
    })
    observer.observe(document, { childList: true, subtree: true })

    return () => observer.disconnect() // Cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only once
  }, [])
}
