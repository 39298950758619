import { getTonProvider } from '../../providers/tonProvider'
import { getJettonWalletAddress } from './getJettonWalletAddress'
import type { BridgeContractCallProps } from '../../../../pages/Bridge/services/bridge.contract'

export const handleTonDeposit = async ({
  commitmentId,
  tokenAddress,
  bridgeContractAddress,
  secondaryWalletAddress,
  amountWithDecimals,
  callback,
}: BridgeContractCallProps) => {
  const { beginCell, toNano, Address, Cell } = await import('@ton/ton')
  const { walletProvider, publicProvider } = getTonProvider()

  if (!walletProvider || !publicProvider || !secondaryWalletAddress) {
    throw new Error('Please connect your TON wallet first.')
  }

  const jettonWalletContract = await getJettonWalletAddress(
    Address.parse(secondaryWalletAddress),
    Address.parse(tokenAddress),
  )
  const sourceAddress = Address.parse(secondaryWalletAddress)
  const destinationAddress = Address.parse(bridgeContractAddress)

  const forwardPayload = beginCell()
    .storeUint(BigInt(`0x${commitmentId}`), 96)
    .endCell()

  const forwardAmount = 0.02

  const body = beginCell()
    .storeUint(0xf8a7ea5, 32) // opcode for jetton transfer
    .storeUint(0, 64) // query id
    .storeCoins(BigInt(amountWithDecimals)) // jetton amount, amount * 10^9
    .storeAddress(destinationAddress) // TON wallet destination address
    .storeAddress(sourceAddress) // response excess destination
    .storeBit(0) // no custom payload
    .storeCoins(toNano(forwardAmount)) // forward amount (if >0, will send notification message)
    .storeBit(1) // we store forwardPayload as a reference
    .storeRef(forwardPayload)
    .endCell()

  const { boc } = await walletProvider.sendTransaction({
    validUntil: Math.floor(Date.now() / 1000) + 360,
    from: secondaryWalletAddress,
    messages: [
      {
        address: jettonWalletContract.toString(),
        amount: toNano(0.1).toString(),
        payload: body.toBoc().toString('base64'),
      },
    ],
  })
  const cell = Cell.fromBase64(boc)
  const buffer = cell.hash()
  const depositTxHash = `${buffer.toString('base64')}`

  callback?.(depositTxHash, commitmentId)

  return { transactionHash: depositTxHash }
}
