import styled from 'styled-components'

export const BridgeConnectedWalletStyled = styled.div`
  grid-area: badge;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`

export const BridgeConnectedDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .rhino-icon {
    cursor: pointer !important;
  }
`

export const ConnectedWalletDot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 100%;

  background: ${({ theme }) => theme.successMain};
`
