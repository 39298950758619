import { FlexContainer } from '../../common/Wrappers/Containers'
import { NonEVMConnected } from './NonEVMConnected'
import { EVMConnected } from './EVMConnected'

export const ConnectedWallets = () => (
  <FlexContainer $gap="16px">
    <EVMConnected />
    <NonEVMConnected />
  </FlexContainer>
)
