import { useState } from 'react'
import { TabBar, Wrapper } from './MobileNavigation.styled'
import { MobileSupportChat } from './components/MobileSupportChat'
import { MobileNavigationMenu } from './components/MobileNavigationMenu/MobileNavigationMenu'
import { useMobileNavigationTabs } from './hooks/useMobileNavigationTabs'
import { MobileNavigationTab } from './components/MobileNavigationTab/MobileNavigationTab'
import { MobileMenu } from '../MobileMenu/MobileMenu'
import RhinoWithText from '../../assets/icons/rhino-with-text.svg?react'
import type { MobileNavigationMenuControls } from './types/MobileNavigation.types'

export const MobileNavigation = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showChat, setShowChat] = useState(false)

  const mobileNavigationTabs = useMobileNavigationTabs({ setShowMenu })

  const mobileNavigationMenuControls: MobileNavigationMenuControls = {
    closeMenu: () => setShowMenu(false),
    showChat: () => setShowChat(true),
  }

  return (
    <>
      <Wrapper>
        <TabBar>
          {mobileNavigationTabs.map((data) => (
            <MobileNavigationTab key={data.id} data={data} />
          ))}
        </TabBar>

        <MobileSupportChat showChat={showChat} setShowChat={setShowChat} />
      </Wrapper>

      <MobileMenu
        show={showMenu}
        headerCustomComponent={<RhinoWithText />}
        handleClose={mobileNavigationMenuControls.closeMenu}
      >
        <MobileNavigationMenu controls={mobileNavigationMenuControls} />
      </MobileMenu>
    </>
  )
}
