import styled from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

export const BridgeTabsContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 16px;
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  max-width: 400px;
  width: 400px;
  min-width: 400px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadow};

  @media ${respondBelow('xxs')} {
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    min-width: auto;
  }
`

export const BridgePortfolioInputWrapper = styled.div`
  width: 100%;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.secondary20};
  border-radius: 6px;
  margin-bottom: 16px;
  .rhino-icon {
    position: absolute;
    left: 8px;
    top: 8px;
  }
`

export const BridgePortfolioInput = styled.input`
  border: none;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  padding-left: 32px;

  &::placeholder {
    color: ${({ theme }) => theme.textSecondary};
  }
`

export const BridgeTabs = styled(FlexContainer)`
  height: 38px;
`

export const BridgeTab = styled.button<{ $active: boolean; $clickable: boolean }>`
  position: relative;
  border-radius: 8px;
  color: ${(props) => props.theme.secondary};
  text-align: center;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  transition: all 0.3s ease-out;
  background: ${({ theme }) => theme.elevationL1};
  border: none;
  outline: none;
  padding: 0;

  ${({ $active }) => ($active ? '' : '')}

  &:hover {
  }

  &:active {
  }

  &:focus {
  }
`

export const HistoryCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-weight: 600;
  padding: 2px 6px;
  background: ${({ theme }) => theme.primaryMain};
  color: ${({ theme }) => theme.textSecondary};
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 13px;
  line-height: 16px;
`
