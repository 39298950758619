import { showModal } from '../../../../../store/actions/modalActions/showModal'
import { modalKeys } from '../../../../../constants/modalKeys'
import { translate } from '../../../../../intl/i18n'
import { useAppSelector } from '../../../../../hooks'
import { selectAddress } from '../../../../../store/selectors/user.selectors'
import { ConnectWrapper, ConnectButton } from './MobileNavigationMenuWallet.styled'
import { useDispatch } from 'react-redux'
import type { MobileNavigationMenuControls } from '../../../types/MobileNavigation.types'
import { MobileMenuSectionConnectedEvmWallet } from '../../../../MobileMenu/components/MobileMenuSectionConnectedEvmWallet'

type Props = {
  controls: MobileNavigationMenuControls
}

export const MobileNavigationMenuWallet = ({ controls: { closeMenu } }: Props) => {
  const dispatch = useDispatch()
  const walletAddress = useAppSelector(selectAddress)

  if (!walletAddress) {
    return (
      <ConnectWrapper>
        <ConnectButton
          id="mobile-navigation-wallet-connect"
          fullWidth
          onClick={() => {
            closeMenu()
            showModal(dispatch)(modalKeys.connectWallet)
          }}
        >
          {translate('onboarding.connect_wallet')}
        </ConnectButton>
      </ConnectWrapper>
    )
  }

  return <MobileMenuSectionConnectedEvmWallet />
}
