import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectBridge, switchChains, type BridgeState } from '../slices/bridge.slice'
import type { BridgeFormValues } from '../types/bridge-widget.types'

export enum SelectableActionKeys {
  ChainIn = 'ChainIn',
  ChainOut = 'ChainOut',
  Token = 'Token',
}

export enum InputActionKeys {
  Amount = 'Amount',
  AmountOut = 'AmountOut',
  Recipient = 'Recipient',
}

export enum ToggleableActionKeys {
  IsOtherAddress = 'IsOtherAddress',
  GasBoostEnabled = 'GasBoostEnabled',
}

type UseBridgeStateInput = {
  setFieldValue: <K extends keyof BridgeFormValues>(field: K, value: BridgeFormValues[K]) => void
}

type UseBridgeStateOutput = {
  bridgeState: BridgeState
  handleSelectChange: (field: SelectableActionKeys, chain: string) => void
  handleInputChange: (field: InputActionKeys, amount: string) => void
  handleToggleChange: (field: ToggleableActionKeys, value: boolean) => void
  handleSwitchChains: () => void
}

const mapEnumToBridgeField: Record<
  SelectableActionKeys | InputActionKeys | ToggleableActionKeys,
  keyof BridgeFormValues
> = {
  [SelectableActionKeys.ChainIn]: 'chainIn',
  [SelectableActionKeys.ChainOut]: 'chainOut',
  [SelectableActionKeys.Token]: 'token',
  [InputActionKeys.Amount]: 'amount',
  [InputActionKeys.AmountOut]: 'amountOut',
  [InputActionKeys.Recipient]: 'recipient',
  [ToggleableActionKeys.IsOtherAddress]: 'isOtherAddress',
  [ToggleableActionKeys.GasBoostEnabled]: 'gasBoostEnabled',
}

const getMappedField = <T extends SelectableActionKeys | InputActionKeys | ToggleableActionKeys>(
  key: T,
): keyof BridgeFormValues => mapEnumToBridgeField[key]

export const useBridgeState = ({ setFieldValue }: UseBridgeStateInput): UseBridgeStateOutput => {
  const dispatch = useAppDispatch()
  const bridgeState = useAppSelector(selectBridge)

  const handleSelectChange = (field: SelectableActionKeys, chain: string) => {
    const fieldKey = getMappedField(field)
    setFieldValue(fieldKey, chain)
    dispatch({ type: `bridge/set${field}`, payload: chain })
  }

  const handleInputChange = (field: InputActionKeys, amount: string) => {
    const fieldKey = getMappedField(field)
    setFieldValue(fieldKey, amount)
    dispatch({ type: `bridge/set${field}`, payload: amount })
  }

  const handleToggleChange = (field: ToggleableActionKeys, value: boolean) => {
    const fieldKey = getMappedField(field)
    setFieldValue(fieldKey, value)
    dispatch({ type: `bridge/set${field}`, payload: value })
  }

  const handleSwitchChains = () => {
    setFieldValue('chainIn', bridgeState.chainOut)
    setFieldValue('chainOut', bridgeState.chainIn)
    dispatch(switchChains())
  }

  return { bridgeState, handleSelectChange, handleInputChange, handleToggleChange, handleSwitchChains }
}
