import { type FC, type PropsWithChildren } from 'react'
import {
  MOBILE_POPUP_FADE_TRANSITION_LENGTH,
  MOBILE_POPUP_SLIDE_IN_TRANSITION_LENGTH,
  MOBILE_POPUP_SLIDE_OUT_TRANSITION_LENGTH,
} from './constants/MobilePopup.constants'
import { zIndices } from '../../constants/zIndex'
import { OpacitySlideTransition } from '../OpacitySlideTransition/OpacitySlideTransition'
import { SlideTransitionType } from '../OpacitySlideTransition/types/OpacitySlideTransition.types'
import { MobilePopupContent } from './Components/MobilePopupContent'

type Props = {
  show: boolean
  handleClose: () => void
  headerText: string
  alwaysOpen?: true
}

export const MobilePopup: FC<PropsWithChildren<Props>> = ({
  show,
  headerText,
  handleClose,
  children,
  alwaysOpen = false,
}) => (
  <OpacitySlideTransition
    show={show}
    timeOutOpacity={MOBILE_POPUP_FADE_TRANSITION_LENGTH}
    timeOutSlideEnter={MOBILE_POPUP_SLIDE_IN_TRANSITION_LENGTH}
    timeOutSlideExit={MOBILE_POPUP_SLIDE_OUT_TRANSITION_LENGTH}
    zIndex={zIndices.mobilePopupOpen}
    showBackground={false}
    slideType={SlideTransitionType.FROM_BOTTOM}
  >
    <MobilePopupContent handleClose={handleClose} headerText={headerText} alwaysOpen={alwaysOpen}>
      {children}
    </MobilePopupContent>
  </OpacitySlideTransition>
)
