import { useState } from 'react'
import { modalKeys } from '../constants/modalKeys'
import { useAppSelector } from '../hooks'
import { WhyTakesSoLongModal } from '../pages/Bridge/components/bridge-states/PendingBridgeState/WhyTakesSoLongModal'
import { ExploreChainProjectsModal } from '../pages/Bridge/components/bridge-states/SuccessBridgeState/ExploreChainProjectsModal'
import { RecentBridgeTxModal } from '../pages/Bridge/components/RecentBridgeTransactions/RecentBridgeTxModal'
import { ContractDeployModal } from '../pages/Chain/components/ContractDeployModal/ContractDeployModal'
import { ContractDetailsModal } from '../pages/Chain/components/ContractDetailsModal'
import { ContractGMModal } from '../pages/Chain/components/ContractGMModal/ContractGMModal'
import { ContractShareModal } from '../pages/Chain/components/ContractShareModal'
import { NFTMintProgressModal } from '../pages/Chain/components/NFTMintModal'
import { ViewNFTModal } from '../pages/Chain/components/ViewNFTModal'
import { NationActiveMissionModal } from '../pages/Nation/components/NationContent/NationActiveMissionModal'
import { NationCompletedMissionModal } from '../pages/Nation/components/NationContent/NationCompletedMissionModal'
import { NationOnboardingModal } from '../pages/Nation/components/NationContent/NationOnboardingModal/NationOnboardingModal'
import { NationActivePerkModal } from '../pages/Nation/components/NationContent/NationPerksModals/NationActivePerkModal/NationActivePerkModal'
import { NationClaimedPerkModal } from '../pages/Nation/components/NationContent/NationPerksModals/NationClaimedPerkModal'
import { selectModal } from '../store/slices/modal.slice'
import { CookiesPopup } from './CookiesPopup'
import { AuthenticationModal } from './Modals/AuthenticationModal/AuthenticationModal'
import { ConnectToParadexModal } from './Modals/ConnectToParadexModal/ConnectToParadexModal'
import { ConnectWalletModal } from './Modals/ConnectWalletModal'
import { NewUserEmailSubscribe } from './Modals/NewUserEmailSubscribe'
import { NonEvmReconnectModal } from './Modals/NonEvmReconnectModal'
import { RequireChain } from './RequireChain/RequireChain'
import { SpendingCapModal } from './Modals/SpendingCapModal'
import { WalletNotInstalledModal } from './Modals/WalletNotInstalledModal'
import { selectIsWidget } from '../store/selectors/portal.selectors'
import { useIsMobile } from '../hooks/useIsMobile'
import { MobileMenuWalletConnect } from './MobileMenuWalletConnect/MobileMenuWalletConnect'
import { MobileMenuConnectedWallets } from './MobileMenuConnectedWallets/MobileMenuConnectedWallets'
import { RecentBridgeTxMobilePopup } from '../pages/Bridge/components/RecentBridgeTransactions/RecentBridgeTxMobilePopup'

export const GlobalModals = () => {
  const isWidget = useAppSelector(selectIsWidget)
  const { isMobile } = useIsMobile()
  const [showCookiePopup, setShowCookiePopup] = useState(
    localStorage.getItem('cookies-confirmed') === null && !isWidget,
  )
  const modal = useAppSelector(selectModal)

  const toggleCookiePopup = () => {
    setShowCookiePopup(!showCookiePopup)
  }

  return (
    <>
      <RequireChain />
      {/* Modal shown */}
      <AuthenticationModal />
      {modal.modalShown === modalKeys.noWallet && <WalletNotInstalledModal />}
      {modal.modalShown === modalKeys.newUserEmail && <NewUserEmailSubscribe />}
      {modal.modalShown === modalKeys.recentBridgeTx &&
        (isMobile && !isWidget ? (
          <RecentBridgeTxMobilePopup {...modal.modalPayload} />
        ) : (
          <RecentBridgeTxModal {...modal.modalPayload} />
        ))}
      {modal.modalShown === modalKeys.nftMint && <NFTMintProgressModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.viewNftMinted && <ViewNFTModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractShare && <ContractShareModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractDeploy && <ContractDeployModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractDetails && <ContractDetailsModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.contractGM && <ContractGMModal {...modal.modalPayload} />}
      {/* TODO DOPE-424 - Add campaigns */}
      {/* {modal.modalShown === modalKeys.claimMilestoneReward && <MilestoneCampaignClaimModal {...modal.modalPayload} />} */}
      {modal.modalShown === modalKeys.connectWallet &&
        (isMobile && !isWidget ? <MobileMenuWalletConnect /> : <ConnectWalletModal />)}
      {modal.modalShown === modalKeys.connectedWalletsMobile && isMobile && !isWidget && <MobileMenuConnectedWallets />}
      {modal.modalShown === modalKeys.connectToParadex && <ConnectToParadexModal />}
      {modal.modalShown === modalKeys.reconnectModal && <NonEvmReconnectModal />}
      {modal.modalShown === modalKeys.spendingCap && <SpendingCapModal />}
      {modal.modalShown === modalKeys.nationOnboarding && <NationOnboardingModal />}
      {modal.modalShown === modalKeys.nationActiveMission && <NationActiveMissionModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.nationCompletedMission && <NationCompletedMissionModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.nationActivePerk && <NationActivePerkModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.nationClaimedPerk && <NationClaimedPerkModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.exploreChainProjects && <ExploreChainProjectsModal {...modal.modalPayload} />}
      {modal.modalShown === modalKeys.whyItTakesSoLong && <WhyTakesSoLongModal />}
      {showCookiePopup && modal.modalShown !== modalKeys.connectWallet && (
        <CookiesPopup toggleCookiePopup={toggleCookiePopup} />
      )}
    </>
  )
}
