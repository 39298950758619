import { Spacing, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { ConnectSectionText, WalletsListContainer } from './ConnectWallet.styled'
import { useState } from 'react'
import { useWallet } from '../../hooks/useWallet'
import { ConnectEip963WalletOptions } from './ConnectEip963WalletOptions'
import { translate } from '../../intl/i18n'
import { ConnectOthersWalletOptions } from './ConnectOthersWalletOptions'

export const ConnectWallet = () => {
  const [error, setError] = useState<string>('')
  const wallet = useWallet()

  return (
    <div>
      <WalletsListContainer>
        {wallet.eip6963Providers.length > 0 && (
          <ConnectSectionText size={TextSize.XS} color="textSecondary">
            {translate('onboarding.detected')}
          </ConnectSectionText>
        )}
        <ConnectEip963WalletOptions />

        {wallet.eip6963Providers.length > 0 && (
          <ConnectSectionText size={TextSize.XS} color="textSecondary">
            {translate('onboarding.others')}
          </ConnectSectionText>
        )}
        <ConnectOthersWalletOptions onErrorCallback={(message: string) => setError(message)} />
      </WalletsListContainer>

      {(wallet.connectionError || error) && (
        <>
          <Spacing size="16" />
          <Text size={TextSize.XS} color="errorMain">
            {wallet.connectionError || error}
          </Text>
        </>
      )}
    </div>
  )
}
