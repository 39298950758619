/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment -- starknet lib issue */
import { getPublicStarknetProvider, getStarknetProvider } from '../../providers/starknetProvider'
import type { BridgeContractCallProps } from '../../../../pages/Bridge/services/bridge.contract'

export const handleStarknetDeposit = async ({
  bridgeContractAddress,
  tokenAddress,
  commitmentId,
  amountWithDecimals,
  callback,
}: BridgeContractCallProps) => {
  const { CallData, cairo } = await import('starknet')
  const depositAmount = cairo.uint256(amountWithDecimals)
  const { account } = getStarknetProvider()
  const publicProvider = getPublicStarknetProvider()
  const id = `0x${commitmentId}`
  const multiCall = await account.execute([
    {
      contractAddress: tokenAddress,
      entrypoint: 'approve',
      calldata: CallData.compile({
        spender: bridgeContractAddress,
        amount: depositAmount,
      }),
    },
    {
      contractAddress: bridgeContractAddress,
      entrypoint: 'deposit_with_id',
      calldata: CallData.compile({
        token: tokenAddress,
        amount: depositAmount,
        commitment_id: id,
      }),
    },
  ])

  callback?.(multiCall.transaction_hash, commitmentId)

  const result = await publicProvider.waitForTransaction(multiCall.transaction_hash)

  if ('revert_reason' in result && 'execution_status' in result) {
    if (result.execution_status === 'REVERTED') {
      throw new Error(result.revert_reason)
    }
  }

  return { transactionHash: multiCall.transaction_hash }
}
