import type { ReactNode } from 'react'
import type { MobileNavigationMenuControls } from '../../../types/MobileNavigation.types'
import { MobileNavigationMenuLinkContent } from './MobileNavigationMenuLinkContent'
import { ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { MobileNavigationMenuButton, MobileNavigationMenuButtonLink } from './MobileNavigationMenuSections.styled'
import { useMobileMenuCloseOnExternalAction } from '../../../../MobileMenu/hooks/useMobileMenuCloseOnExternalAction'

type Props = {
  id: string
  title: string
  type: 'to' | 'action'
  controls: MobileNavigationMenuControls
  to?: string | undefined
  icon?: ReactNode | string
  showChevron?: boolean
  handleAction?: (() => void) | undefined
}

export const MobileNavigationMenuLink = ({
  type,
  id,
  to,
  icon,
  title,
  handleAction = () => {},
  controls,
  showChevron = false,
}: Props) => {
  const handleActionCallback = useMobileMenuCloseOnExternalAction({
    close: controls.closeMenu,
    externalAction: handleAction,
  })

  if (type === 'to' && to) {
    return (
      <MobileNavigationMenuButtonLink id={id} to={to} variant={ButtonVariant.ghost} onClick={controls.closeMenu}>
        <MobileNavigationMenuLinkContent icon={icon} title={title} showChevron={showChevron} />
      </MobileNavigationMenuButtonLink>
    )
  }

  if (type === 'action') {
    return (
      <MobileNavigationMenuButton id={id} onClick={handleActionCallback} variant={ButtonVariant.ghost}>
        <MobileNavigationMenuLinkContent icon={icon} title={title} showChevron={showChevron} />
      </MobileNavigationMenuButton>
    )
  }

  return null
}
