import type { FormikErrors } from 'formik'
import { isEmpty } from 'lodash-es'
import type { BridgeApiError } from '../../../store/apis/bridge.api'
import { Match } from 'effect'
import { formatWithdrawLimitReachedError } from './formatWithdrawLimitReachedError'
import { formatDepositLimitReachedError } from './formatDepositLimitReachedError'
import { translate } from '../../../intl/i18n'

export type NormalizedError = {
  overSubmitError: string | null
  buttonDisabledError?: string | null
  errorKey?: string | null
}

// WithdrawLimitReached: (error: WithdrawLimitReached) => Effect.fail(formatWithdrawLimitReachedError(error)),
// DepositLimitReached: (error: DepositLimitReached) => Effect.fail(formatDepositLimitReachedError(error)),
export const normalizeErrors = ({
  quoteError,
  formErrors,
  hasDepositAmount,
  hasRecipient,
}: {
  quoteError: BridgeApiError | undefined
  formErrors: FormikErrors<unknown>
  hasDepositAmount: boolean
  hasRecipient: boolean
}): NormalizedError => {
  if (!hasDepositAmount) {
    return {
      overSubmitError: null,
    }
  }

  if (!isEmpty(formErrors)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the keys are strings
    const keys = Object.keys(formErrors) as (keyof typeof formErrors)[]
    const firstErrorKey = keys.find((key) => formErrors[key])
    if (firstErrorKey) {
      const firstError = formErrors[firstErrorKey]
      // we don't want to show the error when recipient address is empty
      if (firstError && firstErrorKey === 'recipient' && !hasRecipient) {
        return {
          overSubmitError: null,
          buttonDisabledError: firstError,
          errorKey: firstErrorKey,
        }
      }

      if (firstError) {
        if (String(firstError).length < 38) {
          return {
            overSubmitError: null,
            buttonDisabledError: firstError,
            errorKey: firstErrorKey,
          }
        }
        return {
          overSubmitError: firstError,
          errorKey: firstErrorKey,
        }
      }
    }
  }

  return {
    overSubmitError: Match.value(quoteError).pipe(
      // Needed due to potential bug in Match.tags, leading to
      // error if value is nullish
      // https://discord.com/channels/795981131316985866/795983589644304396/1330857990076628992
      Match.when(Match.undefined, () => null),
      Match.tags({
        WithdrawLimitReached: (error) => formatWithdrawLimitReachedError(error),
        DepositLimitReached: (error) => formatDepositLimitReachedError(error),
        GasBoostGreaterThanReceiveAmount: () => translate('errors.bridge_gas_boost_balance'),
        NegativeReceiveAmount: () => translate('errors.bridge_receive_amount_0'),
      }),
      Match.orElse(() => null),
    ),
  }
}
