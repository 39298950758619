import { Button, ButtonVariant, Text, TokenIcon, type Column } from '@rhinofi/dvf-shared-ui'
import { SkeletonCard } from '../../../../components/common/Skeletons/Skeleton.styled'
import { SafeDecimal } from '../../../../utils/SafeDecimal'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import styled from 'styled-components'
import { translate } from '../../../../intl/i18n'
import { formatFloat } from '../../../../services/helperService/formatFloat'
import type { TokenWithBalance } from '../../types/bridge-widget.types'

export const getBridgePortfolioColumns = ({
  onBridgeClick,
  isBridgePending,
  isMobile,
}: {
  onBridgeClick: (args: TokenWithBalance) => void
  isBridgePending: boolean
  isMobile: boolean
}): Column<TokenWithBalance>[] => {
  return [
    {
      id: 'token',
      title: translate('helpers.name'),
      sortable: true,
      compare: (first, second) => first.token.localeCompare(second.token),
      loadingSkeleton: (
        <SkeletonWrapper $gap="8px">
          <SkeletonCard $height="32px" $width="32px" $borderRadius="64px" />
          <FlexContainer $direction="column" $gap="4px" $alignItems="start">
            <SkeletonCard $height="20px" $width="47px" $borderRadius="6px" />
            <SkeletonCard $height="16px" $width="31px" $borderRadius="6px" />
          </FlexContainer>
        </SkeletonWrapper>
      ),
      renderCell: ({ token, chainName, chain }: TokenWithBalance) => (
        <PortfolioTokenColumn>
          <div>
            <TokenIcon token={token} chain={chain} size="large" />
          </div>
          <div>
            <Text bold size={TextSize.S}>
              {token}
            </Text>
            <Text size={TextSize.XS} color="textSecondary">
              {chainName}
            </Text>
          </div>
        </PortfolioTokenColumn>
      ),
    },
    {
      id: 'balanceUsd',
      title: translate('helpers.balance'),
      titleAlign: 'end',
      sortable: true,
      compare: (first, second) => SafeDecimal(first.balanceUsd).comparedTo(SafeDecimal(second.balanceUsd)),
      loadingSkeleton: (
        <SkeletonWrapper $gap="4px" $direction="column" $alignItems="end" $justifyContent="center">
          <SkeletonCard $height="20px" $width="66px" $borderRadius="6px" />
          <SkeletonCard $height="16px" $width="84px" $borderRadius="6px" />
        </SkeletonWrapper>
      ),
      renderCell: ({ token, balanceToken, balanceUsd }: TokenWithBalance) => (
        <FlexContainer $direction="column" $alignItems="flex-end" $fullWidth $gap="2px">
          <Text bold size={TextSize.M} align={TypographyAlign.Right}>
            ${formatFloat(balanceUsd, 2)}
          </Text>
          <Text size={TextSize.XS} align={TypographyAlign.Right} noLineHeight color="textSecondary">
            {formatFloat(balanceToken, 4)} {token}
          </Text>
        </FlexContainer>
      ),
    },
    {
      id: 'chain',
      title: '',
      width: 121,
      hidden: isMobile,
      loadingSkeleton: <SkeletonCard $height="40px" $width="103px" $borderRadius="64px" />,
      renderCell: (tokenWithBalance: TokenWithBalance) => (
        <Button
          id="portfolio-bridge"
          fullWidth
          variant={ButtonVariant.secondary}
          disabled={isBridgePending}
          onClick={() => {
            onBridgeClick(tokenWithBalance)
          }}
        >
          <Text size={TextSize.XS}>{translate('bridge.bridge')}</Text>
        </Button>
      ),
    },
  ]
}

const PortfolioTokenColumn = styled.div`
  height: 62px;
  display: flex;
  align-items: center;

  & > div:first-child {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
`

const SkeletonWrapper = styled(FlexContainer)`
  height: 62px;
`
