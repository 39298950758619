import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { formatFloat } from '../../../services/helperService/formatFloat'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import type { TokenPricesState } from '../../../store/slices/types/TokenPricesState'

export const formatBridgeAmountUsd = ({
  tokenPrices,
  token,
  amount,
}: {
  tokenPrices: TokenPricesState
  token: string | undefined
  amount: string | undefined
}) => {
  if (!amount || !token) {
    return '0'
  }
  const tokenPrice = getTokenPrice(tokenPrices, token)
  return formatFloat(SafeDecimal(amount).times(tokenPrice).toString())
}
