import { useLocation } from 'react-router-dom'
import type { Dispatch, SetStateAction } from 'react'
import { translate } from '../../../intl/i18n'
import { publicRoutes } from '../../../router'
import type { MobileNavigationTabData } from '../types/MobileNavigation.types'
import { useBridgeNavigate } from '../../../hooks/bridge'
import WalletAltIcon from '../../../assets/icons/wallet.svg?react'
import ThreeDotsIcon from '../assets/three-dots.svg?react'
import ChequeIcon from '../assets/cheque.svg?react'
import BridgeMobileIcon from '../assets/bridge-mobile.svg?react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  setActiveMobileView,
  setActiveBridgeTab,
  selectBridgeLayoutActiveMobileView,
} from '../../../pages/Bridge/slices/bridgeLayout.slice'
import { BridgeLayoutMobileView, BridgeTabsStep } from '../../../pages/Bridge/types/bridge-widget.types'

type Props = {
  setShowMenu: Dispatch<SetStateAction<boolean>>
}

export const useMobileNavigationTabs = ({ setShowMenu }: Props): MobileNavigationTabData[] => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const bridgeNavigate = useBridgeNavigate()
  const activeMobileView = useAppSelector(selectBridgeLayoutActiveMobileView)
  const bridgePath = pathname.startsWith(publicRoutes.bridge)

  return [
    {
      id: 'bridge',
      title: translate('bridge.bridge'),
      icon: <BridgeMobileIcon className="mobile-navigation-link-icon" />,
      isActive: bridgePath && activeMobileView === BridgeLayoutMobileView.bridge,
      onClick: () => {
        bridgeNavigate(publicRoutes.bridge)
      },
    },
    {
      id: 'history',
      title: translate('helpers.history'),
      icon: <ChequeIcon className="mobile-navigation-link-icon" />,
      isActive: bridgePath && activeMobileView === BridgeLayoutMobileView.history,
      onClick: () => {
        bridgeNavigate(publicRoutes.bridge)
        dispatch(setActiveBridgeTab(BridgeTabsStep.history))
        dispatch(setActiveMobileView(BridgeLayoutMobileView.history))
      },
    },
    {
      id: 'portfolio',
      title: translate('portfolio.portfolio'),
      icon: <WalletAltIcon className="mobile-navigation-link-icon" />,
      isActive: bridgePath && activeMobileView === BridgeLayoutMobileView.portfolio,
      onClick: () => {
        bridgeNavigate(publicRoutes.bridge)
        dispatch(setActiveBridgeTab(BridgeTabsStep.portfolio))
        dispatch(setActiveMobileView(BridgeLayoutMobileView.portfolio))
      },
    },
    {
      id: 'more',
      title: translate('helpers.more'),
      isActive: false,
      icon: <ThreeDotsIcon className="mobile-navigation-link-icon" />,
      onClick: () => {
        setShowMenu(true)
      },
    },
  ]
}
