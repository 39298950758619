/* eslint-disable functional/no-let -- legacy */
import Decimal from 'decimal.js'
import { SafeDecimal } from '../../utils/SafeDecimal'

export const formatFloat = (
  rawNumber: string | number | Decimal,
  customPrecision?: number,
  rounding:
    | typeof Decimal.ROUND_DOWN
    | typeof Decimal.ROUND_UP
    | typeof Decimal.ROUND_HALF_DOWN = Decimal.ROUND_HALF_DOWN,
) => {
  Decimal.set({ rounding })
  // handle primitives
  let decimal: Decimal | undefined
  if (typeof rawNumber === 'number' || typeof rawNumber === 'string') {
    const number = +rawNumber
    if (rawNumber === undefined || rawNumber === null || !isFinite(number)) {
      return '0'
    }
    decimal = SafeDecimal(rawNumber)
  } else {
    decimal = rawNumber
  }
  let formatted = ''
  const precision = customPrecision ?? 5

  // Use absolute for comparisons but adjust decimal to
  // handle negative floats
  const absolute = decimal.abs()

  if (absolute.lessThan(Math.pow(10, -precision))) {
    return '0'
  } else if (absolute.lessThan(Math.pow(10, precision - 1))) {
    formatted = decimal.toSignificantDigits(precision).toString()
  } else {
    formatted = decimal.toDecimalPlaces(1).toString()
  }

  if (formatted.includes('.')) {
    formatted = formatted.replace(/0+$/, '').replace(/\.$/, '')
  }
  return formatted
}
