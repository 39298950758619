import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { parseUnits } from 'ethers'
import { DVFDepositContract__factory } from '../../../contracts/DVFDepositContract'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import type { BridgeContractCallProps } from './bridge.contract'

const log = makeLog(LogFeature.BRIDGE_WIDGET)

export const callEvmBridgeContract = async ({
  ethersSigner,
  amount,
  amountWithDecimals,
  commitmentId,
  tokenAddress,
  bridgeContractAddress,
  isNativeToken,
  callback,
}: BridgeContractCallProps) => {
  const depositContract = DVFDepositContract__factory.connect(bridgeContractAddress, ethersSigner)
  const commitmentBigInt = BigInt(`0x${commitmentId}`)

  const tx = await (isNativeToken
    ? depositContract.depositNativeWithId(commitmentBigInt, {
        value: parseUnits(bigDecimalToNumericString(amount), 'ether'),
      })
    : depositContract.depositWithId(tokenAddress, amountWithDecimals, commitmentBigInt))

  callback?.(tx.hash, commitmentId)

  log('Transaction sent:', { tx, isNativeToken })

  const receipt = await tx.wait()

  log('Transaction confirmed:', { receipt, isNativeToken })

  return receipt
}
