import type { EligibilitySchemaWithDistribution, TokenDistributionSchema } from '@rhinofi/token-distribution-api-spec'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { useLazyHasClaimedTokenDistribution } from '../../TokenDistribution/hooks/useHasClaimedTokenDistribution'
import { useLazyTokenDistributionGetQuery } from '../../TokenDistribution/services/tokenDistribution.api'
import { useAppSelector } from '../../../hooks'
import { selectAddress } from '../../../store/selectors/user.selectors'

export type ClaimStatus = 'claimed' | 'not-claimed' | 'error'
export type UpdateDistribution = (params: {
  distribution: TokenDistributionSchema
  claimStatus?: ClaimStatus
  claimError?: string
  txHash?: string
}) => void

export const useMultipleDistributions = (eligibilities: readonly EligibilitySchemaWithDistribution[] | undefined) => {
  const address = useAppSelector(selectAddress) || ''
  const [distributionsFetchDone, setDistributionsFetchDone] = useState(true)
  const [claimsFetchDone, setClaimsFetchDone] = useState(true)
  const [fetchedForAddress, setFetchedForAddress] = useState('')

  const [distributions, setDistributions] = useState<
    {
      distribution: TokenDistributionSchema | undefined
      eligibility: EligibilitySchemaWithDistribution
      claimStatus?: ClaimStatus
      claimError?: string
      txHash?: string
    }[]
  >([])

  useEffect(() => {
    if (address) {
      setDistributionsFetchDone(false)
      setClaimsFetchDone(false)
      setFetchedForAddress(address)
    }

    if (eligibilities && eligibilities.length > 0) {
      setDistributionsFetchDone(false)
      setClaimsFetchDone(false)
    } else {
      setDistributionsFetchDone(true)
      setClaimsFetchDone(true)
    }

    setDistributions([])
  }, [eligibilities, address])

  const updateDistribution: UpdateDistribution = useCallback(
    ({ distribution, claimStatus, claimError, txHash }) => {
      setDistributions(
        distributions.map((item) => {
          if (
            item.distribution?.chain === distribution.chain &&
            item.distribution?.distributionId === distribution.distributionId
          ) {
            return {
              ...item,
              ...(claimStatus !== undefined ? { claimStatus: claimStatus } : {}),
              ...(claimError !== undefined ? { claimError } : {}),
              ...(txHash !== undefined ? { txHash } : {}),
            }
          }
          return item
        }),
      )
    },
    [distributions],
  )
  const [trigger] = useLazyTokenDistributionGetQuery()
  const lazyContractCheck = useLazyHasClaimedTokenDistribution()

  useEffect(() => {
    const fetchDistributions = async () => {
      const results = await Promise.all(
        eligibilities?.map(async (item) => {
          const { data } = await trigger({
            path: {
              chain: item.chain,
              distributionId: item.distributionId,
            },
          })
          return { distribution: data, eligibility: item }
        }) ?? [],
      )
      setDistributionsFetchDone(true)
      setDistributions(results)
    }

    if (!distributionsFetchDone && eligibilities?.length) {
      void fetchDistributions()
    }
  }, [distributionsFetchDone, eligibilities, trigger])

  useEffect(() => {
    const checkClaimed = async () => {
      const results = await Promise.all(
        distributions.map(async (item) => {
          if (!item.distribution) {
            return { ...item, claimStatus: 'error' as const }
          }
          if (item.claimStatus !== undefined) {
            return item
          }

          const { contractAddress, chain, distributionId } = item.distribution
          const { user } = item.eligibility

          const claimStatus: ClaimStatus = await lazyContractCheck({ contractAddress, chain, distributionId, user })
            .then((result) => (result ? ('claimed' as const) : ('not-claimed' as const)))
            .catch(() => 'error' as const)
          return { ...item, claimStatus }
        }),
      )

      setDistributions(results)
      setClaimsFetchDone(true)
    }

    if (distributionsFetchDone && !claimsFetchDone && distributions.length) {
      void checkClaimed()
    }
  }, [claimsFetchDone, distributions, distributionsFetchDone, lazyContractCheck])

  const currentDistributions = useMemo(
    () =>
      distributions.filter(({ distribution }) => {
        const now = new Date().getTime()
        if (distribution) {
          if (distribution.claimableFrom.getTime() < now && distribution.claimableUntil.getTime() > now) {
            return true
          }
        }
        return false
      }),
    [distributions],
  )

  return {
    distributions: currentDistributions,
    updateDistribution,
    lazyContractCheck,
    distributionsFetchDone,
    claimsFetchDone,
    fetchedForAddress,
  }
}
