import { Icon, IconSizes, Text } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { type ReactNode, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled, { css } from 'styled-components'
import { useHoverOrClick } from '@rhinofi/dvf-shared-ui/lib/hooks/useHoverOrClick'
import { clampedGentleFigma } from '../../../constants/springs'
import { AnimatedErrorType } from '../types/animatedError.types'

type ErrorProps = {
  text: string | ReactNode
  type?: AnimatedErrorType
  forceOpen?: boolean
  onlyOnClick?: boolean
}

export const AnimatedError = ({
  text,
  type = AnimatedErrorType.error,
  forceOpen = false,
  onlyOnClick = false,
}: ErrorProps) => {
  const hoverRef = useRef<HTMLDivElement>(null)

  const { clickProps, hoverProps, isOpen, isHovering } = useHoverOrClick({
    handleClicks: true,
    elementToClose: hoverRef,
  })
  const [isFullyCollapsed, setIsFullyCollapsed] = useState(true)

  const styles = useSpring({
    config: clampedGentleFigma,
    maxHeight: isOpen || (isHovering && !onlyOnClick) || forceOpen ? '300px' : '48px',
    onStart: (result: { value: { maxHeight: string } }) => {
      const height = result.value.maxHeight
      if (height !== '48px') {
        setIsFullyCollapsed(false)
      }
    },
    onRest: (result: { value: { maxHeight: string } }) => {
      const height = result.value.maxHeight
      if (height === '48px') {
        setIsFullyCollapsed(true)
      } else if (height === '300px') {
        setIsFullyCollapsed(false)
      }
    },
  })

  return (
    <ErrorPosition>
      <ErrorWrapper
        style={styles}
        id="error-wrapper"
        ref={hoverRef}
        {...clickProps}
        {...hoverProps}
        $type={type}
        $forceOpen={forceOpen}
        $onlyOnClick={onlyOnClick}
      >
        <ErrorIcon $type={type} $forceOpen={forceOpen}>
          <Icon id="exclamation-square" size={IconSizes.Small} />
        </ErrorIcon>
        <ErrorText>
          <ElipsisText
            $isExpanded={isOpen || (isHovering && !onlyOnClick) || !isFullyCollapsed || forceOpen}
            size={TextSize.XS}
          >
            {text}
          </ElipsisText>
        </ErrorText>
      </ErrorWrapper>
    </ErrorPosition>
  )
}

const ErrorWrapper = styled(animated.div)<{
  $type: AnimatedErrorType
  $forceOpen?: boolean
  $onlyOnClick?: boolean
}>`
  position: ${({ $forceOpen }) => ($forceOpen ? 'relative' : 'absolute')};
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  border: solid 1px ${({ theme, $type }) => ($type === AnimatedErrorType.error ? theme.errorLight : theme.warningLight)};

  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 48px;
  .bi {
    color: ${({ theme }) => theme.redBackground};
  }

  overflow: hidden;
  background: ${({ theme }) => theme.cardBackground};
  cursor: ${({ $onlyOnClick }) => ($onlyOnClick ? 'pointer' : 'default')};
`

const ErrorIcon = styled.div<{
  $type: AnimatedErrorType
  $forceOpen?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: ${({ $forceOpen }) => ($forceOpen ? 'center' : 'flex-end')};
  justify-content: center;
  padding-bottom: ${({ $forceOpen }) => ($forceOpen ? '0' : '14px')};
  min-width: 48px;
  background: ${({ theme, $type }) => ($type === AnimatedErrorType.error ? theme.errorLight : theme.warningLight)};

  .bi {
    color: ${({ theme, $type }) => ($type === AnimatedErrorType.error ? theme.primary : theme.primary)};
  }
`

const ErrorText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 4px 4px 8px;
`

const ErrorPosition = styled.div`
  position: relative;
  min-height: 48px;
  width: 100%;
`

const ElipsisText = styled(Text)<{ $isExpanded?: boolean }>`
  ${({ $isExpanded }) =>
    $isExpanded
      ? ''
      : css`
          // should fallback to simple overflow hidden + max height when not supported
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-height: 48px;
        `}
`
