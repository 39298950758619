import type { PayloadAction } from '@reduxjs/toolkit'
import { isAnyOf } from '@reduxjs/toolkit'
import { createSlice, prepareAutoBatched } from '@reduxjs/toolkit'
import type { PortalReducerState, RequiredChain, ThemeName } from './types/PortalReducerState'
import { getDefaultBridgeRoute } from '../../pages/Bridge/helpers/getDefaultBridgeRoute'
import type { MulticallBalances } from '../../services/ethereum/multicall'
import { mergeBlockchainBalanceState } from '../../services/ethereum/mergeBlockchainBalanceState'
import { setWallet } from './user.slice'

const urlParams = new URLSearchParams(window.location.search)
const isWidget = !!urlParams.get('widget')
const urlTheme = urlParams.get('theme')
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- user passed theme
const defaultTheme: ThemeName = (urlTheme as ThemeName) || 'dune'

export const getInitialState = (): PortalReducerState => ({
  // Meta data
  selectedTheme: defaultTheme,
  requiredChain: null,
  isWidget: isWidget,

  // API / Blockchain Data
  blockchainBalance: {},
  failedChains: {},
  hasBalanceForTransaction: {},
  defaultRoute: getDefaultBridgeRoute(),
})

export const portalSlice = createSlice({
  name: 'portal',
  initialState: getInitialState(),
  extraReducers: (builder) => {
    const cleanState = {
      blockchainBalance: {},
      hasBalanceForTransaction: {},
      failedChains: {},
    }
    const reset = (state: PortalReducerState) => {
      return {
        ...state,
        ...cleanState,
      }
    }
    builder.addMatcher(isAnyOf(setWallet.match), reset)
  },
  reducers: {
    updateBlockchainBalance: {
      reducer: (
        state,
        {
          payload: { blockchainBalance, failedChains, hasBalanceForTransaction },
        }: PayloadAction<{
          blockchainBalance: MulticallBalances
          failedChains: Record<string, boolean>
          hasBalanceForTransaction: Record<string, boolean>
        }>,
      ) => {
        return {
          ...state,
          blockchainBalance: mergeBlockchainBalanceState({
            updatedBalances: blockchainBalance,
            currentBalances: state.blockchainBalance,
          }),
          hasBalanceForTransaction: {
            ...state.hasBalanceForTransaction,
            ...hasBalanceForTransaction,
          },
          failedChains: {
            ...state.failedChains,
            ...failedChains,
          },
        }
      },
      prepare: prepareAutoBatched<any>(),
    },
    toggleTheme: (state, { payload: { theme } }: PayloadAction<{ theme: ThemeName }>) => {
      localStorage.setItem('theme', theme)

      return {
        ...state,
        selectedTheme: theme,
      }
    },
    setNextBatchTime: (
      state,
      action: PayloadAction<{
        batchTime: {
          estimatedTime: string | number | null
          averageTime: string | number | null
          finalisedBatchPendingConfirmation?: boolean | undefined
          origAverageTime?: number | null | undefined
        }
      }>,
    ) => {
      return {
        ...state,
        batchTime: action.payload.batchTime,
      }
    },
    setRequiredChain: (
      state,
      {
        payload: { requiredChain },
      }: PayloadAction<{
        requiredChain: RequiredChain
      }>,
    ) => {
      return {
        ...state,
        requiredChain,
      }
    },
  },
})

export const { toggleTheme, setNextBatchTime, setRequiredChain, updateBlockchainBalance } = portalSlice.actions
