import type { ReactNode } from 'react'
import { FlexContainer } from '../../../../common/Wrappers/Containers'
import Chevron from '../../../../../assets/icons/chevron.svg?react'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'

type Props = {
  title: string
  icon?: string | ReactNode
  showChevron?: boolean
  chevronOpen?: boolean
}

export const MobileNavigationMenuLinkContent = ({ icon, title, showChevron = false, chevronOpen = false }: Props) => (
  <Wrapper $justifyContent="space-between" $padding={!icon ? '0 0 0 36px' : '0'}>
    <FlexContainer $gap="12px">
      {icon ? icon : null}
      <Text bold size={TextSize.S}>
        {title}
      </Text>
    </FlexContainer>

    {showChevron && (
      <ChevronWrapper $isOpen={chevronOpen}>
        <Chevron />
      </ChevronWrapper>
    )}
  </Wrapper>
)

const Wrapper = styled(FlexContainer)`
  cursor: pointer;
  user-select: none;
`

const ChevronWrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  transition: all 150ms ease-in-out;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'none')};
`
