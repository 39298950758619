import { translate } from '../../../intl/i18n'
import { formatFloat } from '../../../services/helperService/formatFloat'

export const bridgeWidgetLabels = {
  token: () => translate('helpers.token'),
  reviewBridge: () => translate('bridge.review_bridge').toLowerCase(),
  max: () => translate('helpers.max'),
  bridgeFee: () => translate('bridge.bridge_fee'),
  gasFee: () => translate('bridge.gas_fee'),
  plusGas: () => translate('bridge.plus_gas'),
  bridgeFeeAndGas: () => translate('bridge.bridge_fee_gas'),
  youReceive: () => translate('helpers.you_receive'),
  timeTo: (network: string) => translate('bridge.time_to_network', { '%network': network }),
  originGasFee: () => translate('bridge.origin_gas_fee'),
  destinationWallet: () => translate('bridge.destination_wallet'),
  cancel: () => translate('helpers.cancel').toLowerCase(),
  bridge: () => translate('bridge.bridge'),
  bridgePoweredByStarkEx: () => translate('bridge.rhino_bridge_powered_by_starkex'),
  bridgeFunds: () => translate('bridge.bridge_funds').toLowerCase(),
  needToApproveFirst: () => translate('onboarding.you_need_to_approve_first'),
  processing: () => translate('helpers.processing'),
  enterAmount: () => translate('onboarding.enter_amount'),
  connectWallet: () => translate('onboarding.connect_wallet'),
  bridging: () => `${translate('bridge.bridging')}...`,
  pleaseApproveInYourWallet: () => translate('global.please_approve_in_wallet'),
  error: () => translate('global.error'),
  contactSupport: () => translate('swap.contact_support'),
  tryAgain: () => translate('bridge.try_again'),
  connecting: () => `${translate('helpers.connecting')}...`,
  depositSuccessful: () => translate('bridge.deposit_successful'),
  waitingForBlockConfirmations: () => translate('bridge.waiting_for_block_confirmations'),
  waitingForSignaturre: () => translate('global.signature_pending'),
  withdrawalSuccesful: () => translate('bridge.withdrawal_successful'),
  withdrawing: () => `${translate('bridge.withdrawing')}...`,
  withdrawalFailed: () => translate('bridge.withdrawal_failed'),
  transactionSigned: () => translate('swap.transaction_signed'),
  success: () => translate('helpers.success'),
  viewTransactionHistory: () => translate('global.view_transaction_history').toLowerCase(),
  bridgeAgain: () => translate('bridge.bridge_again'),
  minutesTo: () => translate('bridge.minutes_to'),
  withdrawalBelowMinimum: (feeAmount: string | number, withdrawToken: string | number) =>
    translate('bridge.amount_below_minimum', { '%amount': formatFloat(feeAmount), '%token': withdrawToken }),
  withdrawalAboveLimit: (withdrawalLimit: string | number, withdrawToken: string | number) =>
    translate('bridge.amount_above_maximum', { '%amount': formatFloat(withdrawalLimit), '%token': withdrawToken }),
  tokenHasToBeApproved: () => translate('bridge.token_has_to_be_approved'),
  errorDuringDeposit: () => translate('bridge.error_during_deposit'),
  errorDuringWaiting: () => translate('bridge.error_during_waiting'),
  errorDuringWithdrawal: () => translate('bridge.error_during_withdrawing'),
  unknownError: () => translate('errors.unknown_error'),
  withdraw: () => translate('helpers.withdraw').toLowerCase(),
  severeChainError: () => `${translate('errors.unknown_error')} ${translate('error_boundary.suggestion_text')}`,
  pleaseStayWhileBridging: () => translate('bridge.please_stay_while_bridging'),
  itCanTakeUpTo4Hours: () => translate('bridge.it_takes_up_to_other'),
  didYouKnow: () => translate('magicmoments.did_you_know'),
  showThem: () => translate('magicmoments.show_them'),
  tweetContent1: () => translate('magicmoments.tweet_content_1'),
  tweetContent2: () => translate('magicmoments.tweet_content_2'),
  unsupportedWallet: () => translate('errors.unsupported_wallet'),
}
