import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { useTheme } from 'styled-components'
import { PoweredBySection } from '../../pages/Bridge/components/BridgeWidget/BridgeWidget.styled'

export const PoweredByRhino = () => {
  const theme = useTheme()

  return (
    <PoweredBySection>
      <Text color="textSecondary" size={TextSize.XXS}>
        Powered by
      </Text>
      <img src={theme.assets.logo} alt="rhino logo" />
    </PoweredBySection>
  )
}
