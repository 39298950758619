import { WalletType } from '../../services/wallet/wallet.types'
import { WalletButton } from './WalletButton'
import { useWallet } from '../../hooks/useWallet'

export const ConnectEip963WalletOptions = () => {
  const wallet = useWallet()

  return wallet.eip6963Providers.map((provider) => (
    <WalletButton
      key={`${provider.info.name}`}
      id="reown"
      isConnecting={wallet.connectingWallet.isConnecting && provider.info.name === wallet.connectingWallet.name}
      icon={provider.info.icon}
      onClick={async () => {
        await wallet.connect({
          walletType: WalletType.eip6963,
          name: provider.info.name,
        })
      }}
      label={provider.info.name}
      isDetected
    />
  ))
}
