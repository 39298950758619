/* eslint-disable -- whatever */
const loggerFn = () => {}
loggerFn.lazy = () => {}

export default () => ({
  debug: loggerFn,
  log: loggerFn,
  warn: loggerFn,
  error: loggerFn,
  emergency: loggerFn,
})
