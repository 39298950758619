import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'
import type { BadgePropItem, NetworkType } from '@rhinofi/dvf-shared-ui'

export enum BridgeTabsStep {
  'highlights' = 'highlights',
  'portfolio' = 'portfolio',
  'history' = 'history',
}

export enum Highlights {
  Bridge = 'bridge-highlights',
  PostBridge = 'post-bridge-highlights',
}

export type BridgeFormValues = {
  amount: string
  amountOut: string
  token: string
  chainIn: string
  chainOut: string
  recipient: string
  isOtherAddress: boolean
  gasBoostEnabled: boolean
}

export type TokenWithBalance = {
  token: string
  chain: string
  chainName: string
  balanceToken: string
  balanceUsd: string
}

export type TokenWithBalanceAndAvailability = TokenWithBalance & {
  isAvailable?: boolean | undefined
}

export type BridgeCategory = {
  label: string
  value: string
}

export type ChainWithBalance = Omit<BridgeConfigEntrySchema, 'category' | 'badge'> & {
  type: NetworkType
  balance: string
  balanceUsd: string
  category?: BridgeCategory | undefined
  badge?: BadgePropItem | undefined
  chain: string
  chainName: string
  isAvailable: boolean
  value?: string
  icon?: string
  isWalletConnected: boolean
  tokenBalances?: {
    token: string
    balance: string
  }[]
}

export enum BridgeConfirmationScreen {
  'bridgeToAny' = 0,
  'lessThanDollar' = 1,
}

export type BridgeConfirmationPromise = {
  type: BridgeConfirmationScreen
  resolve: (result: boolean) => boolean
}

export enum BridgeLayoutMobileView {
  'bridge' = 'bridge',
  'history' = 'history',
  'portfolio' = 'portfolio',
}

export enum BridgeLayoutVisibleTab {
  'none' = 'none',
  'history' = 'history',
}
