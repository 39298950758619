import { getTronProvider } from '../../providers/tronProvider'
import type { ApprovalContractCallProps } from '../../../../pages/Bridge/services/callApprovalContract'

export const approveTronDeposit = async ({
  tokenAddress,
  bridgeContractAddress,
  amountWithDecimals,
}: ApprovalContractCallProps) => {
  const { tronWeb } = getTronProvider()
  if (!tronWeb) {
    throw new Error('Please connect your Tron wallet first.')
  }
  const contract = await tronWeb.contract().at(tokenAddress)
  if (!('approve' in contract)) {
    return false
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- library requirement
  const approve = contract.approve as (
    address: string,
    amount: string,
  ) => { send: (options: { shouldPollResponse: boolean }) => Promise<void> }

  return approve(bridgeContractAddress, amountWithDecimals).send({
    shouldPollResponse: true,
  })
}
