import { useCallback, useEffect, useState } from 'react'
import { CampaignRewardDistribution__factory } from '../../../contracts/CampaignRewardDistribution'
import { useGetReadSigner } from '../../../services/ethereum/useGetReadSigner'

type Props = {
  contractAddress: string
  chain: string
  distributionId: number
  user?: string
}

export const useLazyHasClaimedTokenDistribution = () => {
  const getReadSigner = useGetReadSigner()

  return useCallback(
    ({ contractAddress, chain, distributionId, user }: Required<Props>) => {
      const tokenDistributionContract = CampaignRewardDistribution__factory.connect(
        contractAddress,
        getReadSigner(chain),
      )

      return tokenDistributionContract.claimed(user, distributionId)
    },
    [getReadSigner],
  )
}

export const useHasClaimedTokenDistribution = ({ contractAddress, chain, distributionId, user }: Props) => {
  const [hasClaimed, setHasClaimed] = useState(false)
  const lazyGetHasClaimed = useLazyHasClaimedTokenDistribution()

  useEffect(() => {
    if (user) {
      lazyGetHasClaimed({ contractAddress, chain, distributionId, user })
        .then((res) => setHasClaimed(res))
        .catch(console.error)
    }
  }, [contractAddress, chain, distributionId, user, lazyGetHasClaimed])

  return {
    hasClaimed,
  }
}
