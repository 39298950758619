import { useRef, useState } from 'react'
import type { NavigationLinkRoot } from '../../../../NavigationDesktop/NavigationDesktop.types'
import type { MobileNavigationMenuControls } from '../../../types/MobileNavigation.types'
import { MobileNavigationMenuLinkContent } from './MobileNavigationMenuLinkContent'
import { MobileNavigationMenuLink } from './MobileNavigationMenuLink'
import { CSSTransition } from 'react-transition-group'
import { FlexContainer } from '../../../../common/Wrappers/Containers'
import { MobileNavigationMenuButton, SubLinksWrapper } from './MobileNavigationMenuSections.styled'
import { ButtonVariant } from '@rhinofi/dvf-shared-ui'

type Props = {
  data: NavigationLinkRoot
  controls: MobileNavigationMenuControls
}

export const MobileNavigationMenuLinkRoot = ({ data, controls }: Props) => {
  const [subLinksOpen, setSubLinksOpen] = useState(false)
  const subLinksRef = useRef<HTMLDivElement | null>(null)

  if (!data.showOnMobile) {
    return null
  }

  if (data.type === 'to') {
    return (
      <MobileNavigationMenuLink
        id={data.id}
        type={data.type}
        controls={controls}
        icon={data.mobileIcon}
        title={data.title}
        to={data.to}
        showChevron
      />
    )
  }

  if (data.type === 'action') {
    return (
      <MobileNavigationMenuLink
        id={data.id}
        type={data.type}
        controls={controls}
        icon={data.mobileIcon}
        title={data.title}
        handleAction={data.handleAction}
        showChevron
      />
    )
  }

  if (data.type === 'sub-links') {
    return (
      <FlexContainer $direction="column" $alignItems="stretch">
        <MobileNavigationMenuButton
          id={data.id}
          onClick={() => {
            setSubLinksOpen((currentState) => !currentState)
          }}
          variant={ButtonVariant.ghost}
          $disable={subLinksOpen}
        >
          <MobileNavigationMenuLinkContent
            icon={data.mobileIcon}
            title={data.title}
            showChevron
            chevronOpen={subLinksOpen}
          />
        </MobileNavigationMenuButton>

        <CSSTransition
          in={subLinksOpen}
          timeout={150}
          appear={subLinksOpen}
          classNames="sublinks"
          nodeRef={subLinksRef}
          unmountOnExit
        >
          <SubLinksWrapper ref={subLinksRef}>
            {data.subLinks.map((subLink) => (
              <MobileNavigationMenuLink
                key={subLink.id}
                id={subLink.id}
                type={subLink.type}
                controls={controls}
                title={subLink.title}
                to={'to' in subLink ? subLink.to : undefined}
                handleAction={'handleAction' in subLink ? subLink.handleAction : undefined}
              />
            ))}
          </SubLinksWrapper>
        </CSSTransition>
      </FlexContainer>
    )
  }

  return null
}
