import * as Sentry from '@sentry/react'
import { ConfigProvider } from './contexts/config/ConfigContext'
import { Provider } from 'react-redux'
import { Outlet } from 'react-router-dom'
import userflow from 'userflow.js'
import { ContractWalletBlocker } from './components/ContractWalletBlocker'
// This widget uses an endpoint that requires old auth and comes from stark-trading which is going to be removed.
// Need to check if service is going to be removed or if it's going to be replaced by a new one.
// import { TurnstileWidget } from './components/TurnstileWidget/TurnstileWidget'
import { envConfig } from './env/envConfig'
import { ErrorPage } from './pages/ErrorPage/ErrorPage'
import { logAppVersions } from './services/apiService'
import { AuthProvider } from './services/auth/AuthContext'
import { initCloudflareScrypt } from './services/helperService'
import { setUpSentry } from './services/helperService/setUpSentry'
import { translationSetup } from './services/translationService'
import { configureStore } from './store/configureStore'
import { GlobalStyle } from './styles/GlobalStyle'
import { TonHelper } from './components/TonHelper'
import { RhinoThemeProvider } from './components/RhinoThemeProvider'
import { themes } from './theme'
import { Suspense } from 'react'
import { AppLoader } from './components/common/Helpers/AppLoader'
import { Helmet } from 'react-helmet'
import { translate } from './intl/i18n'
import { WalletProvider } from './components/WalletProvider'
import { listenForViteEvents } from './utils/listenForViteEvents'
import { AppRuntime } from './store/appRuntime'

const { userflowTokenId } = envConfig

const store = configureStore(AppRuntime)

userflow.init(userflowTokenId)
logAppVersions()

translationSetup()
initCloudflareScrypt()
setUpSentry()
listenForViteEvents()

export const Root = () => {
  return (
    <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error} theme={themes['dune']} />}>
      <Provider store={store}>
        <Suspense fallback={<AppLoader showOnPage showLogo source="suspense-app" />}>
          <WalletProvider>
            <RhinoThemeProvider>
              <Helmet>
                <title>{translate('metadata.title_default')}</title>
                <meta name="description" content={translate('metadata.description_default')} />
              </Helmet>
              <GlobalStyle />
              <ConfigProvider>
                <AuthProvider>
                  {/* <TurnstileWidget /> */}
                  <ContractWalletBlocker />
                  <TonHelper />
                  <Outlet />
                </AuthProvider>
              </ConfigProvider>
            </RhinoThemeProvider>
          </WalletProvider>
        </Suspense>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}
