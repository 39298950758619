import { portalSlice, updateBlockchainBalance } from '../slices/portal.slice'
import type { AppDispatch } from '../configureStore'
import { getBlockchainBalanceForChain } from '../../services/ethereum/getBlockchainBalanceForChain'
import type { BridgeConfigEntrySchema } from '@rhinofi/bridge-api-spec'

export const requireChain = (dispatch: AppDispatch) => async (chainName: string) => {
  return new Promise<{ done: boolean; reason?: string }>((resolve, reject) => {
    dispatch(
      portalSlice.actions.setRequiredChain({
        requiredChain: {
          chainName,
          resolve,
          reject,
        },
      }),
    )
  })
}
export const fetchBlockChainBalanceForChain =
  (dispatch: AppDispatch) =>
  async ({
    chain,
    address,
    secondaryWallet,
    chainConfig,
  }: {
    chain: string
    address: string | null | undefined
    secondaryWallet: { secondaryWalletAddress: string; connectedChain: string }
    chainConfig: BridgeConfigEntrySchema
  }) => {
    try {
      const { nativeTokenName, nativeTokenSafeguard } = chainConfig
      const balance = await getBlockchainBalanceForChain(chain, address, chainConfig, secondaryWallet)
      const nativeBalance = parseFloat(balance[chain]?.[nativeTokenName]?.balance ?? '0')
      const hasBalanceForTransaction = nativeBalance > nativeTokenSafeguard
      dispatch(
        updateBlockchainBalance({
          blockchainBalance: balance,
          failedChains: { [chain]: false },
          hasBalanceForTransaction: { [chain]: hasBalanceForTransaction },
        }),
      )
      // eslint-disable-next-line no-unused-vars -- catch statement
    } catch (error) {
      dispatch(
        updateBlockchainBalance({
          blockchainBalance: {},
          failedChains: { [chain]: true },
          hasBalanceForTransaction: { [chain]: false },
        }),
      )
    }
  }
