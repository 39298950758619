import { Button, ButtonVariant, CheckBox, Icon, Label, Spacing, Text, Title } from '@rhinofi/dvf-shared-ui'
import { TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useState } from 'react'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { modalKeys } from '../../constants/modalKeys'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { translate } from '../../intl/i18n'
import { reconnectLocalStorageKey } from '../../services/secondaryWallet/triggerReconnectModal'
import { selectBridgeConfig } from '../../store/apis/config.api'
import { selectModal } from '../../store/slices/modal.slice'
import { Modal } from '../common/Modal/Modal'
import { FlexContainer } from '../common/Wrappers/Containers'

export const NonEvmReconnectModal = () => {
  const [isChecked, setIsChecked] = useState(false)

  const dispatch = useAppDispatch()
  const { modalPayload, modalShown } = useAppSelector(selectModal)
  const bridgeConfig = useAppSelector(selectBridgeConfig)

  if (modalShown !== modalKeys.reconnectModal) {
    return null
  }
  const { connectedChain, chainToConnect, resolve } = modalPayload
  const handleCancelConfirm = (value: boolean) => {
    if (isChecked) {
      localStorage.setItem(reconnectLocalStorageKey, 'true')
    }
    resolve(value)
    clearModal(dispatch)()
  }

  const prettyChainInName = bridgeConfig?.[connectedChain]?.name || connectedChain
  const prettyChainOutName = bridgeConfig?.[chainToConnect]?.name || chainToConnect

  return (
    <Modal isVisible onClose={() => {}} noClose width="360px">
      <Title size={TitleSize.XXS}>
        <Icon id="info-square" /> {translate('helpers.information')}
      </Title>
      <Spacing size="16" />
      <Text bold>
        {translate('onboarding.already_connected_secondary', {
          '%connectedChain': prettyChainInName,
          '%chainToConnect': prettyChainOutName,
        })}
      </Text>
      <Spacing size="16" />

      <FlexContainer $gap="8px">
        <Label>{translate('global.dont_show_again')}</Label>
        <CheckBox
          input={{ value: isChecked ? 'true' : '' }}
          handleChange={(event) => setIsChecked(event.target.checked)}
        />
      </FlexContainer>
      <Spacing size="16" />

      <FlexContainer $fullWidth $gap="16px">
        <Button
          id="cancel-other-address"
          onClick={() => handleCancelConfirm(false)}
          fullWidth
          variant={ButtonVariant.secondary}
        >
          {translate('helpers.cancel')}
        </Button>
        <Button id="confirm-other-address" onClick={() => handleCancelConfirm(true)} fullWidth>
          {translate('helpers.confirm')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}
