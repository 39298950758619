import styled from 'styled-components'
import { Button } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

export const HistoryButton = styled(Button)<{ $selected: boolean }>`
  width: 32px;
  height: 32px;
  transform: rotateZ(${({ $selected }) => ($selected ? 0 : 180)}deg);
`

export const Controls = styled(FlexContainer)`
  grid-area: badge;
`
