import type { ConnectedQuotePayloadSchema } from '@rhinofi/bridge-api-spec'
import { trackUserflowEvent } from '../../../services/apiService/trackUserflowEvent'
import { trackAddressableEvent, trackHeapEvent, trackXPixelEvent } from '../../../services/apiService'
import { heapEvents } from '../../../constants/heapEvents'
import { userflowEvents } from '../../../constants/userflowEvents'
import { addressableEvents } from '../../../constants/addressableEvents'
import { xPixelEvents } from '../../../constants/xPixelEvents'

type BridgeEvent = {
  status: string
  mode: ConnectedQuotePayloadSchema['mode']
  token: string
  chainIn: string
  chainOut: string
  amount: string
  usdAmount: string
  fee: string
  feeUsd: string
  gasBoostUsed: string
}

export const trackBridgeEvent = ({
  status,
  mode,
  token,
  chainIn,
  chainOut,
  amount,
  usdAmount,
  gasBoostUsed,
}: BridgeEvent) => {
  const data = {
    status,
    mode,
    token,
    chainIn,
    chainOut,
    amount,
    usdAmount,
    gasBoostUsed,
  }

  trackHeapEvent(heapEvents.bridge, data)
  trackUserflowEvent(userflowEvents.bridge, data)
  trackAddressableEvent(addressableEvents.bridge, true, data)
  trackXPixelEvent(xPixelEvents.bridge)
}
