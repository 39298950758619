import type { NetworkSelectProps } from '@rhinofi/dvf-shared-ui'
import { Text, Label } from '@rhinofi/dvf-shared-ui'
import { TextSize, LabelSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { translate } from '../../intl/i18n'

const networkSelectorDictionaryBase = {
  unavailableChain: translate('global.unavailable_for_this_token'),
  connectWallet: translate('global.connect_your_wallet'),
  unavailableChainsCategory: (token: string) => translate('global.token_unavailable_for_chain', { '%token': token }),
  emptyList: translate('helpers.no_search_results'),
  cancel: translate('helpers.cancel'),
  placeholder: translate('global.search_network_placeholder'),
  experimentalTooltip: (
    <div>
      <Label size={LabelSize.Label} bold>
        {translate('global.exp_networks_notice_title')}
      </Label>
      <br />
      <Text size={TextSize.XS}>{translate('global.exp_networks_notice_desc')}</Text>
    </div>
  ),
  tokenCount: (count: number) => `${count} ${translate(count === 1 ? 'helpers.token' : 'helpers.tokens')}`,
}

export const networkSelectorDictionaryChainFrom: NetworkSelectProps['dictionary'] = {
  ...networkSelectorDictionaryBase,
  listLabel: translate('bridge.select_network_from'),
}

export const networkSelectorDictionaryChainTo: NetworkSelectProps['dictionary'] = {
  ...networkSelectorDictionaryBase,
  listLabel: translate('bridge.select_network_to'),
}

export const networkSelectorDictionaryChain: NetworkSelectProps['dictionary'] = {
  ...networkSelectorDictionaryBase,
  listLabel: translate('bridge.select_chain'),
}
