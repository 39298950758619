import { SafeDecimal } from '../utils/SafeDecimal'
import { addUserPropertiesHeap } from './apiService'

export enum ABTestExperimentId {
  ONBOARDING_EMAIL = 'onboarding-email',
  SWAP_UI = 'swap-ui',
}

// experimentId => probability of variant A (in case of numVariants - 2)
// In case of more variants (A/B/C), probability is equal so only numVariants is needed
const AB_EXPERIMENTS: Record<ABTestExperimentId, { probability?: number; numVariants: number }> = {
  [ABTestExperimentId.ONBOARDING_EMAIL]: { numVariants: 2 },
  [ABTestExperimentId.SWAP_UI]: { numVariants: 2, probability: 0.8 },
}

export const getABTestLocalStorageKey = (experimentId: ABTestExperimentId) => `ab-test-${experimentId}`

export const getABTestRandomVariant = (experimentId: ABTestExperimentId) => {
  const { probability, numVariants } = AB_EXPERIMENTS[experimentId]

  if (numVariants === 2 && probability) {
    return (Math.random() < probability ? 0 : 1).toString()
  }
  const percentage = SafeDecimal(1).div(numVariants)
  const variant = Math.floor(SafeDecimal(Math.random()).div(percentage).toNumber())

  return variant.toString()
}

export const addABTestVariantUserPropertiesHeapOnAuthenticated = (
  experimentId: ABTestExperimentId,
  variant: string,
) => {
  addUserPropertiesHeap({ [getABTestLocalStorageKey(experimentId)]: variant })
}

export const getABTestVariant = (experimentId: ABTestExperimentId) => {
  const localStorageValue = localStorage.getItem(getABTestLocalStorageKey(experimentId))
  if (localStorageValue) {
    return String(localStorageValue)
  }

  const variant = getABTestRandomVariant(experimentId)
  localStorage.setItem(getABTestLocalStorageKey(experimentId), variant)

  return variant
}
