import { NetworkType } from '@rhinofi/dvf-shared-ui'
import { Struct } from 'effect'
import { useMemo, useCallback } from 'react'
import { selectBlockchainBalances } from '../../../store/selectors/portal.selectors'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../services/usdPricesApi'
import { selectAvailableChains, selectAvailableTokens } from '../../../store/apis/config.api'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { getBridgeBadge } from '../helpers'
import { isChainAvailableForToken } from '../helpers/isChainAvailableForToken'
import { selectBridge } from '../slices/bridge.slice'
import type { ChainWithBalance, TokenWithBalanceAndAvailability } from '../types/bridge-widget.types'
import { selectSecondaryWalletChain } from '../../../store/selectors/secondaryWallet.selectors'
import { selectAddress } from '../../../store/selectors/user.selectors'
import { isFeatureEnabled } from '../../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES, NETWORKS } from '../../../constants/types'
import { isParadexAllowedChain } from '../../../services/secondaryWallet/services/paradexService/isParadexAllowedChain'
import { useIsSwapUiAbTestEnabled } from '../../../hooks/useIsSwapUiAbTestEnabled'

const isCombinedBalancesEnabled = isFeatureEnabled(F_FLAGS_TYPES.CHAIN_PICKER_COMBINED_BALANCES)

type UseBridgeDataWithBalancesOutput = {
  availableChainsInWithBalances: ChainWithBalance[]
  availableChainsOutWithBalances: ChainWithBalance[]
  tokensListWithBalancesChainIn: TokenWithBalanceAndAvailability[]
  tokensListWithBalancesChainOut: TokenWithBalanceAndAvailability[]
  highlightedPickerChains: { id: string; label: string }[]
}

const HIGHLIGHTED_PICKER_CHAINS = [
  NETWORKS.SONIC,
  NETWORKS.ARBITRUM,
  NETWORKS.BASE,
  NETWORKS.BINANCE,
  NETWORKS.ETHEREUM,
  NETWORKS.PARADEX,
  NETWORKS.OPTIMISM,
  NETWORKS.SOLANA,
  NETWORKS.LINEA,
  NETWORKS.ZKSYNC,
  NETWORKS.MATIC_POS,
]

export const useBridgeDataWithBalances = (): UseBridgeDataWithBalancesOutput => {
  const availableChains = useAppSelector(selectAvailableChains)
  const availableTokens = useAppSelector(selectAvailableTokens)
  const blockchainBalances = useAppSelector(selectBlockchainBalances)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)

  const isEvmWalletConnected = !!useAppSelector(selectAddress)
  const secondaryWalletChain = useAppSelector(selectSecondaryWalletChain)
  const isSwapUiAbTestEnabled = useIsSwapUiAbTestEnabled()

  const isWalletConnected = useCallback(
    (chain: string, networkType: NetworkType) => {
      if (networkType === NetworkType.Evm) {
        return isEvmWalletConnected
      }
      return secondaryWalletChain === chain
    },
    [isEvmWalletConnected, secondaryWalletChain],
  )

  const availableChainsWithBalances: ChainWithBalance[] = useMemo(
    () =>
      availableChains.map(({ chain, config }) => {
        const balance = blockchainBalances?.[chain]?.[bridgeState.token]?.balance ?? '0'
        const tokenUsdPrice = getTokenPrice(tokenPrices, bridgeState.token)
        const tokensToConsider = [...Object.keys(config.tokens)]
        const balanceUsd = isCombinedBalancesEnabled
          ? Object.values(blockchainBalances?.[chain] ?? {})
              .filter(({ token }) => tokensToConsider.includes(token))
              .map((tokenBalance) => {
                const localTokenUsdPrice = getTokenPrice(tokenPrices, tokenBalance.token)
                return SafeDecimal(tokenBalance.balance).times(localTokenUsdPrice)
              })
              .reduce((acc, curr) => acc.plus(curr), SafeDecimal(0))
              .toString()
          : SafeDecimal(balance).times(tokenUsdPrice).toString()
        const tokenBalances = tokensToConsider.flatMap((token) => {
          const balanceForToken = blockchainBalances?.[chain]?.[token]?.balance
          return balanceForToken && balanceForToken !== '0' ? [{ token, balance: balanceForToken ?? '0' }] : []
        })

        const badge = getBridgeBadge(config)
        const type = config.type === 'EVM' ? NetworkType.Evm : NetworkType.NonEvm

        const baseConfig = Struct.omit('category', 'badge', 'type')(config)

        return {
          ...baseConfig,
          type,
          chain,
          chainName: config.name,
          isAvailable: isChainAvailableForToken(config, bridgeState.token),
          balance,
          balanceUsd,
          isWalletConnected: isWalletConnected(chain, type),
          tokenBalances: isCombinedBalancesEnabled ? tokenBalances : [],
          ...(config.badge && { badge }),
        }
      }),
    [availableChains, blockchainBalances, bridgeState.token, tokenPrices, isWalletConnected],
  )

  const getListTokenInfo = useCallback(
    ({
      token,
      chain,
      chainName,
    }: {
      token: string
      chain: string
      chainName: string
    }): TokenWithBalanceAndAvailability => {
      const tokenUsdPrice = getTokenPrice(tokenPrices, token)
      const balanceToken = blockchainBalances?.[chain]?.[token]?.balance || '0'
      const balanceUsd = SafeDecimal(balanceToken).times(tokenUsdPrice).toString()

      return {
        token,
        chain,
        chainName,
        balanceToken,
        balanceUsd,
        isAvailable: true,
      }
    },
    [tokenPrices, blockchainBalances],
  )

  const allAvailableTokensAllAvailableChainsBalances: TokenWithBalanceAndAvailability[] = useMemo(() => {
    const list = availableChains.flatMap(({ chain, config }) =>
      Object.keys(config.tokens).map((token) => ({
        token,
        chain,
        chainName: config.name,
      })),
    )

    return list.map(getListTokenInfo)
  }, [availableChains, getListTokenInfo])

  const formatDefaultTokenListForChain = useCallback(
    ({ chain, chainName }: { chain: string; chainName: string }) =>
      availableTokens.flatMap((token) => {
        const item = allAvailableTokensAllAvailableChainsBalances.find(
          (foundItem) => foundItem.chain === chain && foundItem.token === token,
        )
        return (
          item ?? {
            token,
            chain,
            chainName,
            balanceToken: '0',
            balanceUsd: '0',
            isAvailable: false,
          }
        )
      }),
    [allAvailableTokensAllAvailableChainsBalances, availableTokens],
  )

  // Default chain in list with balances for available tokens and zero balances for not available tokens
  const tokensListWithBalancesForChainIn = useMemo(
    () => formatDefaultTokenListForChain({ chain: bridgeState.chainIn, chainName: chainInConfig?.name ?? '' }),
    [formatDefaultTokenListForChain, bridgeState.chainIn, chainInConfig?.name],
  )

  // Default chain out list with balances for available tokens and zero balances for not available tokens
  const tokensListWithBalancesForChainOut = useMemo(
    () => formatDefaultTokenListForChain({ chain: bridgeState.chainOut, chainName: chainOutConfig?.name ?? '' }),
    [formatDefaultTokenListForChain, bridgeState.chainOut, chainOutConfig?.name],
  )

  const tokensListWithBalancesChainIn = useMemo(() => {
    if (isSwapUiAbTestEnabled) {
      const formatted = allAvailableTokensAllAvailableChainsBalances.filter(
        (item) => !(item.chain === bridgeState.chainOut && item.token === bridgeState.token),
      )

      if (bridgeState.chainOut === NETWORKS.PARADEX) {
        return formatted.filter(({ chain }) => isParadexAllowedChain({ chain }))
      }

      return formatted
    }

    return tokensListWithBalancesForChainIn
  }, [
    allAvailableTokensAllAvailableChainsBalances,
    isSwapUiAbTestEnabled,
    tokensListWithBalancesForChainIn,
    bridgeState,
  ])

  const tokensListWithBalancesChainOut = useMemo(() => {
    // Show just the selected token on all networks
    if (isSwapUiAbTestEnabled) {
      return allAvailableTokensAllAvailableChainsBalances.filter(
        (item) => item.token === bridgeState.token && item.chain !== bridgeState.chainIn,
      )
    }

    return tokensListWithBalancesForChainOut
  }, [
    isSwapUiAbTestEnabled,
    tokensListWithBalancesForChainOut,
    allAvailableTokensAllAvailableChainsBalances,
    bridgeState,
  ])

  const highlightedPickerChains = useMemo(
    () =>
      HIGHLIGHTED_PICKER_CHAINS.map((chain) => {
        const chainConfig = availableChains.find((item) => item.chain === chain)
        return { id: chain, label: chainConfig?.config.name ?? '' }
      }),
    [availableChains],
  )

  const availableChainsInWithBalances = useMemo(() => {
    if (isSwapUiAbTestEnabled) {
      return availableChainsWithBalances.map((item) => ({ ...item, isAvailable: true }))
    }

    if (bridgeState.chainOut === NETWORKS.PARADEX) {
      return availableChainsWithBalances.filter(({ chain }) => isParadexAllowedChain({ chain }))
    }
    return availableChainsWithBalances
  }, [availableChainsWithBalances, bridgeState.chainOut, isSwapUiAbTestEnabled])

  return {
    availableChainsInWithBalances,
    availableChainsOutWithBalances: availableChainsWithBalances,
    tokensListWithBalancesChainIn,
    tokensListWithBalancesChainOut,
    highlightedPickerChains,
  }
}
