import { useMemo } from 'react'
import { useWallet } from '../../../hooks/useWallet'
import metamaskIcon from '../assets/metamask.svg'
import okxIcon from '../assets/okx.svg'
import trustIcon from '../assets/trust.svg'

const OTHER_WALLETS = [
  {
    icon: metamaskIcon,
    label: 'MetaMask',
  },
  {
    icon: okxIcon,
    label: 'OKX Wallet',
  },
  {
    icon: trustIcon,
    label: 'Trust Wallet',
  },
  {
    icon: 'bi-wallet',
    label: 'Browser wallet',
  },
]

export const useOtherWallet = () => {
  const wallet = useWallet()

  return useMemo(() => {
    return OTHER_WALLETS.filter(
      ({ label }) => !wallet.eip6963Providers.some((provider) => provider.info.name === label),
    )
  }, [wallet.eip6963Providers])
}
