import { Icon, Text, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { SmallSpinner } from '../../../../components/common/Helpers/SmallSpinner'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { useExplorerLink } from '../../../../hooks/useExplorerLink'
import { translate } from '../../../../intl/i18n'
import { StatusIconWrapper, TxLink } from './RecentBridgeTx.styled'

const getStateIcon = (state: string) => {
  switch (state) {
    case 'EXECUTED':
      return <Icon id="check-circle-fill" className="success-bridge" color="primaryMain" />
    case 'CANCELLED':
      return <Icon id="cross-circle-fill" className="success-bridge" color="accentRed" />
  }
  return <SmallSpinner />
}

type Props = {
  chain: string
  name: string
  txHash: string
  state: string
  id: 'in' | 'out'
}

export const ChainContainer = ({ chain, name, txHash, state, id }: Props) => {
  const { etherscanLink } = useExplorerLink()

  return (
    <FlexContainer $direction="column" $alignItems="center" $justifyContent="center" $gap="12px" $fullWidth>
      <div style={{ position: 'relative' }}>
        <TokenIcon token={chain} size="large" />
        <StatusIconWrapper>{getStateIcon(state)}</StatusIconWrapper>
      </div>
      <FlexContainer $direction="column" $gap="2px">
        <Text size={TextSize.S} bold id={`${id}-name`}>
          {name}
        </Text>
        {txHash !== '' ? (
          <TxLink
            href={etherscanLink(txHash, chain, true)}
            target="_blank"
            rel="noopener noreferrer"
            id={`${id}-explorer`}
          >
            {translate('helpers.view_details')}
          </TxLink>
        ) : (
          <Text id={`${id}-pending`} size={TextSize.XS}>
            {translate('helpers.pending')}...
          </Text>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}
