import { Query } from '@rhinofi/effect-rtk'
import { createApiFromEffectTag } from '../../../../store/appRuntime'
import { SwapsClient } from './SwapsClient'

export const { api: swapsApi, ApiErrorPhantom } = createApiFromEffectTag(
  SwapsClient,
  { reducerPath: 'swapsApi' },
  {
    configsGetAllTokens: {
      type: Query,
    },
  },
)

export type SwapsApiError = typeof ApiErrorPhantom
export const { useConfigsGetAllTokensQuery } = swapsApi
