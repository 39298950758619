import { useCallback, useMemo } from 'react'
import { translate } from '../../../../intl/i18n'
import { BridgeTabsStep } from '../../types/bridge-widget.types'
import { BridgeHighlights } from '../BridgeHighlights/BridgeHighlights'
import { BridgePortfolio } from '../BridgePortfolio/BridgePortfolio'
import { BridgeTransitionStates } from '../BridgeTransitionStates'
import { RecentBridgeTransactions } from '../RecentBridgeTransactions/RecentBridgeTransactions'
import { BridgeTabsContainer } from './BridgeTabs.styled'
import { TabSelect } from './TabSelect'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { selectBridgeLayoutActiveBridgeTab, setActiveBridgeTab } from '../../slices/bridgeLayout.slice'

export const BridgeTabs = () => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectBridgeLayoutActiveBridgeTab)

  const tabs = useMemo(
    () =>
      [
        {
          label: translate('helpers.explore'),
          id: BridgeTabsStep.highlights,
        },
        {
          label: translate('portfolio.portfolio'),
          id: BridgeTabsStep.portfolio,
        },
        {
          label: translate('helpers.history'),
          id: BridgeTabsStep.history,
        },
      ].filter(({ id }) => id === activeTab),
    [activeTab],
  )

  const setActiveTab = useCallback(
    (step: BridgeTabsStep) => {
      dispatch(setActiveBridgeTab(step))
    },
    [dispatch],
  )

  const renderBridgeTabsContent = useCallback((step: BridgeTabsStep) => {
    switch (step) {
      case BridgeTabsStep.highlights:
        return <BridgeHighlights />
      case BridgeTabsStep.history:
        return <RecentBridgeTransactions />
      case BridgeTabsStep.portfolio:
        return <BridgePortfolio />
      default:
        return null
    }
  }, [])

  return (
    <BridgeTabsContainer>
      <TabSelect tabs={tabs} onClick={setActiveTab} activeTab={activeTab} fullWidth clickable={tabs.length > 1} />
      <BridgeTransitionStates currentState={activeTab}>
        {({ step }) => renderBridgeTabsContent(step)}
      </BridgeTransitionStates>
    </BridgeTabsContainer>
  )
}
