import { type FC, type PropsWithChildren, useState } from 'react'
import { PopupContent, PopupHeader, PopupWrapper } from '../MobilePopup.styled'
import { Icon, IconSizes, Text } from '@rhinofi/dvf-shared-ui'
import { useAutoClose } from '@rhinofi/dvf-shared-ui/lib/hooks/useAutoClose'
import { useRef } from 'react'

type Props = {
  handleClose: () => void
  headerText: string
  alwaysOpen: boolean
}

export const MobilePopupContent: FC<PropsWithChildren<Props>> = ({ handleClose, headerText, children, alwaysOpen }) => {
  const [initialTrigger, setInitialTrigger] = useState(true)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useAutoClose({
    setIsOpen: () => {
      if (alwaysOpen) {
        return handleClose()
      }

      if (!initialTrigger) {
        handleClose()
      }
      setInitialTrigger(false)
    },
    elementToClose: wrapperRef,
    isWatching: true,
  })

  return (
    <PopupWrapper ref={wrapperRef}>
      <PopupHeader $justifyContent="space-between">
        <Text bold>{headerText}</Text>
        <Icon id="x" active size={IconSizes.Big} handleClick={handleClose} />
      </PopupHeader>

      <PopupContent>{children}</PopupContent>
    </PopupWrapper>
  )
}
