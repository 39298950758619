import { TokenIcon, TextSize, Icon, Text } from '@rhinofi/dvf-shared-ui'
import { useConfig } from '../../hooks'
import { FlexContainer } from '../common/Wrappers/Containers'

export const FromCell = ({ chainIn, showChainNames }: { chainIn: string; showChainNames: boolean }) => {
  const { config } = useConfig()
  return (
    <FlexContainer $alignItems="center" $justifyContent="space-between" $gap="16px">
      <FlexContainer $alignItems="center" $gap="8px">
        <TokenIcon token={chainIn} size="small" />
        {showChainNames && (
          <Text noWrap size={TextSize.S}>
            {config?.[chainIn]?.name ?? ''}
          </Text>
        )}
      </FlexContainer>
      <Icon id="arrow-right" color="secondaryDark" />
    </FlexContainer>
  )
}
export const ToCell = ({ chainOut, showChainNames }: { chainOut: string; showChainNames: boolean }) => {
  const { config } = useConfig()
  return (
    <FlexContainer $alignItems="center" $gap="8px">
      <TokenIcon token={chainOut} size="small" />
      {showChainNames && <Text size={TextSize.S}>{config?.[chainOut]?.name ?? ''}</Text>}
    </FlexContainer>
  )
}
