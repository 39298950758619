import { ERC20__factory } from '../../../contracts/ERC20'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import type { ApprovalContractCallProps } from './callApprovalContract'
import { trackHeapEvent } from '../../../services/apiService'
import { heapEvents } from '../../../constants/heapEvents'

const log = makeLog(LogFeature.BRIDGE_APPROVALS)

export const approveEVMTokenContract = async ({
  bridgeContractAddress,
  tokenAddress,
  ethersSigner,
  amountWithDecimals,
  requireAllowanceReset,
  chain,
  token,
}: ApprovalContractCallProps) => {
  const erc20Contract = ERC20__factory.connect(tokenAddress, ethersSigner)

  // Some tokens on certain chains require the allowance to be set to 0 before
  // setting a new value
  if (requireAllowanceReset) {
    const allowanceResetTx = await erc20Contract.approve(bridgeContractAddress, '0')

    log('Approve reset transaction sent:', allowanceResetTx)
    trackHeapEvent(heapEvents.tokenResetAllowance, {
      chain,
      token,
    })
    const allowanceResetReceipt = await allowanceResetTx.wait()
    log('Approve reset transaction confirmed:', allowanceResetReceipt)
  }

  const tx = await erc20Contract.approve(bridgeContractAddress, amountWithDecimals)
  log('Approve transaction sent:', tx)
  const receipt = await tx.wait()
  log('Approve transaction confirmed:', receipt)
  return receipt
}
