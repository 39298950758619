import type { DepositLimitReached } from '@rhinofi/bridge-api-spec'
import { translate } from '../../../intl/i18n'
import { BigDecimal } from 'effect'
import { formatFloat } from '../../../services/helperService/formatFloat'

export const formatDepositLimitReachedError = (error: DepositLimitReached) => {
  const limit = error.maxDepositAmount ? formatFloat(BigDecimal.format(error.maxDepositAmount)) : ''
  const limitWithToken = `${limit} ${error.token}`
  return translate('errors.bridge_deposit_limit_reached', {
    '%chain': error.chain,
    '%depositLimit': limitWithToken,
  })
}
