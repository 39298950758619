import styled from 'styled-components'
import { useMemo } from 'react'
import { showModal } from '../../../../store/actions/modalActions/showModal'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { modalKeys } from '../../../../constants/modalKeys'
import { translate } from '../../../../intl/i18n'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { bridgeHistoryColumns } from '../../../../components/BridgeHistory/BridgeHistory.columns'
import { useExplorerLink } from '../../../../hooks/useExplorerLink'
import { Table } from '@rhinofi/dvf-shared-ui'
import { ButtonLink } from '../../../../components/common/Helpers/LinkHelpers'
import { ButtonSizes, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { makeRouteTo } from '../../../../router'
import { useAppSelector } from '../../../../hooks'
import { selectBridgeHistory } from '../../slices/bridgeHistory.slice'

export const RecentBridgeTransactions = () => {
  const dispatch = useAppDispatch()
  const { etherscanLink } = useExplorerLink()
  const bridgeHistory = useAppSelector(selectBridgeHistory)

  const tableData = useMemo(() => {
    const { items = [] } = bridgeHistory || {}
    return items.map(({ bridge, rejections }) => ({ ...bridge, rejections }))
  }, [bridgeHistory])

  return (
    <Container $direction="column" $gap="16px">
      <Table
        id="history"
        data={tableData}
        columns={bridgeHistoryColumns({ etherscanLink, isWidget: true })}
        emptyMessage={translate('global.no_transaction_history')}
        onRowClick={(data) =>
          showModal(dispatch)(modalKeys.recentBridgeTx, {
            bridgeData: data,
          })
        }
      />
      {tableData.length > 0 && (
        <ButtonLink
          id="view-full-history"
          to={makeRouteTo.history()}
          variant={ButtonVariant.secondary}
          size={ButtonSizes.Large}
        >
          {translate('global.view_transaction_history')}
        </ButtonLink>
      )}
    </Container>
  )
}

const Container = styled(FlexContainer)`
  max-height: 440px;
  overflow: auto;

  .table-container {
    width: 100%;
  }
`
