import type { PublicQuoteQuerySchema } from '@rhinofi/bridge-api-spec'
import { useAppSelector } from '../../../hooks'
import { useGetPublicQuoteQuery } from '../../../store/apis/bridge.api'
import { selectBridge } from '../slices/bridge.slice'
import { unsafeFromString } from 'effect/BigDecimal'
import { useBridgeAmount } from './useBridgeAmount'
import { useDebouncedValues } from './useDebouncedValues'
import { isQuoteAmountValid } from '../helpers/isQuoteAmountValid'

type UsePublicQuoteInput = {
  mode?: PublicQuoteQuerySchema['mode']
  amount?: string
  gasBoostAmountNative?: string | undefined
  skip?: boolean
}

export const usePublicQuote = ({
  mode = 'receive',
  amount,
  gasBoostAmountNative,
  skip = false,
}: UsePublicQuoteInput = {}) => {
  const bridgeState = useAppSelector(selectBridge)
  const bridgeAmount = useBridgeAmount()

  const quoteAmount = amount !== undefined ? amount : bridgeAmount

  const debouncedValues = useDebouncedValues({
    amount: quoteAmount,
  })

  const {
    currentData: publicQuote,
    isError,
    error,
    isFetching,
  } = useGetPublicQuoteQuery(
    {
      urlParams: {
        amount: unsafeFromString(debouncedValues.amount),
        chainIn: bridgeState.chainIn,
        chainOut: bridgeState.chainOut,
        token: bridgeState.token,
        mode,
        ...(gasBoostAmountNative && { amountNative: unsafeFromString(gasBoostAmountNative) }),
      },
    },
    {
      skip:
        skip ||
        !isQuoteAmountValid(quoteAmount) ||
        // Fixes bug where the quote was being called with the 0 value
        // This happened because the amount was not 0 but the debounced value was still 0
        !isQuoteAmountValid(debouncedValues.amount) ||
        !bridgeState.chainIn ||
        !bridgeState.chainOut ||
        !bridgeState.token,
    },
  )

  return { quote: publicQuote, isError, error, isFetching }
}
