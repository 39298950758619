import { selectBlockchainBalances } from '../../../store/selectors/portal.selectors'
import { useAppSelector } from '../../../hooks'
import { selectBridge } from '../slices/bridge.slice'

export const useMaxBalance = () => {
  const bridgeState = useAppSelector(selectBridge)
  const blockchainBalances = useAppSelector(selectBlockchainBalances)

  const tokenBalanceChainIn = blockchainBalances?.[bridgeState.chainIn]?.[bridgeState.token]?.balance ?? '0'
  const tokenBalanceChainOut = blockchainBalances?.[bridgeState.chainOut]?.[bridgeState.token]?.balance ?? '0'

  return { tokenBalanceChainIn, maxBridgeableAmount: tokenBalanceChainIn, tokenBalanceChainOut }
}
