import type { AuthDataType } from './auth.types'
import { AuthDataSchema } from './auth.types'
import { Schema } from 'effect'
import { authKey } from './authKey'

const urlParams = new URLSearchParams(window.location.search)
const widgetJwt = urlParams.get('api_key')

export const saveAuthData = (authData: AuthDataType) => {
  if (widgetJwt) {
    return
  }
  const encodedAuthData = Schema.encodeSync(AuthDataSchema)(authData)
  localStorage.setItem(authKey(authData.ethAddress), JSON.stringify(encodedAuthData))
}
