import { createGlobalStyle } from 'styled-components'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
// TODO FUD-1455: update topNavigationHeight to imported
// import { topNavigationHeight } from '../ui/layouts/NavigationDesktop/NavigationDesktop.constants'
const topNavigationHeight = '72px'

export const GlobalStyle = createGlobalStyle`
  :root {
    --header-height: 0px;
  }

  @media (min-width: ${defaultThreshold.xxs}px) {
    :root {
      --header-height: ${topNavigationHeight};
    }
  }

  body {
    --brand-primary: ${({ theme }) => theme.primary};
    --neutral-secondary-40: ${({ theme }) => theme.textSecondary};
    --elevation-l-1: ${({ theme }) => theme.elevationL1};
    --elevation-elevation-l-1: ${({ theme }) => theme.elevationL1};
  }

  /* styles moved from SCSS below */
  html,
body {
  height: 100%;
}

body {
  --wcm-z-index: 100002;
  margin: 0;
  padding: 0;
  font-family: 'Lexend', 'sans-serif';
  // Leaving commented in in case it breaks anything
  // min-width: 1000px;
  overflow: auto !important;
  color: black;
  --color_fill: #27324b;

  @media screen and (max-width: 600px) {
    min-width: 0;
  }
}

#focusable-overlay {
  z-index: 9997 !important;
  min-width: 1280px;
  height: 200vh;
  > :nth-child(2) {
    padding-top: 60px;
  }
}

@media (max-width: 1400px) {
  body {
    font-size: 14px;
  }
}


button {
  font-family: 'Lexend', 'sans-serif';
}

.collapsible-body {
  display: block !important;
}

.center {
  text-align: center !important;
}

div.google-visualization-tooltip {
  background-color: #212d49;
  border: 1px solid grey !important;
  font-size: 0.75rem !important;
  text-transform: initial;
  text-align: center;
  height: auto;

  > ul > li {
    padding: 0 1em;
  }
}

[data-simplebar] {
  z-index: 10 !important;
}

.simplebar-content-wrapper {
  outline: none;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.__react-tooltip {
  max-width: 300px;
  font-size: 0.75rem !important;
  text-align: center;
  text-transform: initial;
  border-radius: 4px;

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 10px);
    left: 0 !important;
  }
}

.ui-tooltip__wrapper {
  z-index: 10001 !important;
}

.no-scroll {
  height: 100vh;
  overflow-y: hidden !important;
  padding-right: 15px; /* Avoid width reflow */
  .no-scroll-header {
    overflow-y: hidden !important;
    max-width: calc(100vw - 15px);
  }
}

.rhinofi-wrapped-modal-card-container {
  padding: 0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

#turnstile-container {
  display: none;
}

[data-tc-wallets-modal-container="true"] {
  z-index: 10000 !important;
}

.ui-panel {
  margin: 0;
}

div.collapsible {
  margin-bottom: 10px;
}

.empty-list {
  text-align: center;
}

.fade-in-animation {
  animation: fade-in ease-out 500ms;
}

@keyframes appear-from-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-tooltip {
  font-size: 13px;
  text-align: left;
  padding: 12px 10px;

  @media all and (max-width: 1400px) {
    font-size: 11px;
  }

  & > * {
    margin: 0;
  }

  .split {
    height: 1px;
    background-color: #fff;
    width: 100%;
    margin: 4px 0;
  }

  & > span {
    position: relative;
    margin-left: 5px;
    margin-bottom: 5px;

    &.total {
      font-weight: bold;
      color: #fff;
    }

    &.minus::before {
      content: '- ';
      position: absolute;
      left: -7px;
    }
  }

  p {
    padding-bottom: 8px;
  }
}

.__react-tooltip--no-padding {
  padding: 0;
}

.ui-tooltip__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  pointer-events: none;

  .__react-tooltip {
    z-index: 99999;
    line-height: initial;
    position: relative;
    pointer-events: auto;

    &.__react-tooltip--persistent {
      pointer-events: auto !important;

      &:hover {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    &.__react-tooltip--pre-formatted {
      white-space: pre-wrap;
      text-align: left;
    }
  }
}

`
