import { Button, Icon, IconSizes, Spacing, Text, TokenIcon, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { translate } from '../../../intl/i18n'
import type { RequireChainContentProps } from '../types/RequireChainTypes'

export const RequireChainContent = ({
  connectedNetworkName,
  chainName,
  networkName,
  switchToNetwork,
}: RequireChainContentProps) => (
  <div>
    <Spacing size="16" />
    <FlexContainer $justifyContent="center" $fullWidth $gap="16px">
      <TokenIcon token={connectedNetworkName} size="large" />
      <Icon id="arrow-right" size={IconSizes.Big} />
      <TokenIcon token={chainName} size="large" />
    </FlexContainer>
    <Spacing size="24" />
    <Text align={TypographyAlign.Center}>
      {translate('global.require_chain_modal_text', {
        '%networkName': networkName,
      })}
    </Text>
    <Spacing size="24" />
    <Button id="change-network" fullWidth onClick={switchToNetwork}>
      {translate('global.require_chain_modal_cta')}
    </Button>
  </div>
)
