export const ORDER_MAPPING = {
  limit: 'EXCHANGE LIMIT',
  market: 'EXCHANGE MARKET',
}

export type SupportedWallets =
  | 'metamask'
  | 'ledger'
  | 'keystore'
  | 'trust'
  | 'tokenPocket'
  | 'walletConnect'
  | 'coinbase'
  | 'okx'
  | 'webwallet'

export const WALLETS: Record<string, SupportedWallets> = {
  METAMASK: 'metamask',
  LEDGER: 'ledger',
  KEYSTORE: 'keystore',
  TRUST: 'trust',
  TOKEN_POCKET: 'tokenPocket',
  WALLET_CONNECT: 'walletConnect',
  COINBASE: 'coinbase',
  OKX: 'okx',
  WEBWALLET: 'webwallet',
}

export type SupportedNonEVMWallets = 'starknet'

export const NON_EVM_WALLETS: Record<string, SupportedNonEVMWallets> = {
  STARKNET: 'starknet',
}

export const NETWORKS = {
  ETHEREUM: 'ETHEREUM',
  MATIC_POS: 'MATIC_POS',
  ARBITRUM: 'ARBITRUM',
  ARBITRUMNOVA: 'ARBITRUMNOVA',
  POLYGON_MAINNET: 'POLYGON_MAINNET',
  BINANCE: 'BINANCE',
  BINANCE_MAINNET: 'BINANCE_MAINNET',
  ARBITRUM_MAINNET: 'ARBITRUM_MAINNET',
  ZKSYNC: 'ZKSYNC',
  ZKEVM: 'ZKEVM',
  OPTIMISM: 'OPTIMISM',
  BASE: 'BASE',
  LINEA: 'LINEA',
  OPBNB: 'OPBNB',
  SCROLL: 'SCROLL',
  MANTA: 'MANTA',
  STARKNET: 'STARKNET',
  ZKFAIR: 'ZKFAIR',
  AVALANCHE: 'AVALANCHE',
  TRON: 'TRON',
  ZETA: 'ZETA',
  MANTLE: 'MANTLE',
  FRAME: 'FRAME',
  MODE: 'MODE',
  RARI: 'RARI',
  KROMA: 'KROMA',
  BLAST: 'BLAST',
  INEVM: 'INEVM',
  XLAYER: 'XLAYER',
  STARKEX: 'STARKEX',
  SOLANA: 'SOLANA',
  TAIKO: 'TAIKO',
  PARADEX: 'PARADEX',
  ZIRCUIT: 'ZIRCUIT',
  CRONOS_ZKEVM: 'CRONOS_ZKEVM',
  BERACHAIN: 'BERACHAIN',
  TON: 'TON',
  APECHAIN: 'APECHAIN',
  SONIC: 'SONIC',
  INK: 'INK',
  SONEIUM: 'SONEIUM',
  UNICHAIN: 'UNICHAIN',
  STORY: 'STORY',
  CELO: 'CELO',
}

export const NON_EVM_CHAINS = {
  [NETWORKS.STARKNET]: true,
  [NETWORKS.TRON]: true,
  [NETWORKS.SOLANA]: true,
  [NETWORKS.PARADEX]: true,
  [NETWORKS.TON]: true,
}

export const NO_APPROVAL_CHAINS = [NETWORKS.STARKNET, NETWORKS.PARADEX, NETWORKS.SOLANA, NETWORKS.TON]

// New flags that are NOT for actual end-user features should be prefixed with DEV_.
export const F_FLAGS_TYPES = {
  BRIDGE_REWARDS_CAMPAIGN: 'BRIDGE_REWARDS_CAMPAIGN',
  CLAIM_MILESTONE_CAMPAIGN: 'CLAIM_MILESTONE_CAMPAIGN',
  CONTRACTS_INTERACTIONS: 'CONTRACTS_INTERACTIONS',
  DEV_DELAY_STARTUP: 'DEV_DELAY_STARTUP',
  DEV_ENABLE_SENTRY: 'DEV_ENABLE_SENTRY',
  DEV_ENCHANCE_SENTRY: 'DEV_ENCHANCE_SENTRY',
  DEV_HIDDEN_ACTIONS: 'DEV_HIDDEN_ACTIONS',
  DEV_LOG_TRACKING_EVENTS: 'DEV_LOG_TRACKING_EVENTS',
  STARKNET_ARGENT_MOBILE: 'STARKNET_ARGENT_MOBILE',
  WRAPPED_2023: 'WRAPPED_2023',
  NATION_FREE_MINT: 'NATION_FREE_MINT',
  NATION_WIDGET: 'NATION_WIDGET',
  ZIRCUIT_DOPE: 'ZIRCUIT_DOPE',
  INK_NFT: 'INK_NFT',
  MEMECOIN_TRACKER: 'MEMECOIN_TRACKER',
  TOKEN_DISTRIBUTION: 'TOKEN_DISTRIBUTION',
  CHAIN_PICKER_COMBINED_BALANCES: 'CHAIN_PICKER_COMBINED_BALANCES',
  STARKEX_CLAIM: 'STARKEX_CLAIM',
} as const

export type FFlag = keyof typeof F_FLAGS_TYPES

export type TurnstileDataType = 'REGULAR' | 'BOT'

export type TurnstileData = {
  type: TurnstileDataType
  errorCode: number
  token?: string
}

export type ChainGasFeed = string | number | undefined
