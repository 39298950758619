import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import styled from 'styled-components'
import { SkeletonCard } from '../../../../components/common/Skeletons/Skeleton.styled'

export const BridgeHighlightsContainer = styled.div`
  display: grid;
  gap: 8px;

  .highlight-link {
    display: flex;
  }
`

export const BridgeHighlightsImage = styled.img<{ $isActive: boolean; width: number; height: number }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: ${({ width }) => width}px;
  max-height: ${({ height }) => height}px;
  border-radius: 8px;
  border: 1px solid transparent;
  margin: 0 auto;

  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'default')};

  ${({ $isActive, theme }) =>
    $isActive
      ? `
        &:hover {
          border: 1px solid #e4e4e4;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        }
        &:active {
          border: 1px solid ${theme.brandSolid};
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
        }
  `
      : ''}
  @media ${respondBelow('xxs')} {
    height: auto;
  }
`

export const HighlightSkeleton = styled(SkeletonCard)`
  @media ${respondBelow('xxs')} {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 5;
  }
`
