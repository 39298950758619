import { Button, ButtonSizes, ButtonVariant, Text, TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import { Modal } from '../../../../../components/common/Modal/Modal'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { translate } from '../../../../../intl/i18n'
import { clearModal } from '../../../../../store/actions/modalActions/clearModal'
import RhinoRiderImg from '../../../assets/rhino-rider.png'
import { useBridgeProgress } from '../../../hooks'
import { BridgeProgressStep } from '../../../slices/bridgeProgress.slice'

export const WhyTakesSoLongModal = () => {
  const dispatch = useAppDispatch()
  const { progress } = useBridgeProgress()

  const isSolving = progress.progressStep !== BridgeProgressStep.Done

  const handleCloseModal = () => {
    clearModal(dispatch)()
  }

  return (
    <Modal isVisible onClose={handleCloseModal} width="352px" increasedPadding>
      <FlexContainer $direction="column" $gap="32px">
        <Container>
          <Text size={TextSize.M} color="textPrimary" bold align={TypographyAlign.Left}>
            {translate('bridge.why_takes_so_long')}
          </Text>
          <RhinoRiderImage src={RhinoRiderImg} alt="rhino-rider" width={273} height={200} />
          <Text size={TextSize.M} color="textPrimary" bold align={TypographyAlign.Left}>
            {translate('bridge.your_funds_are_safe')}
          </Text>
          <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Left}>
            {translate('bridge.why_takes_so_long_desc')}
          </Text>
        </Container>
        <Button
          id="why-takes-so-long-modal-button"
          disabled={isSolving}
          fullWidth
          size={ButtonSizes.Large}
          variant={ButtonVariant.secondarySuccess}
          icon={!isSolving ? 'check-circle-fill' : ''}
          onClick={handleCloseModal}
        >
          {isSolving ? `${translate('bridge.solving_in_progress')}...` : translate('bridge.solved')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const RhinoRiderImage = styled.img`
  margin-inline: auto;
  margin-bottom: 4px;
`
