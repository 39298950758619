import { useGetUsdtPricesQuery } from '../services/usdPricesApi'
import { POLLING_INTERVALS } from '../constants/portalConfig'
import { useUserBridgeHistory } from '../pages/Bridge/hooks/useUserBridgeHistory'

export const usePollEndpoints = () => {
  useGetUsdtPricesQuery(undefined, {
    pollingInterval: POLLING_INTERVALS.TOKEN_PRICES,
  })

  useUserBridgeHistory()
}
