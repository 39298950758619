import logoLight from './logo-light.svg'
import logoDark from './logo-dark.svg'
import type { ThemeAssets } from './assets.types'
import type { ThemeName } from '../store/slices/types/PortalReducerState'

export const assets: ThemeAssets = {
  logo: logoLight,
}

export const darkAssets: ThemeAssets = {
  logo: logoDark,
}

export const themeAssets: Record<ThemeName, ThemeAssets> = {
  dune: assets,
  tether: assets,
  sonic: assets,
  equalizer: darkAssets,
  dark: darkAssets,
}
