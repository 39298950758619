import { Dropdown } from '@rhinofi/dvf-shared-ui'
import { MenuContent } from './MenuContent'
import { useAppSelector } from '../../hooks'
import { zIndices } from '../../constants/zIndex'
import { selectAddress } from '../../store/selectors/user.selectors'
import { MenuConnectedWalletsButtonContent } from './MenuConnectedWalletsButtonContent'
import { ConnectWalletButton } from '../ConnectWallet/ConnectWalletButton'
import { ConnectedWalletsContainer } from '../ConnectWallet/ConnectedWallets/ConnectedWallets.styled'

export const Menu = () => {
  const address = useAppSelector(selectAddress)
  return address ? (
    <Dropdown
      element={<MenuConnectedWalletsButtonContent />}
      triggerType="click"
      clampRight
      fullWidth
      zIndex={zIndices.threeDotsMenu}
      propsToChildren
    >
      <MenuContent />
    </Dropdown>
  ) : (
    <ConnectedWalletsContainer className="high-priority-display">
      <ConnectWalletButton />
    </ConnectedWalletsContainer>
  )
}
