import type React from 'react'
import { useLocation } from 'react-router-dom'
import { MobileNavigation } from '../MobileNavigation/MobileNavigation'
import { useIsMobile } from '../../hooks/useIsMobile'
import { NavigationDesktop } from '../NavigationDesktop/NavigationDesktop'
import { LayoutWrapper, FullLayout } from './HeaderLayout.styled'
import { MobileHeader } from '../MobileNavigation/components/MobileHeader'
import { useAppSelector } from '../../hooks'
import { selectIsWidget } from '../../store/selectors/portal.selectors'

export const HeaderLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { pathname } = useLocation()
  const { isMobile } = useIsMobile()
  const isWidget = useAppSelector(selectIsWidget)

  if (pathname.includes('/auth')) {
    return <LayoutWrapper>{children}</LayoutWrapper>
  }

  return (
    <>
      {isMobile ? (
        <LayoutWrapper className="layout-wrapper">
          {!isWidget && <MobileHeader />}
          {children}
          {!isWidget && <MobileNavigation />}
        </LayoutWrapper>
      ) : (
        <LayoutWrapper className="layout-wrapper">
          <NavigationDesktop />
          <FullLayout>{children}</FullLayout>
        </LayoutWrapper>
      )}
    </>
  )
}
